import React, { FC, useEffect, useState } from "react"
import ReactDOMServer from "react-dom/server"
import ReactMarkdown from "react-markdown"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import TurndownService from "turndown"
import "./index.less"

export const TextEditor: FC<TextEditorInterface> = ({ type, title, limit, handleMarkdown, fetchedMarkdown }) => {
  const turndownService = new TurndownService()

  const [text, setText] = useState("")
  const [textLength, setTextLength] = useState<number>(0)

  const reactQuillRef = React.useRef<any>()
  const unprivilegedEditor = (reactQuillRef?.current as any)?.unprivilegedEditor

  const wrapperRef = React.useRef<any>()

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "link"]
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  }

  const getTextLength = () => (text ? unprivilegedEditor?.getLength() - 1 : 0)

  const convertMarkdownToHtml = (markdown: string) => {
    return ReactDOMServer.renderToString(<ReactMarkdown children={markdown} />)
  }

  const convertHtmlToMarkdown = (html: string) => {
    return turndownService.turndown(html)
  }

  const handleChange = (value: string) => {
    if (!limit || getTextLength() <= limit) {
      setText(value)
      handleMarkdown(convertHtmlToMarkdown(value), type)
    }
  }

  const checkCharacterCount = (event: any) => {
    if (limit && getTextLength() >= limit && event.code !== "Backspace") {
      event.preventDefault()
    }
  }

  const handlePaste = (e: any) => {
    if (limit) {
      const textLength = reactQuillRef?.current?.editor?.container?.textContent?.length || 0
      const clipboardData = e.clipboardData
      const pastedTextLength = clipboardData.getData("text/plain").length
      if (textLength + pastedTextLength > limit) {
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    setTextLength(getTextLength())
  }, [text])

  useEffect(() => {
    wrapperRef?.current && wrapperRef.current.addEventListener("paste", handlePaste)
  }, [wrapperRef])

  useEffect(() => {
    fetchedMarkdown && setText(convertMarkdownToHtml(fetchedMarkdown))
  }, [fetchedMarkdown])

  return (
    <div className={"editor-container"} ref={wrapperRef}>
      <div className={"editor-title"} children={title} />
      <ReactQuill
        ref={reactQuillRef}
        onKeyDown={checkCharacterCount}
        value={text}
        placeholder={"Type text here..."}
        theme='snow'
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
      {limit && (
        <div className={"text-editor-limit-identifier"}>
          {textLength <= limit ? textLength : limit} / {limit}
        </div>
      )}
    </div>
  )
}
