import React, { FC, useEffect, useRef, useState } from "react"
import { useGetNotificationListLazyQuery } from "../../../../../graphql"
import { formatDate } from "../../../../../utils"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"
import { useAuth } from "../../../../../components/auth"

const ListBlock: FC = () => {
  const {region} = useAuth()

  const [getNotificationList] = useGetNotificationListLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [filters, setFilters] = useState<{ [key: string]: string | number }>({
    selectedType: "0",
    selectedTitle: "All",
    selectedDescription: "All",
  })

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const createQueryFilters = () => ({
    ...(region?.id ? {regionId: +region.id } : {}),
    ...(filters.selectedType !== "All" ? { typeId: +filters.selectedType } : {}),
    ...(filters.selectedTitle !== "All" ? { title: filters.selectedTitle as string } : {}),
    ...(filters.selectedDescription !== "All" ? { description: filters.selectedDescription as string } : {}),
  })

  const createList = async (isForExport: boolean = false) => {
    setLoading(true)
    if (list && !isForExport) {
      setList(undefined)
    }

    let newList

    newList = await getNotificationList({
      fetchPolicy: "network-only",
      variables: {
        input: {
          ...createQueryFilters(),
          page: isForExport ? 1 : page,
          pageSize: isForExport ? 1000 : pageSize,
        },
      },
    })

    let totalItems
    let finalData

    totalItems = newList?.data?.getNotificationList?.total || 0
    finalData = newList?.data?.getNotificationList?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        item?.title || "-",
        item?.body || "-",
        formatDate(item?.pushAt || item?.createdAt) || "-",
        !item?.isAutoGenerated ? "Custom" : (item?.type?.name || "-"),
      ]) || []
    listForExport = listForExport?.map((item: any) => item.filter((entity: any) => entity !== null))
    listForExport.unshift(["TITLE", "DESCRIPTION", "PUSH AT", "TYPE"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  const createSpecificFiltersList = () => {
    let list = []
    if (filters.selectedTitle !== "All") list.push({ option: filters.selectedTitle, type: "title", backgroundColor: "rgba(215, 163, 171, 0.15)" , color: "#D7A3AB" })
    if (filters.selectedDescription !== "All") list.push({ option: filters.selectedDescription, type: "description", backgroundColor: "rgba(68, 195, 187, 0.15)", color: "#44C3BB" })
    return list
  }

  const specificFilters = createSpecificFiltersList()

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          filters,
          setFilters,
          specificFilters,
        }}
      />
      <List
        {...{
          list,
          page,
          loading,
          setPage,
          filters,
          pageSize,
          setFilters,
          totalItems,
          setLoading,
          setPageSize,
          exportLoading,
          listForExport,
          downloadBtnRef,
          createExportList,
          createQueryFilters,
        }}
      />
    </div>
  )
}

export default ListBlock
