import React, { FC, useEffect, useRef, useState } from "react"
import { useGetLinkListLazyQuery } from "../../../../../graphql"
import { formatDate } from "../../../../../utils"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"

const ListBlock: FC = () => {
  const [getLinksList] = useGetLinkListLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedStatus: "All",
    selectedTitle: "All",
  })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const createQueryFilters = () => {
    return {
      type: {ne: "news"},
    ...(filters.selectedStatus !== "All" ? {app_more_section_type: { name:  filters.selectedStatus}} : {}),
    ...(filters.selectedTitle !== "All" ? { title: { eq: filters.selectedTitle } } : {}),
    }
  }

  const createList = async (isForExport: boolean = false) => {
    setLoading(true)
    if (list && !isForExport) {
      setList(undefined)
    }

    let newList
    const pagination = {
      pageSize: isForExport ? 1000 : pageSize,
      page: isForExport ? undefined : page,
    }
    newList = await getLinksList({
      fetchPolicy: "network-only",
      variables: {
        sort: "createdAt:desc",
        filter: createQueryFilters(),
        pagination,
      },
    })

    let totalItems
    let finalData

    totalItems = newList?.data?.appNewsPosts?.meta?.pagination?.total || 0
    finalData = newList?.data?.appNewsPosts?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        item?.attributes?.title || "-",
        item?.attributes?.more_section_type?.data?.attributes?.label || "",
        formatDate(item?.attributes?.createdAt) || "-",
      ]) || []
    listForExport = listForExport?.map((item: any) => item.filter((entity: any) => entity !== null))
    listForExport.unshift(["TITLE", "TYPE", "DATE"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  const createSpecificFiltersList = () => {
    let list = []
    if (filters.selectedTitle !== "All") list.push({ option: filters.selectedTitle, type: "title", backgroundColor: "rgba(215, 163, 171, 0.15)" , color: "#D7A3AB" })
    return list
  }

  const specificFilters = createSpecificFiltersList()

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          filters,
          setFilters,
          specificFilters,
        }}
      />
      <List
        {...{
          list,
          page,
          loading,
          setPage,
          filters,
          pageSize,
          setFilters,
          totalItems,
          setLoading,
          setPageSize,
          exportLoading,
          listForExport,
          downloadBtnRef,
          createExportList,
          createQueryFilters,
        }}
      />
    </div>
  )
}

export default ListBlock
