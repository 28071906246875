import React, { FC } from "react"
import { useLocation } from "react-router"
import ListBlock from "./blocks/ListBlock"
import ManageBlock from "./blocks/ManageBlock"
import "./index.less"

const Posts: FC = () => {
  const { pathname } = useLocation()
  const pathIdentifier = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createNewMode = pathIdentifier == "new-post"
  const selectedPostId = Number(pathIdentifier)

  return (
    <section className={"posts"}>
      {!createNewMode && !selectedPostId && <ListBlock />}
      {(selectedPostId > 0 || createNewMode) && <ManageBlock selectedPostId={selectedPostId} />}
    </section>
  )
}

export default Posts
