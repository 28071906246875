import React, { FC } from "react"
import { useLocation } from "react-router"
import ListBlock from "./blocks/ListBlock"
import ManageBlock from "./blocks/ManageBlock"
import "./index.less"

const Team: FC<TeamInterface> = ({ role }) => {
  const { pathname } = useLocation()
  const pathIdentifier = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createNewMode = pathIdentifier == "new-user" || pathIdentifier == "new-distributor"
  const selectedUserId = Number(pathIdentifier)

  return (
    <section className={"team"}>
      {!createNewMode && !selectedUserId && <ListBlock role={role} />}
      {(selectedUserId > 0 || createNewMode) && <ManageBlock userId={selectedUserId} role={role} />}
    </section>
  )
}

export default Team
