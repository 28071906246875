import { Empty, Select, Spin } from "antd"
import { FC } from "react"
import { CSVLink } from "react-csv"
import { ReactComponent as DownloadIcon } from "../../../../../../icons/download.svg"
import { ReactComponent as SearchIcon } from "../../../../../../icons/search.svg"
import "./index.less"

const List: FC<LeaderboardListInterface> = ({
  list,
  page,
  filters,
  pageSize,
  setFilters,
  dscOptions,
  listForExport,
  exportLoading,
  downloadBtnRef,
  createExportList,
}) => {
  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const handleFilter = (data: string) => {
    setFilters({
      ...filters,
      selectedDSC: data,
    })
  }

  const createSelect = () => (
    <Select
      showSearch
      value={"DSC"}
      optionFilterProp='children'
      onChange={data => handleFilter(data)}
      filterOption={filteredOption as any}
      options={dscOptions}
      defaultActiveFirstOption={true}
      notFoundContent={
        !dscOptions ? (
          <div className={"no-data"} children={<Spin />} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
        )
      }
      suffixIcon={<SearchIcon />}
    />
  )

  const getItemNumber = (i: number) => i + 1 + (page > 1 ? pageSize * (page - 1) : 0)

  return (
    <div className={"main-list-wrapper competition-leaderboard-list"}>
      <div className={"list-wrapper"}>
        <div className={"items top"}>
          <div children={"RANK"} />
          <div className={`${filters?.selectedDSC !== "All" && "active"}`} children={createSelect()} />
          <div children={"REGION"} />
          <div children={"UNITS"} />
          <div className={"end-block"} children={"DEALS / SETS"} />
        </div>

        {list &&
          list.map((item: any, i: number) => (
            <div className={"items"} key={`list-item-${i}`}>
              <div children={<p>{getItemNumber(i)}</p>} />
              <div children={<p>{item.name || "-"}</p>} />
              <div children={<p>North America</p>} />
              <div children={<p>{item.quantity}</p>} />
              <div children={<p> {item.deals} </p>} />
            </div>
          ))}

        {!list && <div className={"spinner-wrapper"} children={<Spin />} />}

        {list?.length == 0 && <div className={"empty"} children={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />} />}
      </div>

      <button onClick={() => createExportList()} className={"download"} disabled={!list}>
        Download CSV
        {exportLoading ? <Spin /> : <DownloadIcon />}
      </button>

      <CSVLink data={listForExport || []} filename={"leaderboard"} ref={downloadBtnRef as any} />
    </div>
  )
}

export default List
