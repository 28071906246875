import { ConfigProvider, Pagination, PaginationProps } from "antd"
import React, { FC, useEffect, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useAuth } from "../../../components/auth"
import { useGetRegionsQuery, useGetSalesCompetitionTypesQuery, useGetUserNamesQuery } from "../../../graphql"
import CompetitionList from "./CompetitionList"
import GlobalList from "./GlobalList"
import Filter from "./components/Filter"
import "./index.less"
import { capitalizeFirstLetter } from "src/utils"

const Leaderboard: FC<LeaderboardsInterface> = ({ leaderboardType }) => {
  const { region } = useAuth()

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalItems, setTotalItems] = useState<number>(0)

  //year options
  const currentYear = new Date().getFullYear()
  const yearOptions = Array.from({ length: currentYear - 2023 + 1 }, (_, i) => ({
    label: String(currentYear - i),
    key: String(i),
  }))

  //month options
  const currentMonth = new Date().getMonth() + 1
  const monthOptions = Array.from({ length: 12 }, (_, index) => {
    return {
      label: capitalizeFirstLetter(new Date(currentYear, index).toLocaleString("en-US", { month: "long" })),
      key: String(index + 1),
    }
  })
  const currentMonthName = monthOptions?.find(option => +option.key == currentMonth)!.label

  //competition type options
  const { data: competitionTypes } = useGetSalesCompetitionTypesQuery({})
  const competitionTypeOptions = Array.from(
    { length: competitionTypes?.appSalesCompetitionTypes?.data?.length || 0 },
    (_, i) => ({
      label: competitionTypes?.appSalesCompetitionTypes?.data[i].attributes!.name!,
      value: competitionTypes?.appSalesCompetitionTypes?.data[i].attributes!.type!,
      key: competitionTypes?.appSalesCompetitionTypes?.data[i].id || "",
    })
  )

  //sorting options
  const sortOptions = [
    { label: "Most Units Sold", value: "quantity", key: "1" },
    { label: "Most Deals / Sets Sold", value: "deals", key: "2" },
  ]

  //filters
  const initialFilters = {
    selectedDSC: "All",
    selectedRegion: region?.name || "North America",
    selectedMonth: currentMonthName!,
    selectedYear: String(currentYear),
    selectedCompetition: competitionTypeOptions[0]?.label || "",
    sortBy: "Most Units Sold",
  }
  const [filters, setFilters] = useState<{ [key: string]: string }>(initialFilters)

  //region options
  const { data: regionsData } = useGetRegionsQuery()
  const regionOptions = Array.from({ length: regionsData?.regions?.data.length || 0 }, (_, i) => ({
    label: regionsData?.regions?.data[i].attributes!.name!,
    value: regionsData?.regions?.data[i].attributes!.name!,
    key: regionsData?.regions?.data[i].id!,
  }))

  //dsc options
  const { data: dscData } = useGetUserNamesQuery({
    variables: {
      filter: {
        dsc: {
          sales_manager: {
            distributor: {
              region: {
                name: { eq: filters.selectedRegion },
              },
            },
          },
        },
      },
      pagination: { limit: 10000, start: 0 },
    },
  })
  const dscOptions = Array.from({ length: dscData?.usersPermissionsUsers?.data.length || 0 }, (_, i) => ({
    label: `${dscData?.usersPermissionsUsers?.data[i]?.attributes?.name}${dscData?.usersPermissionsUsers?.data[i]?.attributes?.blocked ? " (Blocked)" : ""}`,
    value: dscData?.usersPermissionsUsers?.data[i].attributes!.name!,
    key: dscData?.usersPermissionsUsers?.data[i].attributes!.email!,
  }))

  //available months per selected year
  const availableMonthOptions = monthOptions?.filter(
    (month, i) => +filters.selectedYear !== currentYear || i < currentMonth
  )

  const onChangePage: PaginationProps["onChange"] = pageNumber => {
    setPage(pageNumber)
  }

  const onChangePageSize: PaginationProps["onShowSizeChange"] = (_, pageSize) => {
    setPageSize(pageSize)
  }

  useEffect(() => {
    if (competitionTypes) {
      setFilters(initialFilters)
      if (page !== 1)  setPage(1)
      if (pageSize !== 10)  setPageSize(10)
    }
  }, [leaderboardType, competitionTypes])

  const listProps = {
    page,
    setPage,
    filters,
    pageSize,
    setFilters,
    dscOptions,
    sortOptions,
    monthOptions,
    setTotalItems,
    competitionTypeOptions,
  }

  const filterProps = {
    filters,
    totalItems,
    setFilters,
    currentYear,
    yearOptions,
    sortOptions,
    regionOptions,
    leaderboardType,
    currentMonthName,
    availableMonthOptions,
    competitionTypeOptions,
  }

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition key={leaderboardType} timeout={0} classNames={"fade"} appear={true} unmountOnExit>
        <section className={"leaderboards"}>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "black",
                  colorPrimaryBorder: "black",
                  colorPrimaryHover: "black",
                  controlOutline: "#fff",
                },
                Select: {
                  colorText: "#677685",
                  colorPrimaryBorder: "#fff",
                  colorPrimaryHover: "#fff",
                  controlOutline: "#fff",
                  colorBgContainerDisabled: "#fff",
                },
              },
            }}
          >
            <Filter {...filterProps} />
            {leaderboardType == "global" ? <GlobalList {...listProps} /> : <CompetitionList {...listProps} />}
            <div className={"navigation-wrapper"}>
              <div
                className={"navigation"}
                children={
                  <Pagination
                    showQuickJumper
                    current={page}
                    pageSize={pageSize}
                    total={totalItems}
                    onChange={onChangePage}
                    onShowSizeChange={onChangePageSize}
                  />
                }
              />
            </div>
          </ConfigProvider>
        </section>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default Leaderboard
