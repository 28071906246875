import React, { FC, useEffect, useRef, useState } from "react"
import { useGetPostListLazyQuery } from "../../../../../graphql"
import { formatDate } from "../../../../../utils"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"
import { useAuth } from "../../../../../components/auth"

const ListBlock: FC = () => {
  const {region} = useAuth()
  const [getPostList] = useGetPostListLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedRegion: "All",
    selectedStatus: "All",
    selectedTitle: "All",
    selectedAuthor: "All",
    selectedCategory: "All",
  })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)
  const currentDate = new Date()

  const getPostStatus = (start_at: string) => {
    if (!start_at) return "Published"
    const currentDate = new Date()
    const postDate = new Date(start_at)
    return currentDate.getTime() > postDate.getTime() ? "Published" : "Scheduled"
  }

  const createQueryFilters = () => ({
    type: { eq: "news" },
    ...(filters.selectedStatus == "Published"
      ? {
          or: [{ start_at: { lte: currentDate } }, { start_at: { eq: null } }],
        }
      : {}),
    ...(filters.selectedStatus == "Scheduled"
      ? {
          start_at: { gt: currentDate },
        }
      : {})!,
    ...(filters.selectedRegion !== "All" || region?.name
      ? {
          regions: { name: { eq: region?.name || filters.selectedRegion } },
        }
      : {}),
    ...(filters.selectedTitle !== "All"
      ? {
          title: { eq: filters.selectedTitle },
        }
      : {}),
    ...(filters!.selectedAuthor !== "All"
      ? {
          author: { name: { eq: filters.selectedAuthor } },
        }
      : {}),
    ...(filters!.selectedCategory !== "All"
      ? {
          tag: { eq: filters.selectedCategory },
        }
      : {}),
  })

  const createList = async (isForExport: boolean = false) => {
    setLoading(true)
    if (list && !isForExport) {
      setList(undefined)
    }

    let newList
    const pagination = {
      pageSize: isForExport ? 1000 : pageSize,
      page: isForExport ? undefined : page,
    }
    newList = await getPostList({
      fetchPolicy: "network-only",
      variables: {
        sort: "createdAt:desc",
        filter: createQueryFilters(),
        pagination,
      },
    })

    let totalItems
    let finalData

    totalItems = newList?.data?.appNewsPosts?.meta.pagination.total || 0
    finalData = newList?.data?.appNewsPosts?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        item?.attributes?.title || "-",
        item?.attributes?.author?.data?.attributes?.name || "Unknown",
        item?.attributes?.tag || "",
        getPostStatus(item?.attributes?.start_at),
        formatDate(item?.attributes?.createdAt),
      ]) || []
    listForExport = listForExport?.map(item => item.filter(entity => entity !== null))
    listForExport.unshift(["TITLE", "AUTHOR", "CATEGORY", "STATUS", "DATE"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  const createSpecificFiltersList = () => {
    let list = []
    if (filters.selectedTitle !== "All") list.push({ option: filters.selectedTitle, type: "title", backgroundColor: "rgba(215, 163, 171, 0.15)" , color: "#D7A3AB" })
    if (filters.selectedAuthor !== "All") list.push({ option: filters.selectedAuthor, type: "author", backgroundColor: "rgba(68, 195, 187, 0.15)", color: "#44C3BB" })
    if (filters.selectedCategory !== "All") list.push({ option: filters.selectedCategory, type: "category", backgroundColor: "rgba(11, 214, 42, 0.15)", color: "#2bb350" })
    return list
  }

  const specificFilters = createSpecificFiltersList()

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          filters,
          setFilters,
          specificFilters,
        }}
      />
      <List
        {...{
          list,
          page,
          loading,
          setPage,
          filters,
          pageSize,
          setFilters,
          totalItems,
          setLoading,
          setPageSize,
          getPostStatus,
          exportLoading,
          listForExport,
          downloadBtnRef,
          createExportList,
          createQueryFilters,
        }}
      />
    </div>
  )
}

export default ListBlock
