import React, { FC, useEffect, useRef, useState } from "react"
import { useGetGuestsListLazyQuery } from "../../../../../graphql"
import { capitalizeEveryWord } from "../../../../../utils"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"
import { useAuth } from "../../../../../components/auth"

const ListBlock: FC = () => {
  const { region } = useAuth()
  const [getGuests] = useGetGuestsListLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedName: "All",
    selectedEmail: "All",
    selectedType: "All",
    selectedRegion: region?.name ? region!.name! : "All",
  })

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const createQueryFilters = () => {
    const { selectedType: type, selectedEmail: email, selectedName: name, selectedRegion: region } = filters
    const formattedType = type?.toLowerCase().replace(" ", "_")

    return {
      ...(name !== "All" ? { name: { eq: name } } : {}),
      ...(email !== "All" ? { email: { eq: email } } : {}),
      role: { name: { eq: "Guest" } },
      guest: {
        ...(type !== "All" ? { guest_type: { eq: formattedType } } : {}),
        ...(region !== "All" ? { region: { name: { eq: region } } } : {}),
      },
    }
  }

  const createList = async (isForExport: boolean = false) => {
    setLoading(true)
    if (list && !isForExport) {
      setList(undefined)
    }

    let newList

    newList = await getGuests({
      fetchPolicy: "network-only",
      variables: {
        pagination: {
          pageSize: isForExport ? 1000 : pageSize,
          page: isForExport ? undefined : page,
        },
        sort: "name:asc",
        filter: createQueryFilters() as UsersPermissionsUserFiltersInput,
      },
    })

    let totalItems
    let finalData

    totalItems = newList?.data?.usersPermissionsUsers?.meta?.pagination?.total || 0
    finalData = newList?.data?.usersPermissionsUsers?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const getStatus = (item: any) => {
    return item?.attributes?.blocked ? "blocked" : item?.attributes?.isAppActivated ? "active" : "inactive"
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        item?.attributes?.name || "-",
        item?.attributes?.email || "-",
        capitalizeEveryWord(item?.attributes?.guest?.data?.attributes?.guest_type),
        getStatus(item),
      ]) || []
    listForExport = listForExport?.map((item: any) => item.filter((entity: any) => entity !== null))
    listForExport.unshift(["NAME", "EMAIL", "TYPE", "STATUS"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  const createSpecificFiltersList = () => {
    let list = []
    if (filters.selectedName !== "All")
      list.push({
        option: filters.selectedName,
        type: "name",
        backgroundColor: "rgba(215, 163, 171, 0.15)",
        color: "#D7A3AB",
      })
    if (filters.selectedEmail !== "All")
      list.push({
        option: filters.selectedEmail,
        type: "email",
        backgroundColor: "rgba(68, 195, 187, 0.15)",
        color: "#44C3BB",
      })
    return list
  }

  const specificFilters = createSpecificFiltersList()

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          filters,
          setFilters,
          specificFilters,
        }}
      />
      <List
        {...{
          list,
          page,
          loading,
          setPage,
          filters,
          pageSize,
          getStatus,
          setFilters,
          totalItems,
          setLoading,
          setPageSize,
          exportLoading,
          listForExport,
          downloadBtnRef,
          createExportList,
          createQueryFilters,
        }}
      />
    </div>
  )
}

export default ListBlock
