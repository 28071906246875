import React, { FC, useEffect, useRef, useState } from "react"
import { useGetDistributorEntitiesLazyQuery, useGetUsersLazyQuery } from "../../../../../graphql"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"
import { useAuth } from "../../../../../components/auth"

const ListBlock: FC<TeamListBlockInterface> = ({ role }) => {
  const {region} = useAuth()
  const [getUsers] = useGetUsersLazyQuery()
  const [getDistributors] = useGetDistributorEntitiesLazyQuery()
  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [filters, setFilters] = useState<{ [key: string]: string | boolean }>({
    selectedRegion: "All",
    selectedStatus: "All",
    selectedEmail: "All",
    selectedSM: "All",
    selectedDistributor: "All",
    selectedName: "All",
    isBadgesOnly: false
  })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const createQueryFilters = () => {
    const baseFilters = {
      ...(filters.selectedName !== "All" ? { name: { eq: filters.selectedName } } : {}),
      ...(filters.selectedEmail !== "All" ? { email: { eq: filters.selectedEmail } } : {}),
      ...(filters.selectedStatus === "Active" ? { isAppActivated: { eq: true }, blocked: {eq: false} } : {}),
      ...(filters.selectedStatus === "Inactive" ?
        {or:
            [
              {isAppActivated: { eq: false },  blocked: {eq: false}},
              {isAppActivated: { eq: null },  blocked: {eq: false}}
            ],
      } : {}),
      ...(filters.selectedStatus === "Blocked" ? { blocked: { eq: true } } : {}),
    }

    const roleSpecificFilters: any = {
      dsc: {
        role: { name: { eq: "DSC" } },
        dsc: {
          ...(filters.isBadgesOnly ? {ranking: {ne: null}} : {}),
          sales_manager: {
            name: { eq: filters.selectedSM !== "All" ? filters.selectedSM : undefined },
            distributor: {
              name: { eq: filters.selectedDistributor !== "All" ? filters.selectedDistributor : undefined },
              region: { name: { eq: filters.selectedRegion !== "All" ? filters.selectedRegion : (region?.name || undefined) } },
            },
          },
        },
      },

      sales_manager: {
        role: { name: { eq: "Sales Manager" } },
        sales_manager: {
          distributor: {
            name: { eq: filters.selectedDistributor !== "All" ? filters.selectedDistributor : undefined },
            region: { name: { eq: filters.selectedRegion !== "All" ? filters.selectedRegion : (region?.name || undefined) } },
          },
        },
      },

      distributor: {
        role: { name: { eq: "Distributor" } },
        distributor: {
          name: { eq: filters.selectedDistributor !== "All" ? filters.selectedDistributor : undefined },
          region: { name: { eq: filters.selectedRegion !== "All" ? filters.selectedRegion : (region?.name || undefined) } },
        },
      },

      distributor_entity: {
        name: { eq: filters.selectedDistributor !== "All" ? filters.selectedDistributor : undefined },
        region: { name: { eq: filters.selectedRegion !== "All" ? filters.selectedRegion : (region?.name || undefined) } },
      },

      region: {
        role: { name: { eq: "Region" } },
        region: { name: { eq: filters.selectedRegion !== "All" ? filters.selectedRegion : (region?.name || undefined) } },
      },
    }

    const specificFilters = roleSpecificFilters[role?.toLowerCase()?.replace(" ", "_")]
    return {
      ...baseFilters,
      ...specificFilters,
    }
  }

  const createList = async (isForExport: boolean = false) => {
    setLoading(true)
    if (list && !isForExport) {
      setList(undefined)
    }
    let newList
    const pagination = {
      pageSize: isForExport ? 1000 : pageSize,
      page: isForExport ? undefined : page,
    }

    if (role !== "Distributor Entity") {
      newList = await getUsers({
        fetchPolicy: "network-only",
        variables: {
          sort: "name:asc",
          filter: createQueryFilters(),
          pagination,
        },
      })
    } else {
      newList = await getDistributors({
        fetchPolicy: "network-only",
        variables: {
          sort: "name:asc",
          filter: createQueryFilters(),
          pagination,
        },
      })
    }

    let totalItems
    let finalData

    if (newList.data && "usersPermissionsUsers" in newList.data) {
      totalItems = newList.data?.usersPermissionsUsers?.meta.pagination.total || 0
      finalData = newList.data?.usersPermissionsUsers?.data
    } else if (newList.data && "distributors" in newList.data) {
      totalItems = newList.data?.distributors?.meta.pagination.total || 0
      finalData = newList.data?.distributors?.data
    }

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const clearFilters = () => {
    setFilters({
      selectedRegion: "All",
      selectedStatus: "All",
      selectedEmail: "All",
      selectedSM: "All",
      selectedDistributor: "All",
      selectedName: "All",
    })
    setPage(1)
    setPageSize(10)
    setList(undefined)
    setTotalItems(0)
  }

  const createRoleFiltersList = () => {
    let list = []
    if (filters.selectedName !== "All") {
      list.push({ option: filters.selectedName as string, type: "name", backgroundColor: "rgba(215, 163, 171, 0.15)" , color: "#D7A3AB"})
    }
    if (filters.selectedEmail !== "All") {
      list.push({ option: filters.selectedEmail as string, type: "email", backgroundColor: "rgba(68, 195, 187, 0.15)", color: "#44C3BB" })
    }
    if (filters.selectedSM !== "All") {
      list.push({ option: filters.selectedSM as string, type: "Sales Manager", backgroundColor: "rgba(11, 214, 42, 0.15)", color: "#2bb350" })
    }
    if (filters.selectedDistributor !== "All") {
      list.push({ option: filters.selectedDistributor as string, type: "Distributor", backgroundColor: "rgba(50, 105, 168, 0.15)", color: "#3269a8" })
    }
    return list
  }

  let roleFilters = createRoleFiltersList()

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)
    let listForExport = list?.map(
      (item: any) =>
        [
          role != "Distributor Entity" ? item.attributes.name || "-" : null,
          role != "Distributor Entity" ? item.attributes.email || "-" : null,
          role == "DSC"
            ? item?.attributes?.dsc?.data?.attributes?.sales_manager?.data?.attributes?.name || "-"
            : null,
          role != "Region"
            ? (role == "Distributor Entity" && item.attributes?.name) ||
              item.attributes?.distributor?.data?.attributes?.name ||
              item.attributes?.sales_manager?.data?.attributes?.distributor?.data?.attributes?.name ||
              item.attributes?.dsc?.data?.attributes?.sales_manager?.data?.attributes?.distributor?.data?.attributes
                ?.name ||
              "-"
            : null,
          item?.attributes?.region?.data?.attributes?.name ||
            item.attributes?.distributor?.data?.attributes?.region?.data?.attributes?.name ||
            item.attributes?.sales_manager?.data?.attributes?.distributor?.data?.attributes?.region?.data?.attributes
              ?.name ||
            item.attributes?.dsc?.data?.attributes?.sales_manager?.data?.attributes?.distributor?.data?.attributes
              ?.region?.data?.attributes?.name ||
            "-",
          role != "Distributor Entity" ? (item.attributes?.blocked ? "blocked" : item.attributes?.isAppActivated ? "active" : "inactive") : null,
        ] || []
    )
    listForExport!.unshift([
      role != "Distributor Entity" ? "NAME" : null,
      role != "Distributor Entity" ? "EMAIL" : null,
      role == "DSC" ? "SALES MANAGER" : null,
      role != "Region" ? "DISTRIBUTOR" : null,
      "REGION",
      role !== "Distributor Entity" ? "STATUS" : null,
    ])
    listForExport = listForExport?.map(item => item.filter(entity => entity !== null))
    setExportLoading(false)
    setListForExport(listForExport)
  }

  useEffect(() => {
    if (page !== 1) {
      setPage(1)
    }
  }, [role, filters])

  useEffect(() => {
    clearFilters()
  }, [role])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [role, pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          roleFilters,
          filters,
          setFilters,
          role,
        }}
      />
      <List
        {...{
          list,
          role,
          setPage,
          page,
          setPageSize,
          pageSize,
          totalItems,
          loading,
          setLoading,
          filters,
          setFilters,
          createQueryFilters,
          listForExport,
          exportLoading,
          downloadBtnRef,
          createExportList,
        }}
      />
    </div>
  )
}

export default ListBlock
