import React, { FC } from "react"
import imagePlaceholder from "../../../../../../../images/distributor-placeholder.png"
import { formatDate, truncate } from "../../../../../../../utils"
import "./index.less"

const RightSide: FC<NotificationManageBlockRightSideInterface> = ({ notification, filters }) => {
  return (
    <div className={"general-info-wrapper"}>
      <div className={"title"}>INFORMATION</div>

      <img src={imagePlaceholder} alt={"media"} />

      <div className={"data"}>
        <div>
          Type<span>Custom</span>
        </div>

        <div>
          Title<span>{truncate((filters?.title as string) || "-", 33)}</span>
        </div>

        <div>
          Date of creation<span>{formatDate(notification?.attributes?.createdAt)}</span>
        </div>

        <div>
          Last update<span>{formatDate(notification?.attributes?.updatedAt)}</span>
        </div>

        <div>
          Launch date<span>{(filters?.dateAndTime as any)?.date || "-"}</span>
        </div>

        <div>
          Launch time
          <span>
            {(filters?.dateAndTime as any)?.time +
              ((filters?.dateAndTime as any)?.timeZone ? " " + (filters?.dateAndTime as any)?.timeZone : "") || "-"}
          </span>
        </div>
      </div>
    </div>
  )
}

export default RightSide
