import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { useGetResourceCategoriesQuery } from "../../../../../../../graphql"
import { removeSelectDuplicates, truncate } from "../../../../../../../utils"
import "./index.less"

const Filter: FC<PdfFilterInterface> = ({ filters, setFilters }) => {
  const { data, loading } = useGetResourceCategoriesQuery()

  let categories = data?.appResources?.data
  categories = categories?.length ? categories.filter(category => category) : []

  const categoriesOptions = categories?.length ? removeSelectDuplicates(
    categories?.map((item, i) => ({
      key: String(i + 1),
      value: item.attributes!.category!,
      label: item.attributes!.category!,
    }))
  ) : []
  categoriesOptions.unshift({ key: "0", value: "All", label: "All" })

  return (
    <div className={`filters-wrapper pdfs-filter`}>
      <div className={"filters"}>
        <Dropdown
          className={`category-filter ${filters.selectedCategory != "All" && "active"}`}
          trigger={["click"]}
          disabled={loading}
          overlay={
            <Menu
              onClick={e =>
                setFilters({
                  ...filters,
                  selectedCategory: categoriesOptions.find(item => +item.key == +e.key)!.label!,
                })
              }
              selectedKeys={[`${categoriesOptions.find(item => item.label == filters.selectedCategory)!.key}`]}
            >
              {categoriesOptions?.map(item => <Menu.Item key={item.key}>{truncate(item.label, 25)}</Menu.Item>)}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(categoriesOptions?.find(item => item.label == filters.selectedCategory)!.label!, 25)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>
    </div>
  )
}

export default Filter
