import { ConfigProvider, Modal, Spin } from "antd"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router"
import { useDeleteResourceMutation } from "../../../../../../../graphql"
import { DangerInfoIcon } from "../../../../../../../icons"
import Form from "./components/Form"
import "./index.less"

const LeftSide: FC<PdfManageBlockLeftSideInterface> = ({
  pdf,
  filters,
  setFilters,
  selectedPdfId,
  initialFilters,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const navigate = useNavigate()
  const [deleteResource] = useDeleteResourceMutation()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)

  const showModal = () => setIsModalOpen(true)

  const handleModalCancel = () => setIsModalOpen(false)

  const handleModalOk = async () => {
    setModalLoading(true)

    try {
      const linkResponse = await deleteResource({
        variables: {
          id: String(selectedPdfId),
        },
      })
      if (linkResponse?.data?.deleteAppResource?.data?.id) {
        setIsModalOpen(false)
        setTimeout(() => {
          navigate("/account/content/links")
        }, 500)
      }
      setModalLoading(false)

    } catch (error) {
      setModalLoading(false)
    }
  }

  return (
    <div className={"left-side-wrapper"}>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBorder: "#ffffff",
              colorPrimaryHover: "#ffffff",
              controlOutline: "#ffffff",
              colorError: "#ffffff",
              colorErrorBorderHover: "#ffffff",
              colorErrorOutline: "#ffffff",
              colorIcon: "#1C1C23",
              borderRadius: 0,
            },
            InputNumber: {
              borderRadius: 0,
              colorBorder: "#000",
              colorPrimary: "#000",
              colorPrimaryHover: "#707070",
              colorText: "#000",
            },
          },
        }}
      >
        <Form
          {...{
            pdf,
            filters,
            showModal,
            setFilters,
            initialFilters,
            selectedPdfId,
            isChangesDisabled,
            setInitialFilters,
          }}
        />

        <Modal open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel} className={"pdf"}>
          <div className={"modal-content-wrapper"}>
            <DangerInfoIcon />
            <p>Are you sure you want to delete an existing pdf?</p>
            <div className={"loading"}>{modalLoading && <Spin />}</div>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default LeftSide
