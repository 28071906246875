import { Form as AntForm, Form, Input, Modal, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useDeleteMoreSectionTypeMutation } from "../../../../../../../graphql"
import { LargeTrash } from "../../../../../../../icons"
import "./index.less"

const ExtraModal: FC<MoreSectionsExtraModalInterface> = ({
  id,
  mode,
  refetch,
  filters,
  setFilters,
  handleExtraModalCancel,
}) => {
  const [deleteSection] = useDeleteMoreSectionTypeMutation()

  const [name, setName] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const parentModalElement = document.querySelector(".ant-modal.more") as HTMLDivElement | null

  const onCancel = () => {
    handleExtraModalCancel()
    parentModalElement?.style.setProperty("opacity", null)
  }

  const onOk = async () => {
    setLoading(true)
    let newFilters = JSON.parse(JSON.stringify(filters))

    switch (mode) {
      case "edit":
        {
          const itemIndex = filters.findIndex(item => item?.id == id)
          newFilters[itemIndex].label = name
          newFilters[itemIndex].name = name?.toLowerCase().replace(" ", "_")
        }
        setLoading(false)
        setFilters(newFilters)
        onCancel()
        break

      case "create":
        {
          newFilters.push({
            id: uuidv4(),
            itemId: null,
            label: name,
            name: name?.toLowerCase().replace(" ", "_"),
            isHidden: false,
          })
        }
        setLoading(false)
        setFilters(newFilters)
        onCancel()
        break

      case "delete": {
        try {
          await deleteSection({
            variables: {
              input: {
                id: newFilters.find((item: any) => item?.id == id).itemId,
              },
            },
          })
          await refetch()
          newFilters = newFilters.filter((item: any) => item?.id !== id)
          setLoading(false)
          setFilters(newFilters)
          onCancel()
        } catch (error) {
          setLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    if (mode) parentModalElement?.style.setProperty("opacity", "0")
    if (id) {
      setName(filters.find(item => item.id == id)!.label)
    }
    else {
      setName("")
    }
  }, [mode])

  return (
    <Modal open={!!mode} onCancel={onCancel} className={"more-extra"} footer={null}>
      {mode == "delete" && <LargeTrash className={"trash"} />}
      <div>
        <div className={"title"}>
          {mode == "edit" && "Rename section"}
          {mode == "delete" && "Delete Section"}
          {mode == "create" && "Create section"}
        </div>
        <div className={"additional-description"}>
          {mode == "edit" && "Are you sure you want to edit this section?"}
          {mode == "create" && "Please specify the name of the new section."}
          {mode == "delete" &&
            "Are you sure you want to delete this section? Deleting it will also remove all associated information."}
        </div>
      </div>

      {(mode == "edit" || mode == "create") && (
        <Form layout={"vertical"}>
          <AntForm.Item label={"Name"} name='name'>
            <div className={"input-wrapper"}>
              <Input
                placeholder={"Type here"}
                onChange={e => setName(e.target.value.substring(0, 22))}
                value={name}
              />
            </div>
          </AntForm.Item>
        </Form>
      )}

      <div className={"buttons-wrapper"}>
        <div onClick={onCancel}>Cancel</div>
        <div onClick={onOk}>{mode == "delete" ? "Delete" : "Save"}</div>
        {loading && <Spin />}
      </div>
    </Modal>
  )
}

export default ExtraModal
