import { Form as AntForm, Button, ConfigProvider, Input, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useUpdateUserMutation } from "../../../../../graphql"
import { SaveIcon } from "../../../../../icons"
import { truncate } from "../../../../../utils"
import ImageLoader from "../../../components/ImageLoader"
import "./index.less"
import { useAuth } from "../../../../../components/auth"
const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""

const Form: FC<ProfileFormInterface> = ({ refetch, userData, legalMode, accountMode, passwordMode }) => {
  let {role, region} = useAuth()
  role = role?.replace("Adminportal ", "")
  const [updateUser] = useUpdateUserMutation()

  const formStyleItems = [
    "colorBorder",
    "colorPrimaryHover",
    "controlOutline",
    "colorError",
    "colorErrorBorderHover",
    "colorErrorOutline",
    "colorIcon",
  ]
  const buttonStyleItems = ["colorPrimary", "colorPrimaryHover", "colorText"]

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>("")
  const createFilters = () => ({
    avatar: userData?.me?.avatar?.data?.attributes?.url || null,
    firstInput: userData?.me?.email || "" || "",
    secondInput: userData?.me?.name || "" || "",
    thirdInput: "",
    fourthInput: "",
    fifthInput: "",
  })
  const [filters, setFilters] = useState(createFilters())

  const { firstInput, secondInput, thirdInput, fourthInput, fifthInput, avatar } = filters

  const validateEmail = () => {
    const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}(\.[a-zA-Z0-9-]{2,})?$/
    return emailRegExp.test(firstInput)
  }

  const validateName = () => secondInput?.length > 3

  const validatePassword = (password: string) => {
    const disallowedSymbolsAndSpaces = /[!@#$%^&*()_+={}[\]\\|:;"'<>,.?/~\s]/
    return password?.length >= 8 && !disallowedSymbolsAndSpaces.test(password)
  }

  const isAccountDataValid = () =>
    validateName() &&
    validateEmail() &&
    (firstInput !== userData?.me?.email ||
      secondInput !== userData?.me?.name ||
      (avatar && typeof avatar == "object") ||
      !!userData?.me?.avatar?.data?.attributes?.url !== !!avatar)

  const isPasswordDataValid = () =>
    validatePassword(thirdInput) &&
    validatePassword(fourthInput) &&
    thirdInput !== fourthInput &&
    fourthInput == fifthInput

  const isFormValid = accountMode ? isAccountDataValid() : isPasswordDataValid()

  const handleValuesChange = (inputType: string, value: string) => {
    if (error) setError("")

    setFilters({
      ...filters,
      [inputType]: value,
    })
  }

  const uploadFile = async (avatar: FormData) => {
    const response = await fetch(url + "/api/upload", {
      method: "POST",
      body: avatar,
    })
    const uploadData = await response.json()
    if (!uploadData?.[0]?.id) {
      throw new Error("failed to upload image")
    }
    return uploadData?.[0]?.id
  }

  const handleFinish = async () => {
    setLoading(true)

    try {
      let imageId

      if (avatar && typeof avatar == "object") imageId = await uploadFile(avatar)

      const data: AppUpdateProfileDataInput = accountMode
        ? {
            email: firstInput,
            name: secondInput,
            ...(avatar || avatar === null ? { avatar: avatar === null ? null : imageId } : {}),
          }
        : {
            currentPassword: thirdInput,
            newPassword: fourthInput,
          }

      const updateUserResponse = await updateUser({ variables: { input: data } })

      if (updateUserResponse?.data?.appUpdateProfileData?.success) {
        await refetch()
        setFilters({
          ...filters,
          thirdInput: fourthInput || "",
          fourthInput: "",
          fifthInput: "",
          avatar: updateUserResponse?.data?.appUpdateProfileData?.profileData?.avatar?.data?.attributes?.url || null,
        })
      }

      setLoading(false)
    } catch (error: any) {
      if (error?.message) setError(truncate(error?.message, 100))
      setLoading(false)
    }
  }

  const createInput = (label: string, isInvalid: boolean, value: string, inputType: string) => {
    const props = {
      value: value,
      placeholder: 'Type here',
      onChange: (e: any) => handleValuesChange(inputType, e.target.value)
    }
    return (
      <AntForm.Item label={label} className={isInvalid ? "invalid" : ""}>
        <div className={`input-wrapper ${isInvalid && "invalid"}`}>
          {!label?.includes("password") ? <Input {...props} /> : <Input.Password {...props} />}
        </div>
      </AntForm.Item>
    )
  }

  useEffect(() => {
    setFilters(createFilters())
  }, [accountMode, passwordMode])

  return (
    <>
      <div className={"profile-form"}>
        <div
          className={"title"}
          children={legalMode ? "Legal" : passwordMode ? "Password" : userData?.me?.name || "Admin"}
        />
        {`${role} ${region?.name ? `(${region?.name})` : ""}`}
        <div className={"subtitle"}>
          {accountMode &&
            "Changes to account settings will apply to all of your workspaces. You can make changes as needed."}
          {passwordMode && "This will be your main password to Sign In into the KM Admin Panel."}
          {legalMode && "Below, you'll discover links to our legal pages."}
        </div>

        {!legalMode && (
          <ConfigProvider
            theme={{
              components: {
                Input: Object.fromEntries(
                  formStyleItems.map(item => [item, item == "colorIcon" ? "#1C1C23" : "#ffffff"])
                ),
                Button: Object.fromEntries(
                  buttonStyleItems.map(item => [item, item === "colorPrimaryHover" ? "#000000" : "#ffffff"])
                ),
              },
            }}
          >
            <AntForm layout='vertical' onFinish={handleFinish} requiredMark={false}>
              {accountMode && (
                <>
                  {createInput("Email", !!(firstInput && !validateEmail()), filters.firstInput, "firstInput")}
                  {createInput("Name", !!(secondInput && !validateName()), filters.secondInput, "secondInput")}
                </>
              )}

              {passwordMode && (
                <>
                  {createInput(
                    "Old password",
                    !!(thirdInput && !validatePassword(thirdInput)),
                    filters.thirdInput,
                    "thirdInput"
                  )}
                  {createInput(
                    "New password",
                    !!(fourthInput && !validatePassword(fourthInput)),
                    filters.fourthInput,
                    "fourthInput"
                  )}
                  {createInput(
                    "Confirm new password",
                    !!(fifthInput && fourthInput !== fifthInput),
                    filters.fifthInput,
                    "fifthInput"
                  )}
                </>
              )}

              <Button type='primary' htmlType='submit' disabled={!isFormValid}>
                Save changes
                {loading ? <Spin /> : <SaveIcon />}
              </Button>
              <div className={"error"} children={error || ""} />
            </AntForm>
          </ConfigProvider>
        )}

        {legalMode && (
          <div className={"legal-info"}>
            <a onClick={() => window.open("/terms")} children={"Terms and Conditions"} />
            <a onClick={() => window.open("/privacy")} children={"Privacy Policy"} />
          </div>
        )}
      </div>

      {accountMode ? (
        <div
          className={"avatar"}
          children={
            <ImageLoader
              type={"avatar"}
              filters={filters}
              canBeRemoved={true}
              setFilters={setFilters}
              isChangesDisabled={loading}
              fetchedImageURL={typeof avatar == "string" ? avatar : ""}
            />
          }
        />
      ) : (
        <div />
      )}
    </>
  )
}

export default Form
