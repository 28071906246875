import "./index.less"
import { DateTime } from "luxon"
const pagination = { limit: 10000, start: 0 }
import React, { FC, useEffect, useRef, useState } from "react"
import { Form as AntForm, Empty, Input, Select, Spin } from "antd"
import { TextEditor } from "../../../../../../../components/TextEditor"
const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""
import { areAllKeysPopulated, areObjectsEqual, truncate } from "../../../../../../../../../utils"
import {CalendarIcon, SaveIcon, ClockIcon, CloseIcon, SearchIcon, TrashIcon} from "../../../../../../../../../icons"
import {
  useCreateEventMutation,
  useGetDistributorEntitiesLazyQuery, useGetDscEntitiesLazyQuery,
  useGetRegionsLazyQuery,
  useUpdateEventMutation,
} from "../../../../../../../../../graphql"
import { CustomCalendar } from "../../../../../../../components/CustomCalendar"
import { useAuth } from "../../../../../../../../../components/auth"


const Form: FC<EventManageBlockLeftSideFormInterface> = ({
  event,
  options,
  filters,
  showModal,
  setOptions,
  setFilters,
  initialFilters,
  selectedEventId,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const { permissions, region } = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Content")?.blocks?.find((block: any) => block.name == "Events")

  const [createEvent] = useCreateEventMutation()
  const [updateEvent] = useUpdateEventMutation()

  const calendarSelectElement = useRef<any>(null)

  const [getRegions] = useGetRegionsLazyQuery()
  const [getDscs] = useGetDscEntitiesLazyQuery()
  const [getDistributors] = useGetDistributorEntitiesLazyQuery()

  const [errorMessage, setErrorMessage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const generateTimeOptions = (): SelectItems =>
    Array.from({ length: 96 }, (_, index) => {
      const hours = Math.floor((index * 15) / 60)
      const minutes = (index * 15) % 60
      const formattedHours = hours.toString().padStart(2, "0")
      const formattedMinutes = minutes.toString().padStart(2, "0")
      const time = `${formattedHours}:${formattedMinutes}`
      return {
        value: time,
        label: time,
        key: String(index),
      }
    })

  const generateTimeZoneOptions = (): SelectItems => {
    const utcOffsets = Array.from({ length: 27 }, (_, index) => index - 12)
    return utcOffsets.map(offset => {
      const formattedOffset = offset >= 0 ? `+${offset}` : `${offset}`
      return {
        value: `UTC${formattedOffset}`,
        label: `UTC${formattedOffset}`,
        key: `UTC${formattedOffset}`,
      }
    })
  }

  const getItems = async () => {
    setLoading(true)

    const itemCreator = (item: any) => ({
      value: item.attributes.name,
      label: item.attributes.name,
      key: item.id,
    })

    const dscsData = await getDscs({
      variables: {
        pagination,
        sort: "name:asc",
        ...(region?.id ? {filters: {sales_manager: {distributor: {region: {id: {eq: region.id}}}}}} : {})
      }
    })
    const dscOptions = dscsData?.data?.dscs?.data
      .map(item => itemCreator(item))
      .filter(item => item.value)

    const distributorsData = await getDistributors({ variables: {
        pagination,
        sort: "name:asc",
        ...(region?.id ? {filter: {region: {id: {eq: region.id}}}} : {})
        }
      })
    const distributorsOptions = distributorsData?.data?.distributors?.data
      .map(item => itemCreator(item))
      .filter(item => item.value)

    const regionsData = await getRegions()
    let regionsOptions = regionsData?.data?.regions?.data
      .map(item => itemCreator(item))
      .filter(item => item.value)
    regionsOptions?.unshift({key: "0", value: "All", label: "All"})
    if (region?.id) {
      regionsOptions = regionsOptions?.filter(item => item.key == region.id)
    }

    setOptions({
      dscs: dscOptions!,
      regions: regionsOptions!,
      distributors: distributorsOptions!,
    })

    setLoading(false)
  }

  const handleValuesChange = (value: string | Object, type: string) => {

    const invitationKeys = ["distributor", "region", "dsc"];

    if (invitationKeys.includes(type) && value) {
      setFilters({
        ...filters,
        ...invitationKeys
          .filter(key => key !== type)
          .reduce((acc, key) => {
            (acc as any)[key] = "";
            return acc;
          }, {}),
        [type]: value,
      })
      return
    }

    switch (type) {
      case "start-date":
      case "start-time":
      case "end-date":
      case "end-time": {
        const objectType = type.startsWith("start-") ? type.replace("start-", "") : type.replace("end-", "");
        const key = type.startsWith("start-") ? "startAt" : "endAt";
        setFilters({
          ...filters,
          [key]: {
            ...(typeof filters?.[key] === "object" ? { ...filters[key] } : {}),
            [objectType]: value,
          },
        });
        break;
      }

      case "timeZone": {
        setFilters({
          ...filters,
          startAt: {
            ...(typeof filters?.startAt === "object" ? { ...filters.startAt } : {}),
            [type]: value,
          },
          endAt: {
            ...(typeof filters?.endAt === "object" ? { ...filters.endAt } : {}),
            [type]: value,
          },
        });
        break;
      }

      case "youtubeLink":
        setFilters({
          ...filters,
          image: null,
          [type]: value,
        });
        break;

      default:
        setFilters({
          ...filters,
          [type]: value,
        });
    }
  }

  const handleFinish = async () => {
    const { title, description, address, dsc, distributor, region, youtubeLink, urlLink, endAt, startAt, image } = filters as any

    const convertObjectToDate = (dateAndTime: { date: string; time: string; timeZone: string }): Date => {
      const { date, time, timeZone } = dateAndTime
      const dateTimeString = `${date}T${time}:00.000`
      const dateTime: DateTime = DateTime.fromISO(dateTimeString, { zone: timeZone })
      return dateTime.toJSDate()
    }
    const convertTimeZone = (timeZone: string) =>
      timeZone.replace("+", "plus").replace("-", "minus") as EnumAppnewspostTimezone

    try {
      setFinishLoading(true)
      let imageId

      if (image) {
        if (event?.attributes?.media?.data?.[0]?.id) {
          try {
            await fetch(url + `/api/upload/files/${event?.attributes?.media?.data?.[0]?.id}`, {
              method: "DELETE",
              body: JSON.stringify({
                accessToken: localStorage.getItem("accessToken"),
              })
            })
          } catch(error) {
            console.log(error)
          }
        }
        const uploadResponse = await fetch(url + "/api/upload", {
          method: "POST",
          body: image,
        })
        const uploadData = await uploadResponse.json()
        if (!uploadData?.[0]?.id) {
          throw new Error("failed to upload image")
        }
        imageId = uploadData?.[0]?.id
      }

      const selectedRegion =
        !region ? null :
        +options?.regions?.find(option => option.label == region)?.key! > 0 ?
          [options?.regions?.find(option => option.label == region)?.key] :
          options?.regions?.filter(option => +option.key > 0)?.map(option => option.key)
      const selectedDistributor = options?.distributors?.find(option => option.label == distributor)?.key
      const selectedDsc = options?.dscs?.find(option => option.label == dsc)?.key

      const data: EventInput = {
        title,
        description,
        address: address || null,
        eventLink: urlLink || null,
        youtubeLink: youtubeLink || null,
        media: youtubeLink ? null : imageId,
        startAt: convertObjectToDate(startAt),
        endAt: convertObjectToDate(endAt),
        timeZone: convertTimeZone(startAt.timeZone),
        dscs: selectedDsc ? [selectedDsc] : null,
        regions: selectedRegion?.length ? selectedRegion : null,
        distributors: selectedDistributor ? [selectedDistributor] : null
      }

      let newEvent
      let updatedEvent

      if (!selectedEventId) {
        newEvent = await createEvent({
          variables: {
            data,
          },
        })
      } else {
        updatedEvent = await updateEvent({
          variables: {
            id: String(selectedEventId),
            data,
          },
        })
      }

      if (newEvent?.data?.createEvent?.data?.id || updatedEvent?.data?.updateEvent?.data?.id) {
        let newFilters = JSON.parse(JSON.stringify(filters))
        newFilters.currentImage =
          newEvent?.data?.createEvent?.data?.attributes?.media?.data?.[0]?.attributes?.url ||
          updatedEvent?.data?.updateEvent?.data?.attributes?.media?.data?.[0]?.attributes?.url ||
          ""
        newFilters.image = null
        setInitialFilters(newFilters)
        setFilters(newFilters)
      }

      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    loading: boolean,
    options: SelectItems,
    isRequired = true,
    isDisabled = false
  ) => {
    const periodType = type?.includes("start") || type?.includes("timeZone") ? "startAt" : type?.includes("end") ? "endAt" : null
    const dateType = type?.replace("start-", "").replace("end-", "")

    let value;
    switch (periodType) {
      case "startAt":
        value = filters?.startAt?.[dateType] || ""
        break
      case "endAt":
        value = filters?.endAt?.[dateType] || ""
        break
      default:
        value = filters?.[type] || ""
      break
    }

    let SuffixIcon
    switch (dateType) {
      case "date":
        SuffixIcon = CalendarIcon
        break
      case "time":
        SuffixIcon = ClockIcon
        break
      default:
        SuffixIcon = SearchIcon
        break
    }

    const handleCalendarClick = (eventType: string, periodType: string) => {
      switch (eventType) {
        case "ok":
          calendarSelectElement?.current?.focus()
          calendarSelectElement?.current?.blur()
          break
        case "cancel":
          handleValuesChange("", periodType)
          calendarSelectElement?.current?.focus()
          calendarSelectElement?.current?.blur()
          break
      }
    }

    const canBeRemoved = !isDisabled && !isRequired && value

    return (
      <AntForm.Item name={name} label={name} required={isRequired}>
        <div className={"input-wrapper"}>
          {canBeRemoved && (
            <div className={"remove-icon"} children={<CloseIcon />} onClick={() => handleValuesChange("", type)} />
          )}
          <Select
            {...(type == "start-date" || type == "end-date" ? { ref: calendarSelectElement } : {})}
            showSearch
            value={value}
            options={options}
            disabled={isDisabled}
            optionFilterProp='children'
            filterOption={filteredOption as any}
            onChange={data => handleValuesChange(data, type)}
            defaultActiveFirstOption={true}
            notFoundContent={
              type == "start-date" || type == "end-date" ? (
                <div>
                  <CustomCalendar
                    fullscreen={false}
                    onSelect={(data: any) =>
                      handleValuesChange(
                        `${String(data.$y)}-${String(data.$M + 1).padStart(2, "0")}-${String(data.$D).padStart(2, "0")}`,
                        type
                      )
                    }
                  />
                  <div className={"calendar-buttons"}>
                    <button onClick={() => handleCalendarClick("cancel", periodType!)} className={"cancel"} children={"Cancel"} />
                    <button onClick={() => handleCalendarClick("ok", periodType!)} children={"OK"} />
                  </div>
                </div>
              ) : loading || !options ? (
                <div className={"no-data"} children={<Spin />} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
              )
            }
            suffixIcon={<SuffixIcon style={canBeRemoved ? { opacity: 0 } : {}} />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createInputElement = (type: string, label: string, isRequired = true) => (
    <AntForm.Item label={label} required={isRequired}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          onChange={e => handleValuesChange(e.target.value, type)}
          value={(filters?.[type] as string) || ""}
        />
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    if (filters && initialFilters) {
      const notRequiredKeys = ["distributor", "region", "dsc", "address", "youtubeLink", "urlLink", "currentImage"]
      if (filters.currentImage || filters.youtubeLink) notRequiredKeys.push("image")

      const initialRootValues = JSON.parse(JSON.stringify(initialFilters))
      delete initialRootValues.startAt
      delete initialRootValues.endAt
      const rootValues = JSON.parse(JSON.stringify(filters))
      delete rootValues.startAt
      delete rootValues.endAt

      const isDifferentRootValues = !areObjectsEqual(rootValues, initialRootValues)
      const isRootValuesValid =
        areAllKeysPopulated(rootValues, notRequiredKeys) &&
        (filters.region || filters.distributor || filters.dsc)

      const isDifferentDate = !areObjectsEqual(filters.startAt, initialFilters.startAt) || !areObjectsEqual(filters.endAt, initialFilters.endAt)
      const isDateValuesValid = areAllKeysPopulated(filters.startAt, []) && areAllKeysPopulated(filters.endAt, [])

      setIsFormReady((isDifferentRootValues || isDifferentDate) && isRootValuesValid && isDateValuesValid)
      errorMessage && setErrorMessage("")
    }
  }, [filters, initialFilters])

  useEffect(() => {
    getItems()
  }, [])

  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper event"}>
          <div className={"block"}>
            <div className={"title"}>TITLE</div>
            <div className={"inputs-wrapper wide-input"}>
              {createInputElement("title", "Title")}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>DESCRIPTION</div>
            <div className={"inputs-wrapper wide-input"}>
              <TextEditor
                handleMarkdown={handleValuesChange}
                title={"Description"}
                type={"description"}
                fetchedMarkdown={event?.attributes?.description as string | undefined}
              />
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>LOCATION (Live, Online, Offline, Website, Physical Address etc)</div>
            <div className={"inputs-wrapper wide-input"}>{createInputElement("address", "Location", false)}</div>
          </div>

          <div className={"block"}>
            <div className={"title"}>LINKS</div>
            <div className={"inputs-wrapper wide-input"}>
              {createInputElement("youtubeLink", "YouTube Link", false)}
              {createInputElement("urlLink", "URL Link", false)}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>
              <div children={"STARTS"} />
              <div children={"ENDS"} />
            </div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("start-date", "Start date", loading, [], true, false)}
              {createSelectElement("end-date", "End date", loading, [], true, false)}
              {createSelectElement("start-time", "Start time", loading, generateTimeOptions(), true, false)}
              {createSelectElement("end-time", "End time", loading, generateTimeOptions(), true, false)}
              {createSelectElement("timeZone", "Time zone", loading, generateTimeZoneOptions(), true, false)}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>
              <div className={"invitation"}>
                <span>* </span>INVITATION (Must choose one option)</div>
            </div>
            <div className={"extra-description"}>
              Notification for this Event will be sent automatically to the chosen regions or users on the date when the event starts.
            </div>
            <div className={"inputs-wrapper invitation"}>
              {createSelectElement("region", "Region", loading, options.regions!, false, false)}
              {createSelectElement("distributor", "Distributor", loading, options.distributors!, false, false)}
              {createSelectElement("dsc", "DSC", loading, options.dscs!, false, false)}
            </div>
          </div>

          {errorMessage && <div className={"error"}>{errorMessage}</div>}
        </div>
      </AntForm>

      {blockPermissions?.edit && (
      <button
        type={"submit"}
        onClick={handleFinish}
        className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
        disabled={!isFormReady || isChangesDisabled}
      >
        Save
        {finishLoading ? <Spin /> : <SaveIcon />}
      </button>
      )}

      {selectedEventId && blockPermissions?.delete ? (
        <button onClick={() => showModal()} className={"delete"}>
          Delete
          <TrashIcon />
        </button>
      ) : null}
    </>
  )
}

export default Form
