import React, { FC } from "react"
import Header from "./components/Header"
import Navigation from "./components/Navigation"
import "./index.less"

const Account: FC<AccountInterface> = ({ pageName, children }) => {
  return (
    <>
      <Header pageName={pageName} />
      <Navigation />
      <main className={"main-wrapper"}>{children}</main>
    </>
  )
}
export default Account
