import React, { FC } from "react"
import { useLocation } from "react-router"
import ListBlock from "./blocks/ListBlock"
import ManageBlock from "./blocks/ManageBlock"
import "./index.less"

const Links: FC = () => {
  const { pathname } = useLocation()
  const pathIdentifier = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createPdfMode = pathIdentifier == "new-link"
  const selectedPdfId = Number(pathIdentifier)

  return (
    <section className={"pdfs"}>
      {!createPdfMode && !selectedPdfId && <ListBlock />}
      {(selectedPdfId > 0 || createPdfMode) && <ManageBlock selectedPdfId={selectedPdfId} />}
    </section>
  )
}

export default Links
