import React, { FC, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useAuth } from "../../../../../../components/auth"
import { ReactComponent as Chevron } from "../../../../../../icons/chevron_down.svg"
import "./index.less"

const NavItem: FC<NavItemInterface> = ({ name, link, label, openedElement, setOpenedElement, children }) => {
  const { pathname } = useLocation()
  const { logout } = useAuth()
  const [blockHeight, setBlockHeight] = useState<number>()
  const navItemRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const handleElementClick = () => {
    if (children) {
      setOpenedElement!(name.toLowerCase())
      return
    }
    if (name != "Log Out") {
      navigate(`/account/${link}`)
      return
    }
    logout()
  }

  const handleStyle = () => {
    if (pathname.includes(`/account/${name.toLowerCase().replace(" ", "-")}`) && !children) {
      return { backgroundColor: "#677685", color: "#fff" }
    }
    return {}
  }

  const isOpen =
    (!openedElement && pathname?.includes(`/account/${name.toLowerCase()}`)) || openedElement == name?.toLowerCase()

  useEffect(() => {
    if (navItemRef?.current) {
      const childrenAmount = React.Children.count(children) || 0
      const navElementHeight = parseFloat(window.getComputedStyle(navItemRef.current).height)
      setBlockHeight((childrenAmount + 1) * navElementHeight)
    }
  }, [navItemRef?.current])

  return (
    <div className={"wrapper"} style={isOpen ? { height: blockHeight } : {}}>
      <div
        className={`nav-item ${(isOpen || (pathname.includes(`/account/${name.toLowerCase()?.replace(" ", "-")}`) && !children)) && "active"} ${name.toLowerCase()}`}
        onClick={handleElementClick}
        ref={navItemRef}
        style={handleStyle()}
      >
        <div className={"description"}>
          <div className={`label-wrapper ${isOpen && "open"}`}>{label}</div>
          {name}
        </div>
        {children && <Chevron style={isOpen ? { transform: "rotate(180deg)", fill: "#677685" } : {}} />}
      </div>
      {children}
    </div>
  )
}
export default NavItem
