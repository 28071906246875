import type { PaginationProps } from "antd"
import { ConfigProvider, Empty, Pagination, Select, Spin } from "antd"
import React, { FC, useState } from "react"
import { CSVLink } from "react-csv"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useGetActivityLogActionsForLazyQuery, useGetActivityLogUsersLazyQuery } from "../../../../../graphql"
import { DownloadIcon, SearchIcon } from "../../../../../icons"
import { capitalizeFirstLetter, formatDate, removeSelectDuplicates } from "../../../../../utils"
import "./index.less"

const List: FC<ActivityLogListInterface> = ({
  list,
  page,
  loading,
  setPage,
  filters,
  pageSize,
  setFilters,
  totalItems,
  setLoading,
  setPageSize,
  exportLoading,
  listForExport,
  downloadBtnRef,
  createExportList,
  createQueryFilters,
}) => {
  const [getNames] = useGetActivityLogUsersLazyQuery()
  const [getActionsFor] = useGetActivityLogActionsForLazyQuery()

  const [names, setNames] = useState<SelectItems>()
  const [actionsFor, setActionsFor] = useState<SelectItems>()

  const onChangePage: PaginationProps["onChange"] = (pageNumber: number) => setPage(pageNumber)
  const onChangePageSize: PaginationProps["onShowSizeChange"] = (_, pageSize: number) => setPageSize(pageSize)

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const getItems = async (type: string) => {
    setLoading(true)

    const queryOptions: any = {
      fetchPolicy: "network-only",
      variables: {
        input: {
          ...createQueryFilters(),
          page: 1,
          pageSize: 1000,
        },
      },
    }

    switch (type) {
      case "name":
        const namesData = await getNames(queryOptions)
        setNames(
          removeSelectDuplicates(
            namesData?.data?.getAdminActivityLog?.data
              ?.map((item: any, i: number) => ({
                value: item?.user?.name!,
                label: item?.user?.name!,
                key: String(i),
              }))
              .filter((item: any) => item.value)!
          ).sort((a, b) => a.value!.localeCompare(b.value!))
        )
        break

      case "action-for":
        const actionsForData = await getActionsFor(queryOptions)
        setActionsFor(
          removeSelectDuplicates(
            actionsForData?.data?.getAdminActivityLog?.data
              ?.map((item: any, i: number) => ({
                value: item?.action_for!,
                label: item?.action_for!,
                key: String(i),
              }))
              .filter((item: any) => item.value)!
          ).sort((a, b) => a.value!.localeCompare(b.value!))
        )
        break
    }

    setLoading(false)
  }

  const handleFilter = (data: string, type: string) => {
    switch (type) {
      case "name":
        setFilters({
          ...filters,
          selectedName: data,
        })
        break

      case "action-for":
        setFilters({
          ...filters,
          selectedActionFor: data,
        })
        break
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    query: () => void,
    loading: boolean,
    options: SelectItems
  ) => (
    <div
      className={
        `${type} ${(
          (type == "name" && filters?.selectedName !== "All") ||
          (type == "action-for" && filters?.selectedActionFor !== "All")
        ) && "active"
        }`}
      children={
        <Select
          showSearch
          value={name}
          options={options}
          optionFilterProp='children'
          defaultActiveFirstOption={true}
          onDropdownVisibleChange={query}
          filterOption={filteredOption as any}
          onChange={data => handleFilter(data, type)}
          notFoundContent={
            loading || !options ? (
              <div className={"no-data"} children={<Spin />} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
            )
          }
          suffixIcon={<SearchIcon />}
        />
      }
    />
  )

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        key={JSON.stringify(filters) + String(page) + String(pageSize)}
        timeout={250}
        classNames={"fade"}
        appear={true}
        unmountOnExit
      >
        <div className={"main-list-wrapper activity-log-list"}>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "black",
                  colorPrimaryBorder: "black",
                  colorPrimaryHover: "black",
                  controlOutline: "#fff",
                },
                Select: {
                  colorText: "#677685",
                  colorPrimaryBorder: "#fff",
                  colorPrimaryHover: "#fff",
                  controlOutline: "#fff",
                  colorBgContainerDisabled: "#fff",
                },
              },
            }}
          >
            <div className={"list-wrapper"}>
              <div className={"items top"}>
                <div children={"DATE / TIME"} />
                {createSelectElement("name", "NAME", () => getItems("name"), loading, names!)}
                <div children={"ACTION"} />
                <div children={"TYPE"} />
                {createSelectElement("action-for", "ACTION FOR", () => getItems("action-for"), loading, actionsFor!)}
              </div>

              {list &&
                list.map((item: any, i: number) => (
                  <div className={"items"} key={`list-item-${i}`}>
                    <div children={<p>{formatDate(item?.createdAt, true)}</p>} />
                    <div children={<p>{item?.user?.name || "Unknown"}</p>} />
                    <div
                      className={`action ${item?.action?.toLowerCase() == "deleted" || item?.action?.toLowerCase() == "blocked" ? "negative" : "positive"}`}
                      children={<p>{capitalizeFirstLetter(item?.action) || "-"}</p>}
                    />
                    <div children={<p>{capitalizeFirstLetter(item?.type) || "-"}</p>} />
                    <div children={<p>{item?.action_for || "-"}</p>} />
                  </div>
                ))}

              {!list && <div className={"spinner-wrapper"} children={<Spin />} />}

              {list?.length == 0 && (
                <div className={"empty"}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>

            <div
              className={"navigation"}
              children={
                <Pagination
                  showQuickJumper
                  current={page}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                />
              }
            />

            <button onClick={() => createExportList()} className={"download"} disabled={!list}>
              Download CSV
              {exportLoading ? <Spin /> : <DownloadIcon />}
            </button>

            <CSVLink data={listForExport || []} filename={"activity-log"} ref={downloadBtnRef as any} />
          </ConfigProvider>
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default List
