import React, { FC } from "react"
import { useLocation, useNavigate } from "react-router"
import "./index.less"

const Navigation: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const navItems = ["Account", "Password", "Legal"]
  const pathIndicator = pathname?.split("/")[pathname?.split("/")?.length - 1]

  return (
    <div className={"profile-navigation"}>
      <div className={"menu"}>
        {navItems.map(item => (
          <nav
            children={item}
            key={`nav-${item}`}
            className={pathIndicator == item?.toLowerCase() ? "active" : ""}
            onClick={() => navigate(`/account/profile/${item?.toLowerCase()}`)}
          />
        ))}
      </div>
    </div>
  )
}

export default Navigation
