import React, { FC, SetStateAction, useEffect, useState } from "react"
import { useCountUsersQuery } from "../../../../../graphql"
import { ReactComponent as BadgeIcon } from "../../../../../icons/badge.svg"
import "./index.less"
import { Dropdown, Menu, Space, Typography } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { useAuth } from "../../../../../components/auth"
import { truncate } from "../../../../../utils"
const pagination = { limit: 10000, start: 0 }

const ThirdBlock: FC<{
  regionOptions: SelectItems,
  blockLoading: {[key: string]: boolean}
  setBlockLoading: React.Dispatch<SetStateAction<{ [key: string]: boolean }>>
}> = ({
  blockLoading,
  regionOptions,
  setBlockLoading
}) => {

  const {region} = useAuth()

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedRegion: region?.name || "All",
  })

  const createVariables = (ranking: string) => ({
      filter: {
        dsc: {
          ...(filters?.selectedRegion !== "All" || region?.id ?  {
            sales_manager: {
              distributor: {
                region: {
                  id: {
                    eq: region?.id || regionOptions.find(option => option.label == filters.selectedRegion)!.key
                  }
                }
              }
            }
          } : {}),
          ranking: {
            eq: ranking
          }
        }
      },
      pagination
    }
  )

  const { data: diamondUsers, loading: diamondLoading } = useCountUsersQuery({
    variables: createVariables("DIAMOND")
  })
  const { data: platinumUsers, loading: platinumLoading } = useCountUsersQuery({
    variables: createVariables("PLATINUM")
  })
  const { data: goldUsers, loading: goldLoading } = useCountUsersQuery({
    variables: createVariables("GOLD")
  })

  const loading = diamondLoading || platinumLoading || goldLoading

  const createBlock = (title: string, amount: number) => (
    <div className={`block ${loading && "skeleton"}`}>
      <BadgeIcon style={loading ? {display: "none"}: {}}/>
      <div className={"title"} children={title} />
      <div className={"count"}>
        <div className={"amount"} children={amount} />
        <span children={"This month"} />
      </div>
    </div>
  )

  const createFilter = (options: SelectItems | undefined, filterName: string) => {
    const isActive = filters?.selectedRegion !== "All" && !region?.id
    const filterType = "region-filter"

    return (
      <Dropdown
        disabled={!options || !!region?.id}
        className={`${filterType} ${isActive && "active"}`}
        trigger={["click"]}
        overlay={
          <Menu
            onClick={e =>
              setFilters({
                selectedRegion: options?.find(item => +item.key == +e.key)!.label!,
              })
            }
            selectedKeys={[`${options?.find(item => item.label == filters[filterName])?.key}`]}
          >
            {options?.map(item => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </Menu>
        }
      >
        <Typography.Link>
          <Space className={"dropdown"}>
            {truncate(options?.find(item => item.label == filters[filterName])?.label!, 20) || ""}
            <DownOutlined style={{ color: "black" }} />
          </Space>
        </Typography.Link>
      </Dropdown>
    )
  }

  useEffect(() => {setBlockLoading({...blockLoading, thirdBlock: !loading})}, [loading])

  return (
    <div className={"blocks-wrapper badges"}>
      <div>
        <div className={"title"}>BADGES</div>
        <div className={"filters"}>
          {createFilter(regionOptions, "selectedRegion")}
        </div>
        <div className={"badges-blocks"}>
          {createBlock("DIAMOND", diamondUsers?.usersPermissionsUsers?.meta.pagination.total || 0)}
          {createBlock("PLATINUM", platinumUsers?.usersPermissionsUsers?.meta.pagination.total || 0)}
          {createBlock("GOLD", goldUsers?.usersPermissionsUsers?.meta.pagination.total || 0)}
        </div>
      </div>
    </div>
  )
}

export default ThirdBlock
