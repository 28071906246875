import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { useGetRegionsQuery } from "../../../../../../../graphql"
import { truncate } from "../../../../../../../utils"
import "./index.less"
import { useAuth } from "../../../../../../../components/auth"

const Filter: FC<RoleFilterInterface> = ({ filters, setFilters, specificFilters }) => {
  const {region} = useAuth()
  const { data: regionsData, loading } = useGetRegionsQuery()

  const getRegionOptions = () => {
    const regionOptions = Array.from({ length: regionsData?.regions?.data.length || 0 }, (_, i) => ({
      label: regionsData?.regions?.data[i].attributes?.name,
      key: i + 1,
    }))
      regionOptions.unshift({
        label: "All",
        key: 0,
      })
    return regionOptions || []
  }

  let regionOptions = getRegionOptions()
  if (region?.name) {
    const adminRegion = region!.name!
    regionOptions = [{ label: adminRegion, key: 0}]
  }

  const statusOptions = ["All", "Active", "Inactive", "Blocked"].map((label, index) => ({ label, key: index + 1 }));

  const insiderStatusOptions = ["All", "Allowed", "Not Allowed"].map((label, index) => ({ label, key: index + 1 }));

  const resetFilter = (type: string) => {
    const handleResetFilters = (fieldType: string) =>
      setFilters({ ...filters, [fieldType]: "All" })
    switch (type) {
      case "name": handleResetFilters("selectedName")
        break
      case "email": handleResetFilters("selectedEmail")
        break
      case "sales-manager": handleResetFilters("selectedSM")
        break
      case "distributor": handleResetFilters("selectedDistributor")
        break
    }
  }

  const activeSpecificFiltersAmount = specificFilters.length

  return (
    <div className={"filters-wrapper salons-filter"}>
      <div className={"filters"}>
        <Dropdown
          className={`region-filter ${filters.selectedRegion != "All" && "active"}`}
          trigger={["click"]}
          disabled={loading}
          overlay={
            <Menu
              onClick={e =>
                setFilters({ ...filters, selectedRegion: regionOptions.find(item => item.key == +e.key)!.label!})
              }
              selectedKeys={[`${regionOptions.find(item => item.label == filters.selectedRegion)?.key}`]}
            >
              {regionOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {filters.selectedRegion ? truncate(regionOptions.find(item => item.label == filters.selectedRegion)!.label!, 13) : "All"}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>

        <Dropdown
          className={`status-filter ${filters.selectedStatus != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e =>
                setFilters({
                  ...filters,
                  selectedStatus: statusOptions.find(item => item.key == +e.key)!.label! || "All",
                })
              }
              selectedKeys={[`${statusOptions.find(item => item.label == filters.selectedStatus)!.key}`]}
            >
              {statusOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(statusOptions.find(item => item.label == filters.selectedStatus)!.label!, 15)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>

        <Dropdown
          className={`insider-status-filter ${filters.selectedInsiderStatus != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e =>
                setFilters({
                  ...filters,
                  selectedInsiderStatus: insiderStatusOptions.find(item => item.key == +e.key)!.label! || "All",
                })
              }
              selectedKeys={[`${insiderStatusOptions.find(item => item.label == filters.selectedInsiderStatus)!.key}`]}
            >
              {insiderStatusOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(insiderStatusOptions.find(item => item.label == filters.selectedInsiderStatus)!.label!, 15)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>

      <div className={"specific-filters-wrapper"}>
        Filters ({activeSpecificFiltersAmount})
        <div className={"specific-filters-list"}>
          {specificFilters &&
            specificFilters.map((filter: any, i: number) => (
              <div
                className={"block"}
                key={`filter-${i}`}
                style={{ backgroundColor: filter.backgroundColor, color: filter.color }}
              >
                {truncate(filter.option, 17)}
                <div className={"remove-filter"} onClick={() => resetFilter(filter.type)}>
                  <div className={"l-line"} />
                  <div className={"r-line"} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
