import { Spin } from "antd"
import { DateTime } from "luxon"
import React, { FC, useEffect, useState } from "react"
import { useGetNotificationQuery, useGetNotificationTypesQuery } from "../../../../../graphql"
import LeftSide from "./components/LeftSide"
import RightSide from "./components/RightSide"
import "./index.less"

const ManageBlock: FC<ManageNotificationsBlockInterface> = ({ selectedNotificationId }) => {
  const { data: notificationData, loading: notificationLoading } = useGetNotificationQuery({
    fetchPolicy: "network-only",
    variables: {
      id: String(selectedNotificationId),
    },
  })
  const notification = notificationData?.appNotification?.data

  const { data: typesData, loading: typesLoading } = useGetNotificationTypesQuery()

  const customTypeId = typesData?.appNotificationTypes?.data?.find(
    type => type?.attributes?.name?.toLowerCase() == "custom"
  )?.id
  const eventTypeId = typesData?.appNotificationTypes?.data?.find(
    type => type?.attributes?.name?.toLowerCase() == "event"
  )?.id

  const loading = notificationLoading || typesLoading

  const [initialFilters, setInitialFilters] = useState<{ [key: string]: string | number | Object }>()
  const [filters, setFilters] = useState<{ [key: string]: string | number | Object }>()

  const [options, setOptions] = useState<{ [key: string]: SelectItems | null }>({
    events: null,
    dscs: null,
    distributors: null,
    regions: null
  })

  const convertDateToObject = (date: Date | null | undefined, timeZone: string | null | undefined) => {
    const jsDate = new Date(date as any)
    const formattedTimeZone = timeZone?.replace("minus", "-")?.replace("plus", "+")
    const dateTime = DateTime.fromJSDate(jsDate, { zone: formattedTimeZone })
    const year = dateTime.year
    const month = String(dateTime.month).padStart(2, "0")
    const day = String(dateTime.day).padStart(2, "0")
    const hours = String(dateTime.hour).padStart(2, "0")
    const minutes = String(dateTime.minute).padStart(2, "0")
    const datePart = `${year}-${month}-${day}`
    const timePart = `${hours}:${minutes}`

    return {
      dateAndTime: {
        date: date ? datePart : "",
        time: date ? timePart : "",
        timeZone: date ? formattedTimeZone : "",
      },
    }
  }

  useEffect(() => {
    if (customTypeId && ((selectedNotificationId && notification) || !selectedNotificationId)) {
      const filters = {
        title: notification?.attributes?.title || "",
        description: notification?.attributes?.body || "",
        event: notification?.attributes?.event?.data?.attributes?.title || "",
        dsc: notification?.attributes?.user?.data?.attributes?.name || "",
        distributor:
          options?.distributors?.find(option => option.key == notification?.attributes?.distributors?.data?.[0]?.id)?.label ||
          "",
        region: notification?.attributes?.regions?.data?.length! > 1
          ? "All"
          : options?.regions?.find(option => option.key == notification?.attributes?.regions?.data?.[0]?.id)?.label
          || "",
        ...convertDateToObject(notification?.attributes?.pushAt, notification?.attributes?.timeZone),
      }

      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [notification, options, customTypeId])

  const isChangesDisabled = !!(initialFilters?.title && !selectedNotificationId)
  const isPageReady = !loading && customTypeId && filters && initialFilters

  return (
    <div className={`block-manage-wrapper ${loading && "center"}`}>
      {isPageReady ? (
        <>
          <LeftSide
            {...{
              options,
              filters,
              setOptions,
              setFilters,
              notification,
              eventTypeId,
              customTypeId,
              initialFilters,
              isChangesDisabled,
              setInitialFilters,
              selectedNotificationId,
            }}
          />
          <RightSide
            {...{
              filters,
              notification,
            }}
          />
        </>
      ) : (
        <Spin className={"notifications-manage-block-spin"} />
      )}
    </div>
  )
}

export default ManageBlock
