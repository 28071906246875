import generateCalendar from "antd/es/calendar/generateCalendar"
import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/en"
import "dayjs/locale/en-gb"
import updateLocale from "dayjs/plugin/updateLocale"
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs"

dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
  weekStart: 1,
})
export const CustomCalendar = generateCalendar<Dayjs>(dayjsGenerateConfig)
