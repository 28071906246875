import "./index.less"
import Form from "./components/Form"
import { useNavigate } from "react-router"
import React, { FC, useState } from "react"
import { ConfigProvider, Modal, Spin } from "antd"
import { DangerInfoIcon } from "../../../../../../../icons"
import { useDeleteEventMutation } from "../../../../../../../graphql"

const LeftSide: FC<EventManageBlockLeftSideInterface> = ({
  event,
  options,
  filters,
  setOptions,
  setFilters,
  initialFilters,
  selectedEventId,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)

  const [deleteEvent] = useDeleteEventMutation()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleModalOk = async () => {
    setModalLoading(true)

    try {
          const postResponse = await deleteEvent({
            variables: {
              id: String(selectedEventId),
            },
          })
          if (postResponse?.data?.deleteEvent?.data?.id) {
            setIsModalOpen(false)
            setTimeout(() => {
              navigate("/account/content/events")
            }, 500)
          }
      setModalLoading(false)

    } catch (error) {
      setModalLoading(false)
    }
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className={"left-side-wrapper"}>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBorder: "#ffffff",
              colorPrimaryHover: "#ffffff",
              controlOutline: "#ffffff",
              colorError: "#ffffff",
              colorErrorBorderHover: "#ffffff",
              colorErrorOutline: "#ffffff",
              colorIcon: "#1C1C23",
              borderRadius: 0,
            },
            Select: {
              colorText: "#000",
              colorPrimaryBorder: "#fff",
              colorPrimaryHover: "#fff",
              controlOutline: "#fff",
              colorBgContainerDisabled: "#fff",
              colorBorder: "#fff",
            },
            Button: {
              borderRadius: 0,
              colorBorder: "#000",
              colorPrimary: "#000",
              colorPrimaryHover: "#707070",
              colorText: "#000",
            },
            Calendar: {
              colorPrimary: "#000",
              colorPrimaryHover: "#707070",
            },
          },
        }}
      >
        <Form
          {...{
            event,
            options,
            filters,
            showModal,
            setOptions,
            setFilters,
            initialFilters,
            selectedEventId,
            isChangesDisabled,
            setInitialFilters,
          }}
        />

        <Modal open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel} className={"event"}>
          <div className={"modal-content-wrapper"}>
            <DangerInfoIcon />
            <p>Are you sure you want to delete an existing event?</p>
            <div className={"loading"}>{modalLoading && <Spin />}</div>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default LeftSide
