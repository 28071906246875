import { ConfigProvider, Modal, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useAuth } from "../../../components/auth"
import { useGetLinkListQuery, useGetMoreSectionTypesQuery, useUpdateMoreSectionTypesMutation } from "../../../graphql"
import { ArrowUpDown, SaveIcon } from "../../../icons"
import ImageLoader from "../components/ImageLoader"
import LinksManagement from "./components/LinksManagement"
import SectionsManagement from "./components/SectionsManagement"
const pagination = { limit: 10000, start: 0 }
const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""
import "./index.less"

const m = "mobile_cover"
const t = "tablet_cover"
const l = "landscape_cover"

const More: FC = () => {
  const { permissions } = useAuth()
  const blockPermissions = permissions?.firstMenu
    ?.find(item => item.name == "Content")
    ?.blocks?.find((block: any) => block.name == "More")

  const [updateCover] = useUpdateMoreSectionTypesMutation()
  const { data: links, refetch: refetchLinks } = useGetLinkListQuery({
    fetchPolicy: "network-only",
    variables: {
      pagination,
      filter: {
        type: {
          ne: "news",
        },
      },
      sort: "sequence:asc",
    },
  })
  const { data: moreSectionTypes, refetch: refetchMoreSectionTypes } = useGetMoreSectionTypesQuery({
    fetchPolicy: "no-cache",
    variables: {
      pagination,
      sort: "sequence:asc",
    },
  })

  const [sectionsManagementMode, setSectionsManagementMode] = useState<boolean>(false)
  const [linksManagementMode, setLinksManagementMode] = useState<{
    status: boolean
    links: LinkInterface[] | undefined
  }>({
    status: false,
    links: undefined,
  })
  const isModalOpen = linksManagementMode.status || sectionsManagementMode

  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)

  const [filters, setFilters] = useState<{ [key: string]: any }>()
  const visibleSections = filters?.items?.length ? filters.items.filter((item: any) => !item.isHidden) : []
  const updatedSections = filters?.items?.filter(
    (item: MoreFilterItemInterface) =>
      typeof item[m] == "number" || typeof item[t] == "number" || typeof item[l] == "number"
  )

  const detectFormData = () => {
    let itemIndex
    let itemType
    let formData

    filters!.items.some((item: MoreFilterItemInterface, i: number) => {
      if (typeof item[m] === "object") {
        itemIndex = i
        itemType = m
        formData = item[m]
        return true
      }
      if (typeof item[t] === "object") {
        itemIndex = i
        itemType = t
        formData = item[t]
        return true
      }
      if (typeof item[l] === "object") {
        itemIndex = i
        itemType = l
        formData = item[l]
        return true
      }
      return false
    })

    return { itemIndex, itemType, formData }
  }

  const uploadImage = async (image: FormData, itemIndex: number, itemType: string) => {
    setIsFormReady(false)
    let imageId: number

    try {
      const uploadResponse = await fetch(url + "/api/upload", {
        method: "POST",
        body: image,
      })

      const uploadData = await uploadResponse.json()
      if (!uploadData?.[0]?.id) {
        throw new Error("failed to upload image")
      }
      imageId = uploadData?.[0]?.id

      setFilters({
        ...filters,
        items: [
          ...(filters!.items as any).map((item: any, i: number) => ({
            ...item,
            ...(i == itemIndex ? { [itemType]: imageId } : {}),
          })),
        ],
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSave = async () => {
    setFinishLoading(true)

    for (let i = 0; i < updatedSections.length; i++) {
      let itemTypes: string[] = []
      const item = updatedSections[i]

      let imageIds = []
      if (typeof item[m] == "number") {
        imageIds.push(item[m])
        itemTypes.push(m)
      }
      if (typeof item[t] == "number") {
        imageIds.push(item[t])
        itemTypes.push(t)
      }
      if (typeof item[l] == "number") {
        imageIds.push(item[l])
        itemTypes.push(l)
      }

      try {
        await updateCover({
          variables: {
            id: item.itemId,
            data: {
              ...(itemTypes?.[0] ? {[itemTypes[0]]: imageIds[0]} : {}),
              ...(itemTypes?.[1] ? {[itemTypes[1]]: imageIds[1]} : {}),
              ...(itemTypes?.[2] ? {[itemTypes[2]]: imageIds[2]} : {}),
            },
          },
        })
      } catch (error) {
        console.log(error)
      }
    }
    await refetchMoreSectionTypes()
    setFinishLoading(false)
  }

  const showModal = (type: string, links?: LinkInterface[]) => {
    switch (type) {
      case "sections":
        setSectionsManagementMode(true)
        break
      default:
        setLinksManagementMode({ status: true, links })
        break
    }
  }

  const handleModalCancel = () => {
    if (sectionsManagementMode) {
      setSectionsManagementMode(false)
    } else {
      setLinksManagementMode({ status: false, links: undefined })
    }
  }

  useEffect(() => {
    if (moreSectionTypes && links) {
      const filters = {
        items: moreSectionTypes?.appMoreSectionTypes?.data?.map((item, i) => ({
          id: i,
          itemId: item.id,
          name: item.attributes?.label,
          isHidden: item.attributes?.isHidden,
          links: links?.appNewsPosts?.data
            ?.filter(link => link?.attributes?.more_section_type?.data?.id == item.id)
            ?.map(link => ({
              id: link.id,
              label: link.attributes?.title,
              sequence: link.attributes?.sequence,
              moreSectionType: item.attributes?.label,
            })),
          [m]: item.attributes?.[m]?.data?.attributes?.url,
          [t]: item.attributes?.[t]?.data?.attributes?.url,
          [l]: item.attributes?.[l]?.data?.attributes?.url,
        })),
      }
      setFilters(filters)
    }
  }, [moreSectionTypes, links])

  useEffect(() => {
    if (filters?.items) {
      const { itemIndex, itemType, formData } = detectFormData()
      if (formData) uploadImage(formData, itemIndex!, itemType!)

      setIsFormReady(!!updatedSections?.length)
    }
  }, [filters])

  const createLoader = (item: any, imageType: string, maxSize: number) => (
    <div
      key={`loader-${item.id}-${imageType}`}
      className={`image-wrapper ${!item.name ? "skeleton" : !item[imageType] ? "empty" : ""}`}
    >
      <div className={`${typeof item[imageType] == "object" ? "under-loading" : ""}`}>
        {item.name && <div className={"block-name"} children={item.name} />}
        {imageType == t && item.links.length !== 0 && (
          <ArrowUpDown className={"manage-links-btn"} onClick={() => showModal("links", item.links)} />
        )}
        {item.name && (
          <ImageLoader
            type={imageType}
            filters={filters}
            itemIndex={item.id}
            maxImageSize={maxSize}
            setFilters={setFilters}
            isChangesDisabled={false}
            formatsDescription={false}
            fetchedImageURL={typeof item[imageType] !== "string" ? "" : item[imageType]}
          />
        )}
      </div>
    </div>
  )

  return (
    <section className={"more"}>
      <div className={"main-wrapper"}>
        <div className={"title"}>
          IMAGE MANAGEMENT
          <span>(Click on each section of different devices to upload an image “.png, .jpg, .jpeg”)</span>
        </div>
        <div className={"blocks-wrapper"}>
          <div className={"block"}>
            <div className={"block-title"}>
              PHONE
              <span>
                Recommended (1 x 2.4)
                <br />
                Max size (300kb)
              </span>
            </div>
            <div className={"mobile"}>
              <div className={"mobile-form"}>
                {["a", "b", "c", "d"].map(letter => (
                  <div key={`mobile-element-${letter}`} className={`${letter}-line`} />
                ))}
              </div>
              <div className={"images-wrapper"}>
                {visibleSections.map((item: any) => createLoader(item, m, 300000))}
              </div>
            </div>
          </div>

          <div className={"block"}>
            <div className={"block-title"}>
              TABLET (PORTRAIT)
              <span>
                Recommended (1 x 3.8)
                <br />
                Max size (500kb)
              </span>
            </div>
            <div className={"tablet"}>
              <div className={"tablet-form"}>
                {!!filters?.items?.length && !isFormReady && (
                  <>
                    <span className={"frame-title"}>Section Management</span>
                    <ArrowUpDown onClick={() => showModal("sections")} />
                  </>
                )}
                {["a", "b", "c"].map(letter => (
                  <div key={`tablet-element-${letter}`} className={`${letter}-line`} />
                ))}
              </div>
              <div className={"images-wrapper"}>
                {visibleSections.map((item: any) => createLoader(item, t, 500000))}
              </div>
            </div>
          </div>

          <div className={"block"}>
            <div className={"block-title"}>
              TABLET (LANDSCAPE)
              <span>
                Recommended ( 1 x 1.9 )
                <br />
                Max size (700kb)
              </span>
            </div>
            <div className={"landscape"}>
              <div className={"landscape-form"}>
                {["a", "b", "c"].map(letter => (
                  <div key={`landscape-element-${letter}`} className={`${letter}-line`} />
                ))}
              </div>
              <div className={"image-holder"}>{visibleSections.map((item: any) => createLoader(item, l, 700000))}</div>
            </div>
          </div>
        </div>
      </div>

      {blockPermissions?.edit && (
        <button onClick={handleSave} disabled={!isFormReady} className={`save ${isFormReady && "ready"}`}>
          Save
          {finishLoading ? <Spin /> : <SaveIcon />}
        </button>
      )}

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              borderRadiusLG: 0,
            },
            Checkbox: {
              colorBorder: "#000",
              colorPrimaryHover: "#000",
              colorPrimary: "#000",
              borderRadius: 0,
            },
            Input: {
              colorBorder: "#F7F7F7",
              colorPrimaryHover: "#F7F7F7",
              controlOutline: "#F7F7F7",
              colorError: "#F7F7F7",
              colorErrorBorderHover: "#F7F7F7",
              colorErrorOutline: "#F7F7F7",
              borderRadius: 0,
            },
          },
        }}
      >
        <Modal open={isModalOpen} onCancel={handleModalCancel} className={"more"} footer={null}>
          {sectionsManagementMode ? (
            <SectionsManagement
              data={moreSectionTypes!}
              refetch={refetchMoreSectionTypes}
              handleModalCancel={handleModalCancel}
            />
          ) : (
            <LinksManagement
              refetch={refetchLinks}
              data={linksManagementMode.links!}
              handleModalCancel={handleModalCancel}
            />
          )}
        </Modal>
      </ConfigProvider>
    </section>
  )
}

export default More
