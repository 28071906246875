import { useGetProductBenefitsQuery, useGetProductTypesQuery } from "../../../../../../../graphql"
import { Dropdown, Menu, Space, Typography } from "antd"
import { truncate } from "../../../../../../../utils"
import { DownOutlined } from "@ant-design/icons"
import React, { FC } from "react"
import "./index.less"

const Filter: FC<ProductFilterInterface> = ({ brand, filters, setBrand, setFilters, specificFilters }) => {
  const { data: typesData } = useGetProductTypesQuery({
    variables: {
      sort: "name:asc",
      filters: {
        or: [{ brand: { eq: brand !== "KM" ? "COLOR" : "KM" } }, { brand: { eq: "ALL" } }],
      },
    },
  })
  const { data: benefitsData } = useGetProductBenefitsQuery({ variables: { filters: {} } })

  const getTypeOptions = () => {
    const typeOptions = Array.from({ length: typesData?.productTypes?.data?.length || 0 }, (_, i) => ({
      label: typesData!.productTypes!.data[i].attributes!.name,
      key: typesData!.productTypes!.data[i]!.id,
    }))
    typeOptions.unshift({
      label: "All",
      key: "All",
    })
    return typeOptions || []
  }

  const typeOptions = getTypeOptions()

  const getBenefitOptions = () => {
    const benefitOptions = Array.from({ length: benefitsData?.productBenefits?.data?.length || 0 }, (_, i) => ({
      label: benefitsData!.productBenefits!.data[i].attributes!.name,
      key: benefitsData!.productBenefits!.data[i]!.id,
    }))
    benefitOptions.unshift({
      label: "All",
      key: "All",
    })
    return benefitOptions || []
  }

  const benefitOptions = getBenefitOptions()

  const resetFilter = () => {
    setFilters({ ...filters, selectedName: "All" })
  }

  const handleFilter = (key: string, type: string) => {
    switch (type) {
      case "type":
        setFilters({ ...filters, selectedType: typeOptions.find(item => +item.key! == +key)?.key! || "All" })
        break
      case "benefit":
        setFilters({ ...filters, selectedBenefit: benefitOptions.find(item => +item.key! == +key)?.key! || "All" })
        break
    }
  }

  return (
    <div className={`filters-wrapper product-filter`}>
      <div className={`filters brand-${brand}`}>
        <Dropdown
          className={`type-filter ${filters.selectedType != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e => handleFilter(e.key, "type")}
              selectedKeys={[`${typeOptions.find(item => item.key == filters.selectedType)?.key}`]}
            >
              {typeOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {typeOptions.find(item => item.key == filters.selectedType)?.label || "All"}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
        {brand !== "other" && (
          <Dropdown
            className={`regimen-filter ${filters.selectedBenefit != "All" && "active"}`}
            trigger={["click"]}
            overlay={
              <Menu
                onClick={e => handleFilter(e.key, "benefit")}
                selectedKeys={[`${benefitOptions.find(item => item.key == filters.selectedBenefit)?.key}`]}
              >
                {benefitOptions.map(item => (
                  <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <Typography.Link>
              <Space className={"dropdown"}>
                {benefitOptions.find(item => item.key == filters.selectedBenefit)?.label || "All"}
                <DownOutlined style={{ color: "black" }} />
              </Space>
            </Typography.Link>
          </Dropdown>
        )}
      </div>

      <div className={"specific-filters-wrapper"}>
        Selected Product:
        <div className={"specific-filters-list"}>
          {specificFilters &&
            specificFilters.map((filter: any, i: number) => (
              <div className={"block"} key={`filter-${i}`} style={{backgroundColor: filter.backgroundColor, color: filter.color}}>
                {truncate(filter.option, 40)}
                <div className={"remove-filter"} onClick={resetFilter}>
                  <div className={"l-line"} />
                  <div className={"r-line"} />
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={"tabs-wrapper"}>
        Brands:
        <div className={"tabs"}>
          <div
            className={brand == "KM" ? "active" : ""}
            onClick={() => {
              setBrand("KM")
            }}
          >
            KEVIN.MURPHY
          </div>
          <div
            className={brand == "other" ? "active" : ""}
            onClick={() => {
              setBrand("other")
            }}
          >
            COLOR.ME / GLOSS
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filter
