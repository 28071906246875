import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { useGetNotificationTypesQuery } from "../../../../../../../graphql"
import { truncate } from "../../../../../../../utils"
import "./index.less"

const Filter: FC<NotificationFilterInterface> = ({ filters, setFilters, specificFilters }) => {
  const { data: typesData, loading } = useGetNotificationTypesQuery()

  const getTypeOptions = () => {
    const typeOptions = Array.from({ length: typesData?.appNotificationTypes?.data.length || 0 }, (_, i) => ({
      label: typesData?.appNotificationTypes?.data[i]?.attributes?.name,
      key: typesData?.appNotificationTypes?.data[i]?.id,
    }))
    typeOptions.unshift({
      label: "All",
      key: "0",
    })
    return typeOptions || []
  }

  const typeOptions = getTypeOptions()

  const resetFilter = (type: string) => {
    switch (type) {
      case "title":
        setFilters({ ...filters, selectedTitle: "All" })
        break
      case "description":
        setFilters({ ...filters, selectedDescription: "All" })
        break
    }
  }

  const activeSpecificFiltersAmount = specificFilters.length

  return (
    <div className={"filters-wrapper notifications-filter"}>
      <div className={"filters"}>
        <Dropdown
          className={`type-filter ${filters.selectedType != "0" && "active"}`}
          trigger={["click"]}
          disabled={loading}
          overlay={
            <Menu
              onClick={e =>
                setFilters({
                  ...filters,
                  selectedType: typeOptions.find(item => item.key == e.key)!.key! || "All"
                })
              }
              selectedKeys={[`${typeOptions.find(item => item.key == filters.selectedType)!.key}`]}
            >
              {typeOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {typeOptions.find(item => item.key == filters.selectedType)!.label}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>

      <div className={"specific-filters-wrapper"}>
        Filters ({activeSpecificFiltersAmount})
        <div className={"specific-filters-list"}>
          {specificFilters &&
            specificFilters.map((filter: any, i: number) => (
              <div className={"block"} key={`filter-${i}`} style={{backgroundColor: filter.backgroundColor, color: filter.color}}>
                {truncate(filter.option, 32)}
                <div className={"remove-filter"} onClick={() => resetFilter(filter.type)}>
                  <div className={"l-line"} />
                  <div className={"r-line"} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
