import React, { FC } from "react"
import { useLocation } from "react-router"
import ListBlock from "./blocks/ListBlock"
import ManageBlock from "./blocks/ManageBlock"
import "./index.less"

const Assets: FC = () => {
  const { pathname } = useLocation()
  const pathIdentifier = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createNewMode = pathIdentifier == "new-asset"
  const selectedLinkId = Number(pathIdentifier)

  return (
    <section className={"links"}>
      {!createNewMode && !selectedLinkId && <ListBlock />}
      {(selectedLinkId > 0 || createNewMode) && <ManageBlock selectedLinkId={selectedLinkId} />}
    </section>
  )
}

export default Assets
