import { ReactComponent as CircleCalendar } from "../../../../../icons/circle-calendar.svg"
import { ReactComponent as CircleDocument } from "../../../../../icons/circle-document.svg"
import { ReactComponent as CircleMessage } from "../../../../../icons/circle-message.svg"
import { ReactComponent as CirclePerson } from "../../../../../icons/circle-person.svg"
import { ReactComponent as CircleSales } from "../../../../../icons/circle-sales.svg"
import { ReactComponent as CircleHart } from "../../../../../icons/circle-hart.svg"
import { ReactComponent as CircleComp } from "../../../../../icons/circle-comp.svg"
import { ReactComponent as CircleChat } from "../../../../../icons/circle-chat.svg"
import { ReactComponent as ArrowDown } from "../../../../../icons/arrow_down.svg"
import { ReactComponent as ArrowUp } from "../../../../../icons/arrow_up.svg"
import { ReactComponent as Info } from "../../../../../icons/info.svg"
import ToolTip from "../../../components/ToolTip"
import { useGetDashboardQuery } from "../../../../../graphql"
import { Dropdown, Menu, Space, Typography } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { truncate } from "../../../../../utils"
import React, { FC, ReactNode, useEffect, useState } from "react"
import "./index.less"
import { useAuth } from "../../../../../components/auth"

const FirstBlock: FC<AnalyticsBlockProps> = ({
  currentYear,
  yearOptions,
  currentMonth,
  monthOptions,
  blockLoading,
  regionOptions,
  setBlockLoading,
  currentMonthName,
}) => {
  const {region} = useAuth()

  const getMonthNumber = (month: string) => {
    return availableMonthOptions?.find(option => option.label == month)!.key
  }

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedMonth: currentMonthName!,
    selectedYear: String(currentYear),
    selectedRegion: region?.name || "All",
  })

  const availableYearOptions = yearOptions.filter(year => +year.label >= 2024)

  let availableMonthOptions = monthOptions?.filter(
    (month, i) => +filters.selectedYear !== currentYear || i < currentMonth
  )
  if (filters.selectedYear == "2024") availableMonthOptions = availableMonthOptions.slice(4);

  const isCurrentMonthInFilter =
    filters.selectedMonth != currentMonthName || filters.selectedYear != String(currentYear)

  const { data, loading } = useGetDashboardQuery({
    fetchPolicy: "network-only",
    variables: {
      input: {
      ...(filters?.selectedRegion !== "All" || region?.id && regionOptions ?
          {region: Number(region?.id) || +regionOptions!.find(option => option.label == filters.selectedRegion)!.key} :
          {}),
        dateRange: {
          fromDate: `${filters.selectedYear}-${getMonthNumber(filters.selectedMonth)
            .toString()
            .padStart(2, "0")}-01T00:00:00`,
          toDate: `${filters.selectedMonth !== "December" ? filters.selectedYear : +filters.selectedYear + 1}-${
            filters.selectedMonth !== "December"
              ? (+getMonthNumber(filters.selectedMonth) + 1).toString().padStart(2, "0")
              : "01"
          }-01T00:00:00`,
        },
      },
    },
  })

  const createFilter = (options: SelectItems | undefined, filterName: string) => {
    let isActive

    if (filterName == "selectedMonth") {
      isActive = isCurrentMonthInFilter
    }

    if (filterName == "selectedYear") {
      isActive =  filters.selectedYear != String(currentYear)
    }

    if (filterName == "selectedRegion") {
      isActive =  filters.selectedRegion != "All" && !region?.id
    }

    const filterType = filterName == "selectedMonth" ? "month-filter" : filterName == "selectedYear" ? "year-filter" : "region-filter"

    return (
      <Dropdown
        disabled={!options || (filterName == "selectedRegion" && !!region?.id)}
        className={`${filterType} ${isActive && "active"}`}
        trigger={["click"]}
        overlay={
          <Menu
            onClick={e =>
              setFilters({
                ...filters,
                ...(filterName == "selectedYear" ? { selectedMonth: "January" } : {}),
                [filterName]: options?.find(item => +item.key == +e.key)?.label,
              })
            }
            selectedKeys={[`${options?.find(item => item.label == filters[filterName])?.key}`]}
          >
            {options?.map(item => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </Menu>
        }
      >
        <Typography.Link>
          <Space className={"dropdown"}>
            {options?.find(item => item.label == filters[filterName])?.label || ""}
            <DownOutlined style={{ color: "black" }} />
          </Space>
        </Typography.Link>
      </Dropdown>
    )
  }

  const createOverviewBlock = (title: string, type: keyof DashboardOverviewInterface) => {
    const item = data?.getDashboard?.overview?.[type] as any
    return (
      <div className={`block ${loading && "skeleton"}`}>
        <div className={"title"} children={title} />
        <div className={"amount"}>
          {item?.count}
          {isCurrentMonthInFilter && (
            <span className={`${item?.isPositiveDifference ? "up" : "down"}`}>
              {item?.isPositiveDifference || item?.difference == 0 ? "+" : "-"}
              {item?.difference} this month
            </span>
          )}
        </div>
      </div>
    )
  }

  const createActivityBlock = (
    title: string,
    type: keyof DashboardActivityInterface,
    icon: React.ReactNode,
    toolTipTitle: ReactNode,
  ) => {
    const item = data?.getDashboard?.activity?.[type] as any

    return (
      <div className={`block ${loading && "skeleton"}`}>
        {<div children={icon} />}
        <div className={"title"}>
          {title}
          <ToolTip title={toolTipTitle} disabled={false}>
          <Info className={"info"} />
          </ToolTip>
        </div>
        <div className={"amount"}>{item?.count}</div>
        {isCurrentMonthInFilter && (
          <div className={"difference"}>
            {item?.isPositiveDifference ? <ArrowUp /> : <ArrowDown />}
            <span
              className={`${item?.isPositiveDifference ? "up" : "down"}`}
              children={`${item?.difference % 1 !== 0 ? item?.difference?.toFixed(1) : item?.difference}%`}
            />
            this month
          </div>
        )}
      </div>
    )
  }

  const createTopRatedBlock = (title: string, type: keyof DashboardTopRatedInterface, indicator: string) => {
    const item = data?.getDashboard?.topRated?.[type] as any

    return (
      <div className={`block ${loading && "skeleton"}`}>
        <div className={"title"} children={title} />
        <div className={"list"}>
          {item?.map(
            (item: any, i: number) =>
              i < 5 && (
                <div className={`item ${i !== item?.length && "divider"}`} key={`${item?.name}-${i}`}>
                  {truncate(item?.name || "-", 33)}
                  <span>
                    {item?.count} {indicator}
                  </span>
                </div>
              )
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {setBlockLoading({...blockLoading, firstBlock: !loading})}, [loading])

  return (
    <div className={"blocks-wrapper overview"}>
      <div>
        <div className={"title"}>OVERVIEW</div>
        <div className={"filters"}>
          {createFilter(regionOptions, "selectedRegion")}
          {createFilter(availableMonthOptions, "selectedMonth")}
          {createFilter(availableYearOptions, "selectedYear")}
        </div>
        <div className={"overview-blocks"}>
          {createOverviewBlock("POSTS", "overviewPosts" as keyof DashboardOverviewInterface)}
          {createOverviewBlock("EVENTS", "overviewEvents" as keyof DashboardOverviewInterface)}
          {createOverviewBlock("PRODUCTS", "overviewProducts" as keyof DashboardOverviewInterface)}
        </div>
        <div className={"title active-users"}>ACTIVE USERS</div>
        <div className={"overview-blocks"}>
          {createOverviewBlock("DSC", "overviewDscs" as keyof DashboardOverviewInterface)}
          {createOverviewBlock("SALES MANAGERS", "overviewSalesManagers" as keyof DashboardOverviewInterface)}
          {createOverviewBlock("DISTRIBUTORS", "overviewDistributors" as keyof DashboardOverviewInterface)}
        </div>
      </div>

      <div>
        <div className={"title"}>ACTIVITY</div>
        <div className={"activity-blocks"}>
          {createActivityBlock(
            "Likes",
            "totalLikes" as keyof DashboardActivityInterface,
            <CircleHart />,
            (<div>Represents the number of <span style={{color: "#8EE5DF"}}>likes</span> received under posts.</div>)
          )}
          {createActivityBlock(
            "Comments",
            "totalComments" as keyof DashboardActivityInterface,
            <CircleMessage />,
            (<div>Indicates the number of <span style={{color: "#8EE5DF"}}>comments</span> posted under each post.</div>)
          )}
          {createActivityBlock(
            "Posts views",
            "totalNewsViews" as keyof DashboardActivityInterface,
            <CircleComp />,
            (<div>Tracks the initial view of a <span style={{color: "#8EE5DF"}}>post</span> by a user.</div>)
          )}
          {createActivityBlock(
            "Events view",
            "totalEventsViews" as keyof DashboardActivityInterface,
            <CircleCalendar />,
            (<div>Counts the first instance of an <span style={{color: "#8EE5DF"}}>event</span> being viewed by a user.</div>)
          )}
          {createActivityBlock(
            "Chats created",
            "totalChats" as keyof DashboardActivityInterface,
            <CircleChat />,
            (<div>Counts the total number of <span style={{color: "#8EE5DF"}}>chats</span> initiated in Sendbird.</div>)
          )}
          {createActivityBlock(
            "PDP views",
            "totalPDPViews" as keyof DashboardActivityInterface,
            <CircleDocument />,
            (<div>Records the first time a <span style={{color: "#8EE5DF"}}>product</span> is viewedby a user.</div>)
          )}
          {createActivityBlock(
            "Product catalog views",
            "totalProductsCatalogViews" as keyof DashboardActivityInterface,
            <CircleSales />,
            (<div>Captures every time a user interacts with the <span style={{color: "#8EE5DF"}}>product catalog</span>.</div>)
          )}
          {createActivityBlock(
            "New users",
            "newUsers" as keyof DashboardActivityInterface,
            <CirclePerson />,
            (<div>Tracks  <span style={{color: "#8EE5DF"}}>new active users</span> in the INSIDER app.</div>)
          )}
        </div>
      </div>

      <div>
        <div className={"title"}>TOP RATED</div>
        <div className={"top-rated-blocks"}>
          {createTopRatedBlock("MOST POPULAR POSTS", "mostPopularPosts" as keyof DashboardTopRatedInterface, "Views")}
          {createTopRatedBlock("MOST LIKED POSTS", "mostLikedPosts" as keyof DashboardTopRatedInterface, "Likes")}
          {createTopRatedBlock("MOST POPULAR LINKS", "mostPopularLinks" as keyof DashboardTopRatedInterface, "Clicks")}
        </div>
      </div>
    </div>
  )
}

export default FirstBlock
