import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { ConfigProvider } from "antd"
import React, { memo, type FC } from "react"
import { createBrowserRouter, RouteObject, RouterProvider } from "react-router-dom"
import introspection from "../../graphql"
import { guestRoutes, initializationRoutes, userRoutes } from "../../pages"
import theme from "../../themes"
import { AuthProvider, useAuth } from "../auth"
import "./index.less"

const url = import.meta.env.WEBSITE_API_URL

const getNewToken = async () => {
  return await fetch((url?.replace("/graphql", "") || "") + "/api/token/refresh", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refreshToken: localStorage.getItem("refreshToken"),
    }),
  })
    .then(response => response.json())
    .then(response => {
      if (response?.accessToken && response?.refreshToken) {
        localStorage.setItem("accessToken", response.accessToken as string)
        return response.accessToken
      } else {
        localStorage.clear()
      }
    })
}

const httpLink = createHttpLink({
  uri: `${url ?? "/graphql"}`,
  credentials: "same-origin",
})

const authLink = setContext(async (_, { headers }) => {
  let accessToken = localStorage.getItem("accessToken")
  if (!accessToken) {
    accessToken = await getNewToken()
  }
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (
    graphQLErrors?.find(({ extensions }) => extensions?.code == "FORBIDDEN") ||
    networkError?.message?.includes("401")
  ) {
    localStorage.removeItem("accessToken")
  }
  return forward(operation)
})

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const Router = () => {
  let routes
  const { role, permissions } = useAuth()
  const refreshToken = localStorage.getItem("refreshToken")
  const motherRoutes = [
    ...(permissions?.firstMenu?.map(item => item.name.toLowerCase()) || []),
    ...(permissions?.secondMenu?.map(item => item.name.toLowerCase()) || []),
  ]
  const childrenRoutes = permissions?.firstMenu?.flatMap(item =>
    item.blocks?.map((blocks: any) => blocks.name?.toLowerCase())
  )

  const generateRoleRoutes = () => {
    return [
      {
        ...userRoutes[0],
        children: userRoutes[0].children?.map(route =>
          route.path == "/account"
            ? {
                ...route,
                ...(route?.children && motherRoutes && childrenRoutes
                  ? {
                      children: route.children
                        .filter(route =>
                          motherRoutes.find(motherRoute => route?.path?.replace("-", " ").includes(motherRoute))
                        )
                        .filter(
                          route =>
                            !permissions?.firstMenu?.find(
                              item => item.name.toLowerCase() == route.path?.split("/")[2]?.replace("-", " ")
                            )?.blocks ||
                            childrenRoutes.find(childrenRoute => route?.path?.replace("-", " ").includes(childrenRoute))
                        ),
                    }
                  : {}),
              }
            : route
        ),
      },
    ]
  }

  switch (role?.toLowerCase()?.replace("adminportal ", "")) {
    case "super admin":
    case "editor":
    case "admin":
    case "viewer":
      routes = generateRoleRoutes()
      break

    default:
      routes = refreshToken && !role ? initializationRoutes : guestRoutes
  }

  const router = createBrowserRouter(routes as RouteObject[])
  return <RouterProvider router={router} />
}

const App: FC = memo(() => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ConfigProvider theme={theme}>
          <Router />
        </ConfigProvider>
      </AuthProvider>
    </ApolloProvider>
  )
})

export default App
