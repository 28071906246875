import { Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useGetResourcesQuery } from "../../../../../graphql"
import LeftSide from "./components/LeftSide"
import RightSide from "./components/RightSide"
import "./index.less"

const ManageBlock: FC<ManagePdfBlockInterface> = ({ selectedPdfId }) => {
  const { data: linkData, loading } = useGetResourcesQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        id: {
          eq: String(selectedPdfId),
        },
      },
    },
  })
  const pdf = linkData?.appResources?.data?.[0]
  const pdfLink = selectedPdfId ? `${window.location.origin}/resources/${selectedPdfId}` : null

  const [initialFilters, setInitialFilters] = useState<{ [key: string]: any }>()
  const [filters, setFilters] = useState<{ [key: string]: any }>()

  useEffect(() => {
    if ((selectedPdfId && pdf) || !selectedPdfId) {
      const filters = {
        link: pdfLink,
        pages: pdf?.attributes?.pages || null,
        category: pdf?.attributes?.category || "",
        file: pdf?.attributes?.file?.data?.id || null,
        description: pdf?.attributes?.description || "",
        size: pdf?.attributes?.file?.data?.attributes?.size || null,
      }

      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [pdf])

  const isChangesDisabled = !!(initialFilters?.title && !selectedPdfId)

  return (
    <div className={`block-manage-wrapper ${loading && "center"}`}>
      {!loading && filters && initialFilters ? (
        <>
          <LeftSide
            {...{
              pdf,
              filters,
              setFilters,
              selectedPdfId,
              initialFilters,
              isChangesDisabled,
              setInitialFilters,
            }}
          />
          <RightSide
            {...{
              pdf,
              filters,
              setFilters,
              isChangesDisabled,
            }}
          />
        </>
      ) : (
        <Spin className={"pdfs-manage-block-spin"} />
      )}
    </div>
  )
}

export default ManageBlock
