import React, { FC } from "react"
import caseImage from "../../../../../../../images/case-placeholder.png"
import defaultPlaceholder from "../../../../../../../images/distributor-placeholder.png"
import humanImage from "../../../../../../../images/human-placeholder.png"
import scissorsImage from "../../../../../../../images/scissors-placeholder.png"
import shopPlaceholder from "../../../../../../../images/shop-placeholder.png"
import { capitalizeEveryWord, formatDate } from "../../../../../../../utils"
import ImageLoader from "../../../../../components/ImageLoader"
import "./index.less"

const RightSide: FC<RoleManageBlockRightSideInterface> = ({ user, filters }) => {
  const avatar = user?.attributes?.avatar?.data?.attributes?.url
  let placeholder
  switch (filters?.type) {
    case "internal_employee":
      placeholder = caseImage
      break
    case "stylist":
      placeholder = scissorsImage
      break
    case "store":
      placeholder = shopPlaceholder
      break
    case "other":
      placeholder = humanImage
      break
    default:
      placeholder = defaultPlaceholder
  }

  return (
    <div className={"general-info-wrapper"}>
      <div className={"title"}>INFORMATION</div>

      {avatar ? (
        <ImageLoader filters={{}} setFilters={() => {}} fetchedImageURL={avatar} type={""} isChangesDisabled={true} />
      ) : (
        <img src={placeholder} alt={"media"} />
      )}

      <div className={"data"}>
        <div>
          Name<span>{filters?.name || "-"}</span>
        </div>

        <div>
          Email<span>{filters?.email || "-"}</span>
        </div>

        <div>
          Role<span>Salon</span>
        </div>

        <div>
          Region<span>{filters?.region || "-"}</span>
        </div>

        <div>
          Status
          <span>
            {user?.attributes?.owner?.data?.attributes?.blocked ? "Blocked" : user?.attributes?.owner?.data?.attributes?.isAppActivated ? "Active" : "Inactive"}
          </span>
        </div>

        <div>
          Date of creation<span>{formatDate(user?.attributes?.createdAt)}</span>
        </div>

        <div>
          Last update<span>{formatDate(user?.attributes?.updatedAt)}</span>
        </div>
      </div>
    </div>
  )
}

export default RightSide
