import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { useAuth } from "../../../../../components/auth"
import { truncate } from "../../../../../utils"
import "./index.less"

const Filter: FC<LeaderboardFilterInterface> = ({
  filters,
  totalItems,
  setFilters,
  currentYear,
  yearOptions,
  sortOptions,
  regionOptions,
  leaderboardType,
  currentMonthName,
  availableMonthOptions,
  competitionTypeOptions,
}) => {
  const { region } = useAuth()
  const isCurrentMonthInFilter =
    filters.selectedMonth != currentMonthName || filters.selectedYear != String(currentYear)

  const createFilter = (options: SelectItems | undefined, filterName: string) => {
    let isActive

    switch (filterName) {
      case "selectedRegion":
        isActive = filters.selectedRegion !== "North America" && !region?.id
        break
      case "selectedMonth":
        isActive = isCurrentMonthInFilter
        break
      case "selectedYear":
        isActive = filters.selectedYear !== String(currentYear)
        break
      case "selectedCompetition":
        isActive = filters.selectedCompetition !== competitionTypeOptions?.[0]?.label
        break
      case "sortBy":
        isActive = filters.sortBy !== "Most Units Sold"
        break
      default:
        isActive = false
    }

    return (
      <Dropdown
        className={`${filterName} ${isActive && "active"}`}
        disabled={!options?.length || !!(filterName == "selectedRegion" && region?.id)}
        trigger={["click"]}
        overlay={
          <Menu
            onClick={e =>
              setFilters({
                ...filters,
                ...(filterName == "selectedYear" ? { selectedMonth: "January" } : {}),
                [filterName]: options?.find(item => +item.key == +e.key)?.label,
              })
            }
            selectedKeys={[`${options?.find(item => item.label == filters[filterName])?.key}`]}
          >
            {options?.map(item => <Menu.Item key={item.key}>{item.label}</Menu.Item>)}
          </Menu>
        }
      >
        <Typography.Link>
          <Space className={"dropdown"}>
            {filters?.[filterName] ? truncate(filters?.[filterName], 14) : ""}
            <DownOutlined style={{ color: "black" }} />
          </Space>
        </Typography.Link>
      </Dropdown>
    )
  }

  const resetFilter = () => {
    setFilters({ ...filters, selectedDSC: "All" })
  }

  return (
    <div className={`filters-wrapper leaderboard-filter ${leaderboardType} ${competitionTypeOptions?.length ? "visible" : ""}`}>
      <div className={"filters"}>
        {leaderboardType == "competition" && createFilter(competitionTypeOptions, "selectedCompetition")}
        {leaderboardType == "competition" && createFilter(sortOptions, "sortBy")}
        {createFilter(availableMonthOptions, "selectedMonth")}
        {createFilter(yearOptions, "selectedYear")}
        {createFilter(regionOptions, "selectedRegion")}
      </div>

      <div className={`dsc-filters-wrapper ${leaderboardType}`}>
        Selected DSC:
        <div className={"dsc-filters-list"}>
          {filters.selectedDSC !== "All" && (
            <div className={"block"}>
              {truncate(filters.selectedDSC, 25)}
              <div className={"remove-filter"} onClick={resetFilter}>
                <div className={"l-line"} />
                <div className={"r-line"} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`total-participants`}>
        <span className={`${isNaN(totalItems) && "skeleton"}`}>Total of {totalItems} DSC’s</span>
      </div>
    </div>
  )
}

export default Filter
