import React, { FC, useEffect, useRef, useState } from "react"
import { useGetActivityLogLazyQuery } from "../../../graphql"
import { formatDate } from "../../../utils"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"
import { useAuth } from "../../../components/auth"

const ActivityLog: FC = () => {
  let {role, userId} = useAuth()
  role = role?.toLowerCase()?.replace("adminportal ", "")

  const [getActivityLog] = useGetActivityLogLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedType: "All",
    selectedName: "All",
    selectedAction: "All",
    selectedActionFor: "All",
  })

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const createQueryFilters = () => ({
    ...(role !== "super admin" ? {userId: Number(userId)} : {}),
    ...(filters.selectedName !== "All" ? { userName: filters.selectedName } : {}),
    ...(filters.selectedType !== "All" ? { type: filters.selectedType.toLowerCase() } : {}),
    ...(filters.selectedActionFor !== "All" ? { actionFor: filters.selectedActionFor } : {}),
    ...(filters.selectedAction !== "All" ? { action: filters.selectedAction.toLowerCase() } : {}),
  })

  const createList = async (isForExport = false) => {
    setLoading(true)
    if (list && !isForExport) {
      setList(undefined)
    }

    let newList

    newList = await getActivityLog({
      fetchPolicy: "network-only",
      variables: {
        input: {
          ...createQueryFilters(),
          page: isForExport ? 1 : page,
          pageSize: isForExport ? 1000 : pageSize,
        },
      },
    })

    let totalItems
    let finalData

    totalItems = newList?.data?.getAdminActivityLog?.total || 0
    finalData = newList?.data?.getAdminActivityLog?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        formatDate(item?.createdAt, true),
        item?.user?.name || "Unknown",
        item?.action || "-",
        item?.type || "-",
        item?.action_for || "-",
      ]) || []
    listForExport = listForExport?.map((item: any) => item.filter((entity: any) => entity !== null))
    listForExport.unshift(["DATE / TIME", "NAME", "ACTION", "TYPE", "ACTION FOR"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  const createSpecificFiltersList = () => {
    const list = []
    if (filters.selectedName !== "All") list.push({ option: filters.selectedName, type: "name", backgroundColor: "rgba(215, 163, 171, 0.15)" , color: "#D7A3AB" })
    if (filters.selectedActionFor !== "All") list.push({ option: filters.selectedActionFor, type: "action-for", backgroundColor: "rgba(68, 195, 187, 0.15)", color: "#44C3BB" })
    return list
  }

  const specificFilters = createSpecificFiltersList()

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <section className={"activity-log"}>
      <div className={"block-wrapper"}>
        <Filter
          {...{
            filters,
            setFilters,
            specificFilters,
          }}
        />
        <List
          {...{
            list,
            page,
            loading,
            setPage,
            filters,
            pageSize,
            setFilters,
            totalItems,
            setLoading,
            setPageSize,
            exportLoading,
            listForExport,
            downloadBtnRef,
            createExportList,
            createQueryFilters,
          }}
        />
      </div>
    </section>
  )
}

export default ActivityLog
