import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import React, { FC, useEffect, useState } from "react"
import { Line, Pie } from "react-chartjs-2"
import { useGetSalesOutQuery } from "../../../../../graphql"
import "./index.less"
import { useAuth } from "../../../../../components/auth"
import { truncate } from "../../../../../utils"
ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, PointElement, LineElement, RadialLinearScale, Filler)

const SecondBlock: FC<AnalyticsBlockProps> = ({
  currentYear,
  yearOptions,
  blockLoading,
  currentMonth,
  monthOptions,
  regionOptions,
  setBlockLoading
}) => {
  const {region} = useAuth()

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedYear: String(currentYear),
    selectedRegion: region?.name || "North America"
  })

  let { data, loading } = useGetSalesOutQuery({fetchPolicy: "network-only", variables: { input: { year: +filters.selectedYear } } })

  if (filters?.selectedRegion !== "North America") {
    data = undefined
  }

  const lineHaircareFetchedData = Array.from(
    { length: currentYear == +filters.selectedYear ? currentMonth : 12 },
    (_, i) => (data?.getSalesOut?.chart as any)?.[monthOptions[i].label.toLowerCase()]?.haircare ?? 0
  )
  const totalHaircare = lineHaircareFetchedData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const lineColorFetchedData = Array.from(
    { length: currentYear == +filters.selectedYear ? currentMonth : 12 },
    (_, i) => (data?.getSalesOut?.chart as any)?.[monthOptions[i].label.toLowerCase()]?.color_me ?? 0
  )
  const totalColor = lineColorFetchedData.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const createLineData = (color: string, skirtColor: string, data: any) => [
    {
      lineTension: 0.25,
      backgroundColor: color,
      borderColor: color,
      borderWidth: 4,
      pointBackgroundColor: "transparent",
      pointBorderColor: "#000",
      pointBorderWidth: 2,
      pointRadius: 4,
      pointHoverRadius: 6,
      pointHoverBorderColor: "#000",
      pointHoverBorderWidth: 2,
      fill: {
        target: "origin",
        above: skirtColor,
      },
      data: data,
    },
  ]

  const formatAmount = (amount: any | null | undefined) =>
    amount?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    }) || 0

  const pieData = {
    labels: ["COLOR.ME & GLOSS", "HAIRCARE"],
    datasets: [
      {
        data: [totalColor, totalHaircare],
        backgroundColor: ["#8EE5DF", "#C8E6F7"],
        borderColor: ["#8EE5DF", "#C8E6F7"],
        borderWidth: 1,
      },
    ],
  }

  const pieOptions = {
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context: any) => formatAmount(context.parsed),
        },
      },
      datalabels: {
        formatter: (value: number, ctx: any) => {
          let sum = 0
          let dataArr = ctx.chart.data.datasets[0].data
          dataArr.map((data: any) => {
            sum += data
          })
          let percentage = ((value * 100) / sum).toFixed(0) + "%"
          return percentage
        },
        color: "#FFF",
        font: {
          size: 15,
          weight: "bold",
        },
      },
    },
  }

  const lineData = {
    labels: [...monthOptions.map(item => item.label.substring(0, 3))],
    datasets: [
      ...createLineData("#C8E6F7", "rgba(200, 230, 247, 0.3)", lineHaircareFetchedData),
      ...createLineData("#8EE5DF", "rgba(142, 229, 223, 0.3)", lineColorFetchedData),
    ],
  }

  const lineOptions = {
    responsive: true,
    layout: {
      padding: 45,
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 12,
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 10,
        },
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context: any) => formatAmount(context.parsed.y),
        },
      },
    },
  }

  const createFilter = (options: SelectItems | undefined, filterName: string) => {
    let isActive;

    if (filterName == "selectedYear") {
      isActive =  filters.selectedYear != String(currentYear)
    }

    if (filterName == "selectedRegion") {
      isActive =  filters.selectedRegion != "North America" && !region?.id
    }

    return (
      <Dropdown
        className={`${filterName} ${isActive && "active"}`}
        trigger={["click"]}
        disabled={!options || (filterName == "selectedRegion" && !!region?.id)}
        overlay={
          <Menu
            onClick={e => setFilters({ ...filters, [filterName]: options?.find(item => +item.key == +e.key)?.label! })}
            selectedKeys={[
              `${filterName !== "region" && options?.find(item => item.label == filters[filterName])?.key}`,
            ]}
          >
            {options?.map(item => <Menu.Item key={item.key}>{item.label}</Menu.Item>)}
          </Menu>
        }
      >
        <Typography.Link>
          <Space className={"dropdown"}>
            {filterName == "region" ? "North America" : truncate(options?.find(item => item.label == filters[filterName])?.label!, 20)}
            <DownOutlined style={{ color: "black" }} />
          </Space>
        </Typography.Link>
      </Dropdown>
    )
  }

  const createFooter = () => (
    <div className={"labels-wrapper"}>
      <div>
        <div className={"color haircare"} />
        HAIRCARE
      </div>
      <div>
        <div className={"color color-me"} />
        COLOR.ME
      </div>
    </div>
  )

  useEffect(() => {setBlockLoading({...blockLoading, secondBlock: !loading})}, [loading])

  return (
    <div className={"blocks-wrapper sales-out"}>
      <div>
        <div className={"title"}>SALES OUT</div>
        <div className={"filters"}>
          {createFilter(regionOptions?.filter(option => option.value !== "All"), "selectedRegion")}
          {createFilter(yearOptions, "selectedYear")}
        </div>

        <div className={"charts"}>
          <div className={`pie-chart ${loading && "skeleton"}`}>
            <div className={"title"}>
              <div children={"COMBINED SALES"} />
            </div>
            <div className={"count"}>
              <div children={formatAmount(data?.getSalesOut?.total)} />
            </div>
            <div className={"chart-holder"}>
              <Pie data={pieData} plugins={[ChartDataLabels as any]} options={pieOptions as any} />
            </div>
            {createFooter()}
          </div>

          <div className={`line-chart ${loading && "skeleton"}`}>
            <div className={"title"}>
              <div children={"HAIRCARE"} />
              <div children={"COLOR.ME & GLOSS"} />
            </div>
            <div className={"count"}>
              <div children={formatAmount(totalHaircare)} />
              <div children={formatAmount(totalColor)} />
            </div>
            <div className={"chart-holder"}>
              <Line data={lineData} options={lineOptions as any} />
            </div>
            {createFooter()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondBlock
