import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { useGetRegionsQuery } from "../../../../../../../graphql"
import { truncate } from "../../../../../../../utils"
import "./index.less"

const Filter: FC<RoleFilterInterface> = ({ filters, setFilters, specificFilters }) => {
  const { data: regionsData, loading } = useGetRegionsQuery()

  const getRegionOptions = () => {
    const regionOptions = Array.from({ length: regionsData?.regions?.data.length || 0 }, (_, i) => ({
      label: regionsData?.regions?.data[i].attributes?.name,
      key: i + 1,
    }))
    regionOptions.unshift({
      label: "All",
      key: 0,
    })
    return regionOptions || []
  }

  const regionOptions = getRegionOptions()

  const roleVariants = ["Super Admin", "Editor", "Admin", "Viewer"]
  const roleOptions = roleVariants.map((item, i) => ({
    value: item,
    label: item,
    key: i + 1,
  }))
  roleOptions.unshift({
    value: "All",
    label: "All",
    key: 0,
  })

  const resetFilter = (type: string) => {
    switch (type) {
      case "name":
        setFilters({ ...filters, selectedName: "All" })
        break
      case "email":
        setFilters({ ...filters, selectedEmail: "All" })
        break
    }
  }

  const activeSpecificFiltersAmount = specificFilters.length

  return (
    <div className={"filters-wrapper roles-filter"}>
      <div className={"filters"}>
        <Dropdown
          className={`region-filter ${filters.selectedRegion != "All" && "active"}`}
          trigger={["click"]}
          disabled={loading}
          overlay={
            <Menu
              onClick={e =>
                setFilters({ ...filters, selectedRegion: regionOptions.find(item => item.key == +e.key)!.label! })
              }
              selectedKeys={[`${regionOptions.find(item => item.label == filters.selectedRegion)!.key}`]}
            >
              {regionOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(regionOptions.find(item => item.label == filters.selectedRegion)!.label!, 15)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>

        <Dropdown
          className={`role-filter ${filters.selectedRole != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e =>
                setFilters({
                  ...filters,
                  selectedRole: roleOptions.find(item => item.key == +e.key)!.label! || "All",
                })
              }
              selectedKeys={[`${roleOptions.find(item => item.label == filters.selectedRole)!.key}`]}
            >
              {roleOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(roleOptions.find(item => item.label == filters.selectedRole)!.label, 15)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>

      <div className={"specific-filters-wrapper"}>
        Filters ({activeSpecificFiltersAmount})
        <div className={"specific-filters-list"}>
          {specificFilters &&
            specificFilters.map((filter: any, i: number) => (
              <div className={"block"} key={`filter-${i}`} style={{backgroundColor: filter.backgroundColor, color: filter.color}}>
                {truncate(filter.option, 32)}
                <div className={"remove-filter"} onClick={() => resetFilter(filter.type)}>
                  <div className={"l-line"} />
                  <div className={"r-line"} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
