import { useGetProductListLazyQuery } from "../../../../../graphql"
import React, { FC, useEffect, useRef, useState } from "react"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"

const ListBlock: FC = () => {
  const [getProductList] = useGetProductListLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [brand, setBrand] = useState<"KM" | "other">("KM")
  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedName: "All",
    selectedType: "All",
    selectedBenefit: "All",
  })

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const createQueryFilters = (isPaginationDisabled = false) => {
    return {
      variables: {
        input: {
          page: isPaginationDisabled ? 1 : page,
          pageSize: isPaginationDisabled ? 1000 : pageSize,
          filter: {
            brand,
            ...(filters.selectedName !== "All" ? { search: filters.selectedName } : {}),
            ...(filters?.selectedBenefit !== "All" ? { benefitId: +filters.selectedBenefit } : {}),
            ...(filters?.selectedType !== "All" ? { typeId: +filters.selectedType } : {}),
            orderBy: {
              isReversed: false,
            },
          },
        },
      },
    }
  }

  const createList = async (isForExport: boolean = false) => {
    setLoading(true)
    if (list && !isForExport) {
      setList(undefined)
    }

    const newList = await getProductList({
      fetchPolicy: "network-only",
      ...createQueryFilters(isForExport),
    })

    const totalItems = newList?.data?.products?.total || 0
    const finalData = newList?.data?.products?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        item?.title || "-",
        item?.descriptionTitle || "-",
        item?.type?.name || "-",
        ...(brand === "KM" ? [item?.benefit?.name || "-"] : []),
      ]) || []
    listForExport = listForExport?.map(item => item.filter(entity => entity !== null))
    listForExport.unshift(["NAME", "TITLE", "TYPE", ...(brand === "KM" ? ["REGIMENTS"] : [])])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  const createSpecificFiltersList = () => (filters.selectedName !== "All" ? [{ option: filters.selectedName, backgroundColor: "rgba(215, 163, 171, 0.15)" , color: "#D7A3AB" }] : [])

  const specificFilters = createSpecificFiltersList()

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters, brand])

  useEffect(() => {
    setFilters({
      selectedName: "All",
      selectedType: "All",
      selectedBenefit: "All",
    })
  }, [brand])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          brand,
          setBrand,
          filters,
          setFilters,
          specificFilters,
        }}
      />
      <List
        {...{
          list,
          page,
          brand,
          loading,
          setPage,
          filters,
          pageSize,
          setFilters,
          totalItems,
          setLoading,
          setPageSize,
          exportLoading,
          listForExport,
          downloadBtnRef,
          createExportList,
          createQueryFilters,
        }}
      />
    </div>
  )
}

export default ListBlock
