import { Spin, SpinProps } from "antd"
import { FC } from "react"
import "./index.less"

const Loader: FC<SpinProps> = props => (
  <div className={"initial-spin"}>
    <Spin size={"large"} />
  </div>
)

export { Loader }
