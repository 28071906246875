import "./index.less"
import React, { FC, useEffect, useState } from "react"
import ImageLoader from "../../../../../components/ImageLoader"
import { formatDate, truncate } from "../../../../../../../utils"

const RightSide: FC<EventManageBlockRightSideInterface> = ({ event, filters, setFilters, isChangesDisabled }) => {
  const [fetchedImageURL, setFetchedImageURL] = useState<string>()
  const isVideo = fetchedImageURL?.includes(".mov") || fetchedImageURL?.includes(".mp4")

  useEffect(() => {
    if (filters!.currentImage && !filters!.youtubeLink) {
      setFetchedImageURL(filters!.currentImage as string)
    }
    if (filters!.youtubeLink) {
      setFetchedImageURL(undefined)
    }
  }, [filters])

  return (
    <div className={"general-info-wrapper"}>
      <div className={"title"}>INFORMATION</div>

      <ImageLoader
        type={"image"}
        filters={filters}
        acceptVideo={true}
        setFilters={setFilters}
        isChangesDisabled={isChangesDisabled || !!filters?.youtubeLink}
        {...(isVideo ? {fetchedVideoURL: fetchedImageURL} : {fetchedImageURL: fetchedImageURL})}
      />

      <div className={"data"}>
        <div>
          Title<span>{truncate((filters?.title as string) || "-", 33)}</span>
        </div>

        <div>
          Start date<span>{filters?.startAt?.date || "-"}</span>
        </div>

        <div>
          End date<span>{filters?.endAt?.date || "-"}</span>
        </div>

        <div>
          Location<span>{truncate((filters?.address as string) || "-", 33)}</span>
        </div>

        <div>
          Date of creation<span>{formatDate(event?.attributes?.createdAt)}</span>
        </div>

        <div>
          Last update<span>{formatDate(event?.attributes?.updatedAt)}</span>
        </div>

      </div>
    </div>
  )
}

export default RightSide
