import { DownOutlined } from "@ant-design/icons"
import { ConfigProvider, Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { useNavigate } from "react-router"
import { useGetRegionsQuery } from "../../../../../../../graphql"
import { truncate } from "../../../../../../../utils"
import "./index.less"
import { useAuth } from "../../../../../../../components/auth"
import { Checkbox } from 'antd';
import type { CheckboxProps } from 'antd';
import { DangerInfoIcon } from "../../../../../../../icons"
import ToolTip from "../../../../../components/ToolTip"

const Filter: FC<TeamFilterInterface> = ({ filters, setFilters, roleFilters, role }) => {
  const navigate = useNavigate()
  const {region} = useAuth()
  const { data: regionsData } = useGetRegionsQuery()

  const getRegionOptions = () => {
    const regionOptions = Array.from({ length: regionsData?.regions?.data.length || 0 }, (_, i) => ({
      label: regionsData?.regions?.data[i].attributes?.name,
      key: i + 1,
    }))
    if (!region?.id) {
      regionOptions.unshift({
        label: "All",
        key: 0,
      })
    }
    return regionOptions || []
  }

  const regionOptions = getRegionOptions()

  const statusOptions = [
    {
      label: "All",
      key: 1,
    },
    {
      label: "Active",
      key: 2,
    },
    {
      label: "Inactive",
      key: 3,
    },
    {
      label: "Blocked",
      key: 4,
    },
  ]

  const resetFilter = (type: string) => {
    switch (type) {
      case "name":
        setFilters({ ...filters, selectedName: "All" })
        break
      case "email":
        setFilters({ ...filters, selectedEmail: "All" })
        break
      case "Sales Manager":
        setFilters({ ...filters, selectedSM: "All" })
        break
      case "Distributor":
        setFilters({ ...filters, selectedDistributor: "All" })
        break
    }
  }

  const onCheckboxChange: CheckboxProps['onChange'] = (e) => {
    setFilters({
      ...filters,
      isBadgesOnly: e.target.checked
    })
  };

  const roleActiveFiltersAmount = roleFilters.length

  return (
    <div className={`filters-wrapper team-filter ${role?.toLowerCase()?.replace(" ", "-")}`}>
          <div className={`filters ${role == "Distributor" && region?.id && "justify-right"}`}>

        {!region?.id && (
        <Dropdown
          className={`region-filter ${filters.selectedRegion != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e =>
                setFilters({ ...filters, selectedRegion: regionOptions.find(item => item.key == +e.key)!.label! })
              }
              selectedKeys={[`${regionOptions.find(item => item.label == filters.selectedRegion)!.key}`]}
            >
              {regionOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(regionOptions.find(item => item.label == filters.selectedRegion)!.label!, 18)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
        )}


        {role !== "Distributor Entity" && (
          <Dropdown
            className={`status-filter ${filters.selectedStatus != "All" && "active"}`}
            trigger={["click"]}
            overlay={
              <Menu
                onClick={e =>
                  setFilters({ ...filters, selectedStatus: statusOptions.find(item => item.key == +e.key)!.label! })
                }
                selectedKeys={[`${statusOptions.find(item => item.label == filters.selectedStatus)!.key}`]}
              >
                {statusOptions.map(item => (
                  <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <Typography.Link>
              <Space className={"dropdown"}>
                {statusOptions.find(item => item.label == filters.selectedStatus)!.label}
                <DownOutlined style={{ color: "black" }} />
              </Space>
            </Typography.Link>
          </Dropdown>
        )}

            {role == "DSC" &&
              <ConfigProvider
                theme={{
                  components: {
                    Checkbox: {
                      colorBorder: "#000",
                      colorPrimaryHover: "#000",
                      colorPrimary: "#000",
                      borderRadius: 0,
                    },
                  },
                }}
              >
                <div className={"checkbox-wrapper"}>
                  <Checkbox onChange={onCheckboxChange}>Badge only</Checkbox>
                  <ToolTip
                    title={
                    <div className={"checkbox-tool-tip"}>
                      Users with <span children={"Gold"}/>, <span children={"Platinum"}/> or <span children={"Diamond"}/> badges only
                    </div>
                    }
                    disabled={false}
                    size={"fit-content"}>
                  <DangerInfoIcon />
                  </ToolTip>
                </div>
              </ConfigProvider>
            }
      </div>

      <div className={"role-filters-wrapper"}>
        Filters ({roleActiveFiltersAmount})
        <div className={"role-filters-list"}>
          {roleFilters &&
            roleFilters.map((filter, i) => (
              <div className={"block"} key={`filter-${i}`} style={{backgroundColor: filter.backgroundColor, color: filter.color}}>
                {truncate(filter.option, 10)}
                <div className={"remove-filter"} onClick={() => resetFilter(filter.type)}>
                  <div className={"l-line"} />
                  <div className={"r-line"} />
                </div>
              </div>
            ))}
        </div>
      </div>
      {(role == "Distributor" || role == "Distributor Entity") && (
        <div className={"tabs-wrapper"}>
          Tabs:
          <div className={"tabs"}>
            <div
              className={role == "Distributor" ? "active" : ""}
              onClick={() => navigate("/account/team/distributor/users")}
            >
              ASSIGNED USERS
            </div>
            <div
              className={role == "Distributor Entity" ? "active" : ""}
              onClick={() => navigate("/account/team/distributor/entities")}
            >
              DISTRIBUTORS
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Filter
