import { ReactComponent as DownloadIcon } from "../../../../../../../icons/download.svg"
import { ReactComponent as EditDots } from "../../../../../../../icons/edit-dots.svg"
import { ReactComponent as SearchIcon } from "../../../../../../../icons/search.svg"
import { ReactComponent as SaveIcon } from "../../../../../../../icons/add.svg"
import { useGetProductNamesLazyQuery } from "../../../../../../../graphql"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { ConfigProvider, Empty, Pagination, Select, Spin } from "antd"
import { removeSelectDuplicates } from "../../../../../../../utils"
import type { PaginationProps } from "antd"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { CSVLink } from "react-csv"

import "./index.less"
import { useAuth } from "../../../../../../../components/auth"

const List: FC<ProductListInterface> = ({
  list,
  page,
  brand,
  loading,
  setPage,
  filters,
  pageSize,
  setFilters,
  totalItems,
  setLoading,
  setPageSize,
  exportLoading,
  listForExport,
  downloadBtnRef,
  createExportList,
  createQueryFilters,
}) => {
  const {permissions} = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Content")?.blocks?.find((block: any) => block.name == "Products")

  const navigate = useNavigate()
  const [getProductNames] = useGetProductNamesLazyQuery()
  const [names, setNames] = useState<SelectItems>()

  const onChangePage: PaginationProps["onChange"] = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const onChangePageSize: PaginationProps["onShowSizeChange"] = (_, pageSize: number) => {
    setPageSize(pageSize)
  }

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const getItems = async () => {
    setLoading(true)

    const namesData = await getProductNames({
      fetchPolicy: "network-only",
      ...createQueryFilters(true),
    })

    setNames(
      removeSelectDuplicates(
        namesData?.data?.products?.data
          ?.map(item => ({
            value: item?.title!,
            label: item?.title!,
            key: String(item?.id)!,
          }))
          .filter(item => item.value)!
      )
    )

    setLoading(false)
  }

  const handleFilter = (data: string) => {
    setFilters({
      ...filters,
      selectedName: data,
    })
  }

  const handleCreateNewClick = () => {
    navigate("/account/content/products/new-product")
  }

  const createSelectElement = (
    type: string,
    name: string,
    query: () => void,
    loading: boolean,
    options: SelectItems
  ) => (
    <div
      className={
        `${type?.toLowerCase().replace(" ", "-")} ${
          (type == "title" && filters?.selectedName !== "All") && "active"
        }`}
      children={
        <Select
          showSearch
          onDropdownVisibleChange={query}
          value={name}
          optionFilterProp='children'
          onChange={data => handleFilter(data)}
          filterOption={filteredOption as any}
          options={options}
          defaultActiveFirstOption={true}
          notFoundContent={
            loading || !options ? (
              <div className={"no-data"} children={<Spin />} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
            )
          }
          suffixIcon={<SearchIcon />}
        />
      }
    />
  )

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        key={JSON.stringify(filters) + brand + String(page) + String(pageSize)}
        timeout={250}
        classNames={"fade"}
        appear={true}
        unmountOnExit
      >
        <div className={"main-list-wrapper product-list"}>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "black",
                  colorPrimaryBorder: "black",
                  colorPrimaryHover: "black",
                  controlOutline: "#fff",
                },
                Select: {
                  colorText: "#677685",
                  colorPrimaryBorder: "#fff",
                  colorPrimaryHover: "#fff",
                  controlOutline: "#fff",
                  colorBgContainerDisabled: "#fff",
                },
              },
            }}
          >
            <div className={"list-wrapper"}>
              <div className={`items top brand-${brand}`}>
                {createSelectElement("title", "NAME", () => getItems(), loading, names!)}
                <div children={"TITLE"} />
                <div children={"TYPE"} />
                {brand !== "other" && <div children={"REGIMENTS"} />}
                <div className={"centered"} children={"EDIT"} />
              </div>

              {list &&
                list.map((item: any, i: number) => (
                  <div className={`items brand-${brand}`} key={`list-item-${i}`}>
                    <div children={<p>{item?.title || "-"}</p>} />
                    <div children={<p>{item?.descriptionTitle || "-"}</p>} />
                    <div children={<p>{item?.type?.name || "-"}</p>} />
                    {brand !== "other" && <div children={<p>{item?.benefit?.name || "-"}</p>} />}
                    <div
                      className={`centered ${!blockPermissions?.edit && "disabled"}`}
                      children={
                        <Link
                          to={!blockPermissions?.edit ? "#" : String(item.id)}
                          children={<EditDots />}
                        />
                      }
                    />
                  </div>
                ))}

              {!list && (
                <div className={"spinner-wrapper"}>
                  <Spin />
                </div>
              )}

              {list?.length == 0 && (
                <div className={"empty"}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>

            <div
              className={"navigation"}
              children={
                <Pagination
                  showQuickJumper
                  current={page}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                />
              }
            />

            {blockPermissions?.edit && (
            <button onClick={handleCreateNewClick} className={"create-new"}>
              New Product
              <SaveIcon />
            </button>
            )}

            <button onClick={() => createExportList()} className={`download ${!blockPermissions?.edit && "single"}`} disabled={!list}>
              Download CSV
              {exportLoading ? <Spin /> : <DownloadIcon />}
            </button>

            <CSVLink data={listForExport || []} filename={"products"} ref={downloadBtnRef as any} />
          </ConfigProvider>
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default List
