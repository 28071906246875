import type { PaginationProps } from "antd"
import { ConfigProvider, Empty, Pagination, Select, Spin } from "antd"
import React, { FC, useState } from "react"
import { CSVLink } from "react-csv"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useGetUserEmailsLazyQuery, useGetUserNamesLazyQuery } from "../../../../../../../graphql"
import { DownloadIcon, EditDots, PlusIcon, SearchIcon } from "../../../../../../../icons"
import { formatDate, removeSelectDuplicates } from "../../../../../../../utils"
import "./index.less"
const pagination = { limit: 10000, start: 0 }

const List: FC<RoleListInterface> = ({
  list,
  page,
  loading,
  setPage,
  filters,
  pageSize,
  getStatus,
  setFilters,
  totalItems,
  setLoading,
  setPageSize,
  exportLoading,
  listForExport,
  downloadBtnRef,
  createExportList,
  createQueryFilters,
}) => {
  const navigate = useNavigate()

  const [getEmailsQuery] = useGetUserEmailsLazyQuery()
  const [getNamesQuery] = useGetUserNamesLazyQuery()

  const [names, setNames] = useState<SelectItems>()
  const [emails, setEmails] = useState<SelectItems>()

  const onChangePage: PaginationProps["onChange"] = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const onChangePageSize: PaginationProps["onShowSizeChange"] = (_, pageSize: number) => {
    setPageSize(pageSize)
  }

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const getItems = async (type: string) => {
    setLoading(true)

    const namesData = await getNamesQuery({
      fetchPolicy: "network-only",
      variables: {
        sort: "name:asc",
        filter: createQueryFilters(),
        pagination,
      },
    })

    setNames(
      removeSelectDuplicates(
        namesData.data?.usersPermissionsUsers?.data
          ?.map(item => ({
            value: item.attributes?.name!,
            label: item.attributes?.name!,
            key: item.id!,
          }))
          .filter(item => item.value)!
      )
    )

    const emailsData = await getEmailsQuery({
      fetchPolicy: "network-only",
      variables: {
        sort: "email:asc",
        filter: createQueryFilters(),
        pagination,
      },
    })

    setEmails(
      removeSelectDuplicates(
        emailsData.data?.usersPermissionsUsers?.data
          ?.map(item => ({
            value: item.attributes?.email!,
            label: item.attributes?.email!,
            key: item.id!,
          }))
          .filter(item => item.value)!
      )
    )

    setLoading(false)
  }

  const handleFilter = (data: string, type: string) => {
    switch (type) {
      case "name":
        setFilters({
          ...filters,
          selectedName: data,
        })
        break

      case "email":
        setFilters({
          ...filters,
          selectedEmail: data,
        })
        break
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    query: () => void,
    loading: boolean,
    options: SelectItems
  ) => (
    <div
      className={
        `${type?.toLowerCase().replace(" ", "-")} ${(
          (type == "name" && filters?.selectedName !== "All") ||
          (type == "email" && filters?.selectedEmail !== "All")
        ) && "active"
        }`}
      children={
        <Select
          showSearch
          onDropdownVisibleChange={query}
          value={name}
          optionFilterProp='children'
          onChange={data => handleFilter(data, type)}
          filterOption={filteredOption as any}
          options={options}
          defaultActiveFirstOption={true}
          notFoundContent={
            loading || !options ? (
              <div className={"no-data"} children={<Spin />} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
            )
          }
          suffixIcon={<SearchIcon />}
        />
      }
    />
  )

  const handleCreateNewClick = () => {
    navigate("/account/roles/admin-roles/new-role")
  }

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        key={JSON.stringify(filters) + String(page) + String(pageSize)}
        timeout={250}
        classNames={"fade"}
        appear={true}
        unmountOnExit
      >
        <div className={"main-list-wrapper roles-list"}>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "black",
                  colorPrimaryBorder: "black",
                  colorPrimaryHover: "black",
                  controlOutline: "#fff",
                },
                Select: {
                  colorText: "#677685",
                  colorPrimaryBorder: "#fff",
                  colorPrimaryHover: "#fff",
                  controlOutline: "#fff",
                  colorBgContainerDisabled: "#fff",
                },
              },
            }}
          >
            <div className={"list-wrapper"}>
              <div className={"items top"}>
                {createSelectElement("name", "NAME", () => getItems("name"), loading, names!)}
                {createSelectElement("email", "EMAIL", () => getItems("email"), loading, emails!)}
                <div children={"ROLE"} />
                <div children={"DATE"} />
                <div children={"STATUS"} />
                <div className={"centered"} children={"EDIT"} />
              </div>

              {list &&
                list.map((item: any, i: number) => (
                  <div className={"items"} key={`list-item-${i}`}>
                    <div children={<p>{item?.attributes?.name || "-"}</p>} />
                    <div children={<p>{item?.attributes?.email || "-"}</p>} />
                    <div
                      children={
                        <p>{item?.attributes?.role?.data?.attributes?.name?.replace("Adminportal ", "") || "-"}</p>
                      }
                    />
                    <div children={<p>{formatDate(item?.attributes?.createdAt) || "-"}</p>} />
                    <div className={`activity ${getStatus(item)}`} children={getStatus(item)} />
                    <div className={"centered"} children={<Link to={item?.id} children={<EditDots />} />} />
                  </div>
                ))}

              {!list && (
                <div className={"spinner-wrapper"}>
                  <Spin />
                </div>
              )}

              {list?.length == 0 && (
                <div className={"empty"}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>

            <div
              className={"navigation"}
              children={
                <Pagination
                  showQuickJumper
                  current={page}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                />
              }
            />

            <button onClick={handleCreateNewClick} className={"create-new"}>
              New Role
              <PlusIcon />
            </button>

            <button onClick={() => createExportList()} className={"download"} disabled={!list}>
              Download CSV
              {exportLoading ? <Spin /> : <DownloadIcon />}
            </button>

            <CSVLink data={listForExport || []} filename={"admins"} ref={downloadBtnRef as any} />
          </ConfigProvider>
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default List
