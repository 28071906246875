import "./index.less"
import { Spin } from "antd"
import { DateTime } from "luxon"
import LeftSide from "./components/LeftSide"
import RightSide from "./components/RightSide"
import React, { FC, useEffect, useState } from "react"
import { useGetEventQuery } from "../../../../../graphql"

const ManageBlock: FC<ManageEventBlockInterface> = ({ selectedEventId }) => {
  const { data: eventData, loading } = useGetEventQuery({
    fetchPolicy: "network-only",
    variables: {
        id:  String(selectedEventId)
    },
  })
  const event = eventData?.event?.data

  const [initialFilters, setInitialFilters] = useState<{ [key: string]: any }>()
  const [filters, setFilters] = useState<{ [key: string]: any }>()

  const [options, setOptions] = useState<{ [key: string]: SelectItems | null }>({
    distributors: null,
    regions: null,
    dscs: null,
  })

  const convertDateToObject = (date: Date | null | undefined, timeZone: string | null | undefined, type: string) => {
    const jsDate = new Date(date as any);
    const formattedTimeZone = timeZone?.replace("minus", "-")?.replace("plus", "+");
    const dateTime = DateTime.fromJSDate(jsDate, { zone: formattedTimeZone });
    const year = dateTime.year;
    const month = String(dateTime.month).padStart(2, "0");
    const day = String(dateTime.day).padStart(2, "0");
    const hours = String(dateTime.hour).padStart(2, "0");
    const minutes = String(dateTime.minute).padStart(2, "0");
    const datePart = `${year}-${month}-${day}`;
    const timePart = `${hours}:${minutes}`;
    return {
      [type]: {
        date: date ? datePart : "",
        time: date ? timePart : "",
        timeZone: date ? (formattedTimeZone || "") : "",
      }
    };
  };

  useEffect(() => {
    if ((selectedEventId && event) || !selectedEventId) {
      const filters = {
        image: null,
        title: event?.attributes?.title || "",
        address: event?.attributes?.address || "",
        urlLink: event?.attributes?.eventLink || "",
        description: event?.attributes?.description || "",
        youtubeLink: event?.attributes?.youtubeLink || "",
        currentImage: event?.attributes?.media?.data?.[0]?.attributes?.url || "",
        ...convertDateToObject(event?.attributes?.endAt, event?.attributes?.timeZone, "endAt"),
        ...convertDateToObject(event?.attributes?.startAt, event?.attributes?.timeZone, "startAt"),
        region: +event?.attributes?.regions?.data?.length! > 1 ?
          "All" :
          (options?.regions?.find(option => option.key == event?.attributes?.regions?.data?.[0]?.id)?.label || ""),
        distributor: options?.distributors?.find(option => option.key == event?.attributes?.distributors?.data?.[0]?.id)?.label || "",
        dsc: options?.dscs?.find(option => option.key == event?.attributes?.dscs?.data?.[0]?.id)?.label || "",
      }
      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [event, options])

  const isChangesDisabled = !!(initialFilters?.title && !selectedEventId)

  return (
    <div className={`block-manage-wrapper ${loading && "center"}`}>
      {!loading && filters && initialFilters ? (
        <>
          <LeftSide
            {...{
              event,
              options,
              filters,
              setOptions,
              setFilters,
              initialFilters,
              selectedEventId,
              isChangesDisabled,
              setInitialFilters,
            }}
          />
          <RightSide
            {...{
              event,
              filters,
              setFilters,
              isChangesDisabled,
            }}
          />
        </>
      ) : (
        <Spin className={"events-manage-block-spin"} />
      )}
    </div>
  )
}

export default ManageBlock
