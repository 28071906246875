import { ConfigProvider, Modal, Spin } from "antd"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router"
import { useDeleteCommentMutation, useDeletePostMutation } from "../../../../../../../graphql"
import { ReactComponent as DangerInfoIcon } from "../../../../../../../icons/danger-info.svg"
import Comments from "./components/Comments"
import Form from "./components/Form"
import "./index.less"

const LeftSide: FC<PostManageBlockLeftSideInterface> = ({
  post,
  options,
  filters,
  setOptions,
  setFilters,
  initialFilters,
  selectedPostId,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const navigate = useNavigate()
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<{ status: boolean; commentId: number | undefined }>({
    status: false,
    commentId: undefined,
  })
  const [comments, setComments] = useState<{ [key: string | number]: string }[]>(
    post?.attributes?.app_news_comments?.data || []
  )

  const [deletePost] = useDeletePostMutation()
  const [deleteComment] = useDeleteCommentMutation()

  const showModal = (commentId?: number) => {
    setIsModalOpen({ status: true, commentId: commentId })
  }

  const handleModalOk = async () => {
    setModalLoading(true)
    try {
      switch (typeof isModalOpen.commentId) {
        case "undefined":
          const postResponse = await deletePost({
            variables: {
              id: String(selectedPostId),
            },
          })
          if (postResponse?.data?.deleteAppNewsPost?.data?.id) {
            setIsModalOpen({ status: false, commentId: undefined })
            setTimeout(() => {
              navigate("/account/content/posts")
            }, 500)
          }
          break
        case "string":
          const commentResponse = await deleteComment({
            variables: {
              id: isModalOpen.commentId,
            },
          })
          if (commentResponse?.data?.deleteAppNewsComment?.data?.id) {
            const newCommentsSet = comments?.filter(comment => +comment?.id != isModalOpen.commentId)
            setComments(newCommentsSet)
            setIsModalOpen({ status: false, commentId: undefined })
          }
          break
      }
      setModalLoading(false)
    } catch (error) {
      setModalLoading(false)
    }
  }

  const handleModalCancel = () => {
    setIsModalOpen({ status: false, commentId: undefined })
  }

  return (
    <div className={"left-side-wrapper"}>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBorder: "#ffffff",
              colorPrimaryHover: "#ffffff",
              controlOutline: "#ffffff",
              colorError: "#ffffff",
              colorErrorBorderHover: "#ffffff",
              colorErrorOutline: "#ffffff",
              colorIcon: "#1C1C23",
              borderRadius: 0,
            },
            Select: {
              colorText: "#000",
              colorPrimaryBorder: "#fff",
              colorPrimaryHover: "#fff",
              controlOutline: "#fff",
              colorBgContainerDisabled: "#fff",
              colorBorder: "#fff",
            },
            Button: {
              borderRadius: 0,
              colorBorder: "#000",
              colorPrimary: "#000",
              colorPrimaryHover: "#707070",
              colorText: "#000",
            },
            Calendar: {
              colorPrimary: "#000",
              colorPrimaryHover: "#707070",
            },
          },
        }}
      >
        <Form
          {...{
            post,
            options,
            filters,
            setOptions,
            setFilters,
            initialFilters,
            selectedPostId,
            isChangesDisabled,
            setInitialFilters,
            showModal,
          }}
        />

        <Comments
          {...{
            comments,
            showModal,
          }}
        />

        <Modal open={isModalOpen.status} onOk={handleModalOk} onCancel={handleModalCancel} className={"post"}>
          <div className={"modal-content-wrapper"}>
            <DangerInfoIcon />
            <p>Are you sure you want to delete an existing {isModalOpen.commentId ? "comment" : "post"}?</p>
            <div className={"loading"}>{modalLoading && <Spin />}</div>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default LeftSide
