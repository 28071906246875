import { formatDate, truncate } from "../../../../../../../utils"
import ImageLoader from "../../../../../components/ImageLoader"
import React, { FC, useEffect, useState } from "react"
import "./index.less"

const RightSide: FC<ProductManageBlockRightSideInterface> = ({ filters, product, setFilters, isChangesDisabled }) => {
  const [fetchedImageURL, setFetchedImageURL] = useState<string>()

  useEffect(() => {
    if (filters!.currentImage && !filters!.youtubeLink) {
      setFetchedImageURL(filters!.currentImage as string)
    }
    if (filters!.youtubeLink) {
      setFetchedImageURL(undefined)
    }
  }, [filters])

  return (
    <div className={"general-info-wrapper"}>
      <div className={"title"}>INFORMATION</div>

      <ImageLoader
        type={"media"}
        filters={filters}
        maxImageSize={120000}
        setFilters={setFilters}
        fetchedImageURL={fetchedImageURL}
        isChangesDisabled={isChangesDisabled || !!filters?.youtubeLink}
        canBeRemoved={true}
      />

      <div className={"data"}>
        <div>
          Name
          <span>{truncate((filters?.title as string) || "-", 33)}</span>
        </div>

        <div>
          Date of creation
          <span>{formatDate(product?.attributes?.createdAt)}</span>
        </div>

        <div>
          Last update
          <span>{formatDate(product?.attributes?.updatedAt)}</span>
        </div>

        <div>
          Brand
          <span>
            {truncate((filters?.brand == "other" ? "COLOR.ME / GLOSS" : (filters?.brand as string)) || "-", 33)}
          </span>
        </div>

        <div>
          Type
          <span>{truncate((filters?.type as string) || "-", 33)}</span>
        </div>

        <div>
          Regimen
          <span>{truncate((filters?.benefit as string) || "-", 33)}</span>
        </div>

        <div>
          Items
          <span>{filters?.items?.length}</span>
        </div>
      </div>
    </div>
  )
}

export default RightSide
