import React, { FC } from "react"
import { formatDate, truncate } from "../../../../../../../utils"
import PdfLoader from "../../../../../components/PdfLoader"
import "./index.less"

const RightSide: FC<PdfManageBlockRightSideInterface> = ({ pdf, filters, setFilters, isChangesDisabled }) => {
  const getSize = (size: number) => (!size ? "-" : (size / 1000).toFixed(1) + " mb")

  return (
    <div className={"general-info-wrapper"}>
      <div className={"title"}>INFORMATION</div>

      <PdfLoader
        type={"file"}
        filters={filters}
        maxFileSize={10000000}
        setFilters={setFilters}
        isChangesDisabled={isChangesDisabled}
      />

      <div className={"data"}>
        <div>
          ID<span>{pdf?.id || "-"}</span>
        </div>

        <div>
          Category<span>{truncate(filters?.category || "-", 33)}</span>
        </div>

        <div>
          Pages<span>{filters?.pages || "-"}</span>
        </div>

        <div>
          Size (mb)<span>{getSize(filters?.size) || "-"}</span>
        </div>

        <div>
          Created by<span>{truncate(pdf?.attributes?.author?.data?.attributes?.name || "-", 33)}</span>
        </div>

        <div>
          Date of creation<span>{formatDate(pdf?.attributes?.createdAt)}</span>
        </div>

        <div>
          Last update<span>{formatDate(pdf?.attributes?.updatedAt)}</span>
        </div>
      </div>
    </div>
  )
}

export default RightSide
