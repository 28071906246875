import type { PaginationProps } from "antd"
import { ConfigProvider, Empty, Pagination, Select, Spin } from "antd"
import React, { FC, useState } from "react"
import { CSVLink } from "react-csv"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import {
  useGetSalonDistributorNamesLazyQuery,
  useGetSalonEmailsLazyQuery,
  useGetSalonNamesLazyQuery,
  useGetSalonSMnamesLazyQuery,
} from "../../../../../../../graphql"
import { DownloadIcon, EditDots, PlusIcon, SearchIcon } from "../../../../../../../icons"
import { removeSelectDuplicates } from "../../../../../../../utils"
import "./index.less"
import { useAuth } from "../../../../../../../components/auth"
const pagination = { limit: 10000, start: 0 }

const List: FC<RoleListInterface> = ({
  list,
  page,
  loading,
  setPage,
  filters,
  pageSize,
  getStatus,
  setFilters,
  totalItems,
  setLoading,
  setPageSize,
  exportLoading,
  listForExport,
  downloadBtnRef,
  createExportList,
  createQueryFilters,
}) => {
  const { permissions } = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Team")?.blocks?.find((block: any) => block.name == "Salon")

  const navigate = useNavigate()

  const [getNamesQuery] = useGetSalonNamesLazyQuery()
  const [getEmailsQuery] = useGetSalonEmailsLazyQuery()
  const [getSalesManagers] = useGetSalonSMnamesLazyQuery()
  const [getDistributors] = useGetSalonDistributorNamesLazyQuery()

  const [names, setNames] = useState<SelectItems>()
  const [emails, setEmails] = useState<SelectItems>()
  const [salesManagers, setSalesManagers] = useState<SelectItems>()
  const [distributors, setDistributors] = useState<SelectItems>()

  const onChangePage: PaginationProps["onChange"] = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const onChangePageSize: PaginationProps["onShowSizeChange"] = (_, pageSize: number) => {
    setPageSize(pageSize)
  }

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const getItems = async (type: string) => {
    setLoading(true)

    switch(type) {
      case "name": {
        setNames(undefined)
        const namesData = await getNamesQuery({
          fetchPolicy: "network-only",
          variables: {
            sort: "name:asc",
            filter: createQueryFilters(),
            pagination,
          },
        })
        setNames(
          removeSelectDuplicates(
            namesData.data?.salons?.data
              ?.map(item => ({
                value: item.attributes?.name!,
                label: item.attributes?.name!,
                key: item.id!,
              }))
              .filter(item => item.value)!
          )
        )
      }
      break

      case "email": {
        setEmails(undefined)
        const emailsData = await getEmailsQuery({
          fetchPolicy: "network-only",
          variables: {
            sort: "owner.email:asc",
            filter: createQueryFilters(),
            pagination,
          },
        })
        setEmails(
          removeSelectDuplicates(
            emailsData.data?.salons?.data
              ?.map(item => ({
                value: item.attributes?.owner?.data?.attributes?.email!,
                label: item.attributes?.owner?.data?.attributes?.email!,
                key: item.id!,
              }))
              .filter(item => item.value)!
          )
        )
      }
      break
      case "sales-manager": {
        const SMdata = await getSalesManagers({
          fetchPolicy: "network-only",
          variables: {
            sort: "sales_manager.name:asc",
            filter: createQueryFilters(),
            pagination,
          },
        })
        setSalesManagers(
          removeSelectDuplicates(
            SMdata.data?.salons?.data
              ?.map(item => ({
                value: item.attributes?.sales_manager?.data?.attributes?.name!,
                label: item.attributes?.sales_manager?.data?.attributes?.name!,
                key: item?.attributes?.sales_manager?.data?.id!,
              }))
              .filter(item => item.value)!
          )
        )
      }
        break;
      case "distributor": {
        const distributorsData = await getDistributors({
          fetchPolicy: "network-only",
          variables: {
            sort: "distributor.name:asc",
            filter: createQueryFilters(),
            pagination,
          },
        })
        setDistributors(
          removeSelectDuplicates(
            distributorsData.data?.salons?.data
              ?.map(item => ({
                value: item.attributes?.distributor?.data?.attributes?.name!,
                label: item.attributes?.distributor?.data?.attributes?.name!,
                key: item?.attributes?.distributor?.data?.id!,
              }))
              .filter(item => item.value)!
          )
        )
      }
    }
    setLoading(false)
  }

  const handleFilter = (data: string, type: string) => {
    const handleFilterChange = (valueType: string) =>  setFilters({
      ...filters,
      [valueType]: data,
    })

    switch (type) {
      case "name": handleFilterChange("selectedName")
        break
      case "email": handleFilterChange("selectedEmail")
        break
      case "sales-manager": handleFilterChange("selectedSM")
        break
      case "distributor": handleFilterChange("selectedDistributor")
        break
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    query: () => void,
    loading: boolean,
    options: SelectItems
  ) => (
    <div
      className={`${type} ${
        ((type == "name" && filters?.selectedName !== "All") ||
          (type == "email" && filters?.selectedEmail !== "All") ||
          (type == "sales-manager" && filters?.salesManager !== "All") ||
          (type == "distributor" && filters?.distributor !== "All")
        ) &&
        "active"
      }`}
      children={
        <Select
          showSearch
          value={name}
          options={options}
          optionFilterProp='children'
          onDropdownVisibleChange={query}
          defaultActiveFirstOption={true}
          filterOption={filteredOption as any}
          onChange={data => handleFilter(data, type)}
          notFoundContent={
            loading || !options ? (
              <div className={"no-data"} children={<Spin />} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
            )
          }
          suffixIcon={<SearchIcon />}
        />
      }
    />
  )

  const handleCreateNewClick = () => navigate("/account/team/salon/new-salon")

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        timeout={250}
        appear={true}
        unmountOnExit
        classNames={"fade"}
        key={JSON.stringify(filters) + String(page) + String(pageSize)}
      >
        <div className={"main-list-wrapper salons-list"}>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "black",
                  colorPrimaryBorder: "black",
                  colorPrimaryHover: "black",
                  controlOutline: "#fff",
                },
                Select: {
                  colorText: "#677685",
                  colorPrimaryBorder: "#fff",
                  colorPrimaryHover: "#fff",
                  controlOutline: "#fff",
                  colorBgContainerDisabled: "#fff",
                },
              },
            }}
          >
            <div className={"list-wrapper"}>
              <div className={"items top"}>
                {createSelectElement("name", "NAME", () => getItems("name"), loading, names!)}
                {createSelectElement("email", "EMAIL", () => getItems("email"), loading, emails!)}
                {createSelectElement("sales-manager", "SALES MANAGER", () => getItems("sales-manager"), loading, salesManagers!)}
                {createSelectElement("distributor", "DISTRIBUTOR", () => getItems("distributor"), loading, distributors!)}
                <div children={"REGION"} />
                <div children={"STATUS"} />
                <div className={"centered"} children={"EDIT"} />
              </div>

              {list &&
                list.map((item: any, i: number) => (
                  <div className={"items"} key={`list-item-${i}`}>
                    <div children={<p>{item?.attributes?.name || "-"}</p>} />
                    <div children={<p>{item?.attributes?.owner?.data?.attributes?.email || "-"}</p>} />
                    <div children={<p>{item?.attributes?.sales_manager?.data?.attributes?.name || "-"}</p>} />
                    <div children={<p>{item?.attributes?.distributor?.data?.attributes?.name || "-"}</p>} />
                    <div children={<p>{item?.attributes?.region?.data?.attributes?.name || "-"}</p>} />
                    <div className={`activity ${getStatus(item)}`} children={getStatus(item)} />
                    <div className={`centered ${!blockPermissions?.edit && "disabled"}`} children={<Link to={!blockPermissions?.edit ? "#" : item?.id} children={<EditDots />} />} />
                  </div>
                ))}

              {!list && (
                <div className={"spinner-wrapper"}>
                  <Spin />
                </div>
              )}

              {list?.length == 0 && (
                <div className={"empty"}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>

            <div
              className={"navigation"}
              children={
                <Pagination
                  showQuickJumper
                  current={page}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                />
              }
            />

            {blockPermissions?.edit && (
            <button onClick={handleCreateNewClick} className={"create-new"}>
              New Salon
              <PlusIcon />
            </button>
              )}

            <button onClick={() => createExportList()} className={`download ${!blockPermissions?.edit && "single"}`}  disabled={!list}>
              Download CSV
              {exportLoading ? <Spin /> : <DownloadIcon />}
            </button>

            <CSVLink data={listForExport || []} filename={"salons"} ref={downloadBtnRef as any} />
          </ConfigProvider>
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default List
