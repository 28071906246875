import "./index.less"
import List from "./components/List"
import Filter from "./components/Filter"
import { formatDate } from "../../../../../utils"
import React, { FC, useEffect, useRef, useState } from "react"
import { useGetEventListLazyQuery, useGetRegionsQuery } from "../../../../../graphql"
import { useAuth } from "../../../../../components/auth"

const ListBlock: FC = () => {
  const {region} = useAuth()
  const [getEventsList] = useGetEventListLazyQuery()

  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedStatus: "Ongoing",
    selectedRegion: "All",
    selectedTitle: "All",
    selectedAddress: "All",
  })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()
  const [totalItems, setTotalItems] = useState<number>(0)

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const { data: regionsData } = useGetRegionsQuery()
  const getRegionOptions = (): SelectItems => {
    const regionOptions = Array.from({ length: regionsData?.regions?.data.length || 0 }, (_, i) => ({
      label: regionsData?.regions?.data[i].attributes?.name!,
      value: regionsData?.regions?.data[i].attributes?.name!,
      key: regionsData?.regions?.data[i]?.id!,
    })) || []
    regionOptions.unshift({
      label: "All",
      value: "All",
      key: "0",
    })
    return regionOptions
  }

  const regionOptions = getRegionOptions()

  const createQueryFilters = () => ({
    type: filters.selectedStatus.toLowerCase(),
    ...(filters?.selectedTitle !== "All" ?
      {title: filters?.selectedTitle} :
      {}),
    ...(filters?.selectedAddress !== "All" ?
      {address: filters?.selectedAddress} :
      {}),
    ...(filters.selectedRegion !== "All" || region?.id ?
      {region: Number(region?.id) || +regionOptions.find(option => option.label == filters.selectedRegion)!.key } :
      {})
  })

  const createList = async (isForExport: boolean = false) => {
    setLoading(true)
    if (list && !isForExport) {
      setList(undefined)
    }

    let newList

    newList = await getEventsList({
      fetchPolicy: "network-only",
      variables: {
       input: {
         page: isForExport ? 1 : page,
         pageSize: isForExport ? 1000 : pageSize,
         ...createQueryFilters()
       }
      },
    })

    let totalItems
    let finalData

    totalItems = newList?.data?.events?.total || 0
    finalData = newList?.data?.events?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      setLoading(false)
      return
    } else {
      setLoading(false)
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        item?.title || "-",
        formatDate(item?.startAt) || "-",
        formatDate(item?.endAt) || "-",
        item?.address || "-",
      ]) || []
    listForExport = listForExport?.map(item => item.filter(entity => entity !== null))
    listForExport.unshift(["TITLE", "START", "END", "LOCATION"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  const createSpecificFiltersList = () => {
    let list = []
    if (filters.selectedTitle !== "All") list.push({ option: filters.selectedTitle, type: "title", backgroundColor: "rgba(215, 163, 171, 0.15)" , color: "#D7A3AB" })
    if (filters.selectedAddress !== "All") list.push({ option: filters.selectedAddress, type: "address", backgroundColor: "rgba(68, 195, 187, 0.15)", color: "#44C3BB" })
    return list
  }

  const specificFilters = createSpecificFiltersList()

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          filters,
          setFilters,
          regionOptions,
          specificFilters,
        }}
      />
      <List
        {...{
          list,
          page,
          loading,
          setPage,
          filters,
          pageSize,
          setFilters,
          totalItems,
          setLoading,
          setPageSize,
          exportLoading,
          listForExport,
          downloadBtnRef,
          createExportList,
          createQueryFilters
        }}
      />
    </div>
  )
}

export default ListBlock
