import type { PaginationProps } from "antd"
import { ConfigProvider, Empty, Pagination, Spin } from "antd"
import React, { FC } from "react"
import { CSVLink } from "react-csv"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useAuth } from "../../../../../../../components/auth"
import { DownloadIcon, EditDots, FileIcon, PlusIcon } from "../../../../../../../icons"
import { formatDate } from "../../../../../../../utils"
import "./index.less"

const List: FC<PdfListInterface> = ({
  list,
  page,
  setPage,
  filters,
  pageSize,
  totalItems,
  setPageSize,
  listForExport,
  exportLoading,
  downloadBtnRef,
  createExportList,
}) => {
  const navigate = useNavigate()
  const { permissions } = useAuth()

  const blockPermissions = permissions?.firstMenu
    ?.find(item => item.name == "Content")
    ?.blocks?.find((block: any) => block.name == "Links")

  const onChangePage: PaginationProps["onChange"] = (pageNumber: number) => setPage(pageNumber)

  const onChangePageSize: PaginationProps["onShowSizeChange"] = (_, pageSize: number) => setPageSize(pageSize)

  const handleCreateNewClick = () => navigate("/account/content/links/new-link")

  const getSize = (size: number) => {
    if (!size) return
    return (
      <div className={"size-holder"}>
        {(size / 1000).toFixed(1) + " mb"}
        <FileIcon />
      </div>
    )
  }

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        key={JSON.stringify(filters) + String(page) + String(pageSize)}
        timeout={250}
        classNames={"fade"}
        appear={true}
        unmountOnExit
      >
        <div className={"main-list-wrapper pdf-list"}>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "black",
                  colorPrimaryBorder: "black",
                  colorPrimaryHover: "black",
                  controlOutline: "#fff",
                },
                Select: {
                  colorText: "#677685",
                  colorPrimaryBorder: "#fff",
                  colorPrimaryHover: "#fff",
                  controlOutline: "#fff",
                  colorBgContainerDisabled: "#fff",
                },
              },
            }}
          >
            <div className={"list-wrapper"}>
              <div className={"items top"}>
                <div children={"ID"} />
                <div children={"CATEGORY"} />
                <div children={"DESCRIPTION"} />
                <div children={"DATE"} />
                <div children={"SIZE"} />
                <div children={"EDIT"} className={"centered"} />
              </div>

              {list &&
                list.map((item: any, i: number) => (
                  <div className={"items"} key={`list-item-${i}`}>
                    <div children={<p>{item?.id || "-"}</p>} />
                    <div children={<p>{item?.attributes?.category || "-"}</p>} />
                    <div children={<p>{item?.attributes?.description || "-"}</p>} />
                    <div children={<p>{formatDate(item?.attributes?.createdAt)}</p>} />
                    <div children={<p>{getSize(item?.attributes?.file?.data?.attributes?.size) || "-"}</p>} />
                    <div
                      className={`centered ${!blockPermissions?.edit && "disabled"}`}
                      children={<Link to={!blockPermissions?.edit ? "#" : item.id} children={<EditDots />} />}
                    />
                  </div>
                ))}

              {!list && (
                <div className={"spinner-wrapper"}>
                  <Spin />
                </div>
              )}

              {list?.length == 0 && (
                <div className={"empty"}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>

            <div
              className={"navigation"}
              children={
                <Pagination
                  showQuickJumper
                  current={page}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                />
              }
            />

            {blockPermissions?.edit && (
              <button onClick={handleCreateNewClick} className={"create-new"}>
                New Link
                <PlusIcon />
              </button>
            )}

            <button
              onClick={() => createExportList()}
              className={`download ${!blockPermissions?.edit && "single"}`}
              disabled={!list}
            >
              Download CSV
              {exportLoading ? <Spin /> : <DownloadIcon />}
            </button>

            <CSVLink data={listForExport || []} filename={"pdfs"} ref={downloadBtnRef as any} />
          </ConfigProvider>
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default List
