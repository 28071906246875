import { PlatinumBage, GoldBage, DiamondBage, PlusIcon, SearchIcon, DownloadIcon, EditDots } from "../../../../../../../icons"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { ConfigProvider, Empty, Pagination, Select, Spin } from "antd"
import { removeSelectDuplicates, truncate } from "../../../../../../../utils"
import type { PaginationProps } from "antd"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { CSVLink } from "react-csv"
import "./index.less"

import {
  useGetDistributorEntitiesLazyQuery,
  useGetDistributorsLazyQuery,
  useGetUserEmailsLazyQuery,
  useGetUserNamesLazyQuery,
  useGetSMsLazyQuery,
} from "../../../../../../../graphql"
import { useAuth } from "../../../../../../../components/auth"
import ToolTip from "../../../../../components/ToolTip"

const pagination = { limit: 10000, start: 0 }

const List: FC<TeamListInterface> = ({
  list,
  role,
  page,
  loading,
  setPage,
  filters,
  pageSize,
  setFilters,
  totalItems,
  setLoading,
  setPageSize,
  exportLoading,
  listForExport,
  downloadBtnRef,
  createExportList,
  createQueryFilters,
}) => {
  const {permissions} = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Team")?.blocks?.find((block: any) => role.includes(block.name))

  const navigate = useNavigate()

  const [getEmailsQuery] = useGetUserEmailsLazyQuery()
  const [getSMsQuery] = useGetSMsLazyQuery()
  const [getDistributorsQuery] = useGetDistributorsLazyQuery()
  const [getDistributorEntitiesQuery] = useGetDistributorEntitiesLazyQuery()
  const [getNamesQuery] = useGetUserNamesLazyQuery()

  const [names, setNames] = useState<SelectItems>()
  const [emails, setEmails] = useState<SelectItems>()
  const [salesManagers, setSalesManagers] = useState<SelectItems>()
  const [distributors, setDistributors] = useState<SelectItems>()

  const onChangePage: PaginationProps["onChange"] = pageNumber => {
    setPage(pageNumber)
  }

  const onChangePageSize: PaginationProps["onShowSizeChange"] = (_, pageSize) => {
    setPageSize(pageSize)
  }

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const getActivity = (item: any) => {
    return item?.attributes?.blocked ? "blocked" : item?.attributes?.isAppActivated ? "active" : "inactive"
  }

  const getItems = async (type: string) => {
    if (role != "Distributor Entity") {
      switch (type) {
        case "name":
          setNames(undefined)
          setLoading(true)
          const namesData = await getNamesQuery({
            fetchPolicy: "network-only",
            variables: {
              sort: "name:asc",
              filter: createQueryFilters(),
              pagination,
            },
          })
          setNames(
            removeSelectDuplicates(
              namesData.data?.usersPermissionsUsers?.data
                ?.map(item => ({
                  value: item.attributes?.name!,
                  label: item.attributes?.name!,
                  key: item.id!,
                }))
                .filter(item => item.value)!
            )
          )
          setLoading(false)
          break

        case "email":
          setEmails(undefined)
          setLoading(true)
          const emailsData = await getEmailsQuery({
            fetchPolicy: "network-only",
            variables: {
              sort: "email:asc",
              filter: createQueryFilters(),
              pagination,
            },
          })
          setEmails(
            removeSelectDuplicates(
              emailsData.data?.usersPermissionsUsers?.data
                ?.map(item => ({
                  value: item.attributes?.email!,
                  label: item.attributes?.email!,
                  key: item.id!,
                }))
                .filter(item => item.value)!
            )
          )
          setLoading(false)
          break

        case "Sales Manager":
          setSalesManagers(undefined)
          setLoading(true)
          const SMsData = await getSMsQuery({
            fetchPolicy: "network-only",
            variables: {
              sort: "dsc.sales_manager.name:asc",
              filter: createQueryFilters(),
              pagination,
            },
          })
          const SMsList = SMsData.data?.usersPermissionsUsers?.data
            .map(item => ({
              value: item.attributes?.dsc?.data?.attributes?.sales_manager?.data?.attributes?.name!,
              label: item.attributes?.dsc?.data?.attributes?.sales_manager?.data?.attributes?.name!,
              key: item.id!,
            }))
            .filter(item => item.value)
          setSalesManagers(removeSelectDuplicates(SMsList!))
          setLoading(false)
          break

        case "Distributor":
          setDistributors(undefined)
          setLoading(true)
          const distributorsData = await getDistributorsQuery({
            fetchPolicy: "network-only",
            variables: {
              sort:
                role == "DSC"
                  ? "dsc.sales_manager.distributor.name:asc"
                  : role == "Sales Manager"
                  ? "sales_manager.distributor.name:asc"
                  : "distributor.name:asc",
              filter: createQueryFilters(),
              pagination,
            },
          })
          const distributorsList = distributorsData.data?.usersPermissionsUsers?.data
            .map(item => {
              const name =
                item.attributes?.distributor?.data?.attributes?.name ||
                item.attributes?.sales_manager?.data?.attributes?.distributor?.data?.attributes?.name ||
                item.attributes?.dsc?.data?.attributes?.sales_manager?.data?.attributes?.distributor?.data?.attributes
                  ?.name

              return {
                value: name,
                label: name,
                key: item.id!,
              }
            })
            .filter(item => item.value)
          setDistributors(removeSelectDuplicates(distributorsList as SelectItems))
          setLoading(false)
          break
      }
    } else {
      setDistributors(undefined)
      setLoading(true)

      const distributorsData = await getDistributorEntitiesQuery({
        fetchPolicy: "network-only",
        variables: {
          filter: createQueryFilters(),
          pagination,
        },
      })

      const distributorsList = distributorsData.data?.distributors?.data
        ?.map(item => {
          const name = item.attributes?.name
          return {
            value: name,
            label: name,
            key: item.id!,
          }
        })
        .filter(item => item.value)

      setDistributors(removeSelectDuplicates(distributorsList as SelectItems))
      setLoading(false)
    }
  }

  const handleFilter = (data: string, type: string) => {
    switch (type) {
      case "name":
        setFilters({
          ...filters,
          selectedName: data,
        })
        break
      case "email":
        setFilters({
          ...filters,
          selectedEmail: data,
        })
        break
      case "Sales Manager":
        setFilters({
          ...filters,
          selectedSM: data,
        })
        break
      case "Distributor":
        setFilters({
          ...filters,
          selectedDistributor: data,
        })
        break
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    query: () => void,
    loading: boolean,
    options: SelectItems
  ) => (
    <div
      className={
      `${type?.toLowerCase().replace(" ", "-")} ${(
        (type == "name" && filters?.selectedName !== "All") ||
        (type == "email" && filters?.selectedEmail !== "All") ||
        (type == "Sales Manager" && filters?.selectedSM !== "All") ||
        (type == "Distributor" && filters?.selectedDistributor !== "All")
        ) && "active"
      }`}
      children={
        <Select
          showSearch
          onDropdownVisibleChange={() => {
            query()
          }}
          value={name}
          optionFilterProp='children'
          onChange={data => handleFilter(data, type)}
          filterOption={filteredOption as any}
          options={options}
          defaultActiveFirstOption={true}
          notFoundContent={
            loading || !options ? (
              <div className={"no-data"} children={<Spin />} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
            )
          }
          suffixIcon={<SearchIcon />}
        />
      }
    />
  )

  const handleCreateNewClick = () => {
    switch (role) {
      case "DSC":
        navigate("/account/team/dsc/new-user")
        break
      case "Sales Manager":
        navigate("/account/team/sales-manager/new-user")
        break
      case "Distributor":
        navigate("/account/team/distributor/users/new-user")
        break
      case "Distributor Entity":
        navigate("/account/team/distributor/entities/new-distributor")
        break
      case "Region":
        navigate("/account/team/region/new-user")
        break
    }
  }

  const badgeGenerator = (badge: string) => {
    let icon;
    let title
    switch (badge) {
      case "GOLD": {
        icon = <GoldBage />
        title = <div className={"tool-tip-ranking"}><span children={"Gold:"}/> $30K—$50K <br/><span className={"additional-text"} children={"(Average Monthly Sales)"}/></div>
      }
      break
      case "PLATINUM":
        icon = <PlatinumBage/>
        title = <div className={"tool-tip-ranking"}><span children={"Platinum:"}/> $50K—$75K <br/><span className={"additional-text"} children={"(Average Monthly Sales)"}/></div>
        break
      case "DIAMOND":
        icon = <DiamondBage/>
        title = <div className={"tool-tip-ranking"}><span children={"Diamond:"}/> over $75k <br/><span className={"additional-text"} children={"(Average Monthly Sales)"}/></div>
        break
    }
   return icon ?
     <ToolTip title={title} disabled={false} size={"fit-content"}>
     <div children={icon} className={"badge"}/>
     </ToolTip>
     :
     null
  }

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        key={JSON.stringify(createQueryFilters()) + String(page) + String(pageSize)}
        timeout={250}
        classNames={"fade"}
        appear={true}
        unmountOnExit
      >
        <div className={"main-list-wrapper team-list"}>
          <ConfigProvider
            theme={{
              components: {
                Pagination: {
                  colorPrimary: "black",
                  colorPrimaryBorder: "black",
                  colorPrimaryHover: "black",
                  controlOutline: "#fff",
                },
                Select: {
                  colorText: "#677685",
                  colorPrimaryBorder: "#fff",
                  colorPrimaryHover: "#fff",
                  controlOutline: "#fff",
                  colorBgContainerDisabled: "#fff",
                },
              },
            }}
          >
            <div className={"list-wrapper"}>
              <div className={`items top ${role?.toLowerCase()?.replace(" ", "-")}`}>
                {(role == "Distributor" || role == "Distributor Entity") &&
                  createSelectElement(
                    "Distributor",
                    "DISTRIBUTOR",
                    () => getItems("Distributor"),
                    loading,
                    distributors!
                  )}
                {(role == "Region" || role == "Distributor Entity") && <div children={"REGION"} />}
                {role !== "Distributor Entity" &&
                  createSelectElement("name", "NAME", () => getItems("name"), loading, names!)}
                {role !== "Distributor Entity" &&
                  createSelectElement("email", "EMAIL", () => getItems("email"), loading, emails!)}

                {role == "DSC" &&
                  createSelectElement(
                    "Sales Manager",
                    "SALES MANAGER",
                    () => getItems("Sales Manager"),
                    loading,
                    salesManagers!
                  )}
                {(role == "DSC" || role == "Sales Manager") &&
                  createSelectElement(
                    "Distributor",
                    "DISTRIBUTOR",
                    () => getItems("Distributor"),
                    loading,
                    distributors!
                  )}
                {(role == "DSC" || role == "Sales Manager" || role == "Distributor") && <div children={"REGION"} />}
                {role !== "Distributor Entity" && <div children={"STATUS"} />}
                <div className={"centered"} children={"EDIT"} />
              </div>
              {list &&
                list.map((item: any, i: number) => (
                  <div className={`items ${role?.toLowerCase()?.replace(" ", "-")}`} key={`list-item-${i}`}>
                    {(role == "Distributor" || role == "Distributor Entity") && (
                      <div
                        children={
                          <p>
                            {(role == "Distributor"
                              ? item?.attributes?.distributor?.data?.attributes?.name
                              : item?.attributes?.name) || "-"}
                          </p>
                        }
                      />
                    )}
                    {(role == "Region" || role == "Distributor Entity") && (
                      <div children={<p>{item?.attributes?.region?.data?.attributes?.name || "-"}</p>} />
                    )}
                    {role !== "Distributor Entity" &&
                      <div children={
                        <p>
                          {
                            (item?.attributes?.dsc?.data?.attributes?.ranking ?
                              truncate(item?.attributes?.name, 14) :
                              item?.attributes?.name) || "-"}
                          {badgeGenerator(item?.attributes?.dsc?.data?.attributes?.ranking)}
                        </p>
                      }
                      />}
                    {role !== "Distributor Entity" && <div children={<p>{item?.attributes?.email || "-"}</p>} />}
                    {role == "DSC" && (
                      <div
                        children={
                          <p>
                            {item?.attributes?.dsc?.data?.attributes?.sales_manager?.data?.attributes?.name ||
                              "-"}
                          </p>
                        }
                      />
                    )}
                    {(role == "DSC" || role == "Sales Manager") && (
                      <div
                        children={
                          <p>
                            {item?.attributes[role.replace(" ", "_")?.toLowerCase()]?.data?.attributes?.distributor
                              ?.data?.attributes?.name || "-"}
                          </p>
                        }
                      />
                    )}
                    {(role == "DSC" || role == "Sales Manager") && (
                      <div
                        children={
                          <p>
                            {item?.attributes[role.replace(" ", "_")?.toLowerCase()]?.data?.attributes?.distributor
                              ?.data?.attributes?.region?.data?.attributes?.name || "-"}
                          </p>
                        }
                      />
                    )}
                    {role == "Distributor" && (
                      <div
                        children={
                          <p>
                            {item?.attributes?.distributor?.data?.attributes?.region?.data?.attributes?.name ||
                              "-"}
                          </p>
                        }
                      />
                    )}
                    {role !== "Distributor Entity" && (
                      <div className={`activity ${getActivity(item)}`} children={getActivity(item)} />
                    )}
                    <div
                      className={`centered ${!blockPermissions?.edit && "disabled"}`}
                      children={
                        <Link
                          to={!blockPermissions?.edit ? "#" : item.id}
                          children={<EditDots />}
                        />
                      }
                    />
                  </div>
                ))}
              {!list && (
                <div className={"spinner-wrapper"}>
                  <Spin />
                </div>
              )}
              {list?.length == 0 && (
                <div className={"empty"}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>
            <div
              className={"navigation"}
              children={
                <Pagination
                  showQuickJumper
                  current={page}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                />
              }
            />

            {blockPermissions?.edit && (
            <button onClick={handleCreateNewClick} className={"create-new"}>
              {role != "Distributor Entity" ? "New User" : "New Distributor"}
              <PlusIcon />
            </button>
            )}

            <button onClick={() => createExportList()} className={`download ${!blockPermissions?.edit && "single"}`} disabled={!list}>
              Download CSV
              {exportLoading ? <Spin /> : <DownloadIcon />}
            </button>
            <CSVLink data={listForExport || []} filename={role} ref={downloadBtnRef as any} />
          </ConfigProvider>
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default List
