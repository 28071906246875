import { useCreateProductMutation, useUpdateProductMutation } from "../../../../../../../../../graphql"
import { areAllKeysPopulated, areObjectsEqual, truncate } from "../../../../../../../../../utils"
import { ReactComponent as SearchIcon } from "../../../../../../../../../icons/search.svg"
import { ReactComponent as CloseIcon } from "../../../../../../../../../icons/close.svg"
import { ReactComponent as TrashIcon } from "../../../../../../../../../icons/trash.svg"
import { ReactComponent as SaveIcon } from "../../../../../../../../../icons/check.svg"
import { TextEditor } from "../../../../../../../components/TextEditor"
import { Form as AntForm, Empty, Input, Select, Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import "./index.less"
import { useAuth } from "../../../../../../../../../components/auth"

const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""

const Form: FC<ProductManageBlockLeftSideFormInterface> = ({
  product,
  options,
  filters,
  showModal,
  setFilters,
  initialFilters,
  selectedProductId,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const {permissions} = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Content")?.blocks?.find((block: any) => block.name == "Products")

  const [createProduct] = useCreateProductMutation()
  const [updateProduct] = useUpdateProductMutation()

  const [finishLoading, setFinishLoading] = useState<boolean>(false)
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const handleValuesChange = (value: any, type: string) => {
    setFilters({
      ...filters,
      [type]: value,
      ...(type == "brand" && filters.benefit ? { benefit: "" } : {}),
      ...(type == "brand" && filters.type ? { type: "" } : {}),
    })
  }

  const handleFinish = async () => {
    let { media, name, title, description, brand, type, benefit, items, defaultSize, defaultColor, currentImage } =
      filters
    const normalizeEnumValue = (value: string): any => {
      return value.replace(/[\W_]+/g, "_").replace(/_$/, "");
    }

    items = items.map(
      (item: any) =>
        (item.currentImage || item.media) && {
          ...(item.id ? { id: item.id } : {}),
          SKU: item?.SKU || null,
          name: item.name || null,
          size: normalizeEnumValue(item.size) || null,
          color: normalizeEnumValue(item.color) || null,
          media: item.media || product?.attributes?.items?.find((product: any) => product.id == item.id).media.data.id,
        }
    )

    try {
      setFinishLoading(true)
      let imageId

      if (media) {
        const uploadResponse = await fetch(url + "/api/upload", {
          method: "POST",
          body: media,
        })
        const uploadData = await uploadResponse.json()
        if (!uploadData?.[0]?.id) {
          throw new Error("failed to upload image")
        }
        imageId = uploadData?.[0]?.id
      }

      const data: ProductInput = {
        items,
        description,
        title: name,
        isNew: false,
        isBestseller: false,
        descriptionTitle: title,
        brand: brand == "KM" ? brand : "COLOR_ME_GLOSS",
        defaultSize: normalizeEnumValue(defaultSize) || null,
        defaultColor: normalizeEnumValue(defaultColor) || null,
        type: options?.types?.find(option => option?.label == type)!.key,
        ...(imageId ? { media: imageId } : currentImage ? {} : { media: null }),
        benefit: options?.benefits?.find(option => option?.label == benefit)?.key || null,
      }

      let newProduct
      let updatedProduct

      if (!selectedProductId) {
        newProduct = await createProduct({
          variables: {
            data,
          },
        })
      } else {
        updatedProduct = await updateProduct({
          variables: {
            id: String(selectedProductId),
            data,
          },
        })
      }

      if (newProduct?.data?.createProduct?.data?.id || updatedProduct?.data?.updateProduct?.data?.id) {
        let newFilters = JSON.parse(JSON.stringify(filters))
        newFilters.currentImage =
          newProduct?.data?.createProduct?.data?.attributes?.media?.data?.[0]?.attributes?.url ||
          updatedProduct?.data?.updateProduct?.data?.attributes?.media?.data?.[0]?.attributes?.url ||
          ""
        newFilters.media = null

        const newItems = newProduct?.data?.createProduct?.data?.attributes?.items
        const updatedItems = updatedProduct?.data?.updateProduct?.data?.attributes?.items

        newFilters.items = newFilters.items.map((item: any, i: number) => {
            return {
              ...item,
              media: null,
              currentImage:
                newItems?.[i]?.media?.data?.attributes?.url ||
                updatedItems?.[i]?.media?.data?.attributes?.url ||
                ""
            }
          }
        )

        setInitialFilters(newFilters)
        setFilters(newFilters)
      }

      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    loading: boolean,
    options: SelectItems,
    isRequired = true,
    isDisabled = false
  ) => {
    const value = filters?.[type] || ""
    const canBeRemoved = !isDisabled && !isRequired && value

    return (
      <AntForm.Item name={name} label={name} required={isRequired}>
        <div className={"input-wrapper"}>
          {canBeRemoved && (
            <div className={"remove-icon"} children={<CloseIcon />} onClick={() => handleValuesChange("", type)} />
          )}
          <Select
            showSearch
            disabled={isDisabled}
            value={value}
            optionFilterProp='children'
            onChange={data => handleValuesChange(data, type)}
            filterOption={filteredOption as any}
            options={options}
            defaultActiveFirstOption={true}
            notFoundContent={
              loading || !options ? (
                <div className={"no-data"} children={<Spin />} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
              )
            }
            suffixIcon={<SearchIcon style={canBeRemoved ? { opacity: 0 } : {}} />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createInputElement = (type: string, label: string, isRequired = true) => (
    <AntForm.Item label={label} required={isRequired}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          onChange={e => handleValuesChange(e.target.value, type)}
          value={(filters?.[type] as string) || ""}
        />
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    if (filters && initialFilters) {
      const notRequiredKeys = ["items", "defaultSize", "defaultColor", "benefit", "media", "currentImage"]
      const items = filters.items
      const isDifferentRootValues = !areObjectsEqual(filters, initialFilters, "items")
      const isRootValuesValid = areAllKeysPopulated(filters, notRequiredKeys)
      const isDifferentItems =
        initialFilters.items?.length !== items?.length ||
        !!items.find((item: any, i: number) => !areObjectsEqual(item, initialFilters.items?.[i]))
      const isItemsDownloaded = !items.find((item: any) => item.media && isNaN(item.media))
      const isItemsValid =
        items.length && !items.find((item: any) => !item.currentImage && !item.media) && isItemsDownloaded

      setIsFormReady((isDifferentRootValues || isDifferentItems) && isRootValuesValid && isItemsValid)
      errorMessage && setErrorMessage("")
    }
  }, [filters, initialFilters])

  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper product"}>
          <div className={"block"}>
            <div className={"title"}>NAME</div>
            <div className={"inputs-wrapper wide-input"}>{createInputElement("name", "Name")}</div>
          </div>

          <div className={"block"}>
            <div className={"title"}>TITLE</div>
            <div className={"inputs-wrapper wide-input"}>{createInputElement("title", "Title")}</div>
          </div>

          <div className={"block"}>
            <div className={"title"}>DESCRIPTION</div>
            <div className={"inputs-wrapper wide-input"}>
              <TextEditor
                handleMarkdown={handleValuesChange}
                title={"Description"}
                type={"description"}
                fetchedMarkdown={product?.attributes?.description as string | undefined}
              />
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>GENERAL</div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("brand", "Brand", !options.brands, options.brands!, true, false)}
              {createSelectElement("type", "Type", !options.types, options.types!, true, !filters.brand)}
              {createSelectElement(
                "benefit",
                "Regimen",
                !options.benefits,
                options.benefits!,
                false,
                filters.brand != "KM"
              )}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>DEFAULT OPTIONS</div>
            <div className={"inputs-wrapper"}>
              {createSelectElement(
                "defaultSize",
                "Default Size",
                !options.defaultSizes,
                options.defaultSizes!,
                false,
                !filters.brand
              )}
              {createSelectElement(
                "defaultColor",
                "Default Color",
                !options.defaultColors,
                options.defaultColors!,
                false,
                !filters.brand
              )}
            </div>
          </div>

          {errorMessage && <div className={"error"}>{errorMessage}</div>}
        </div>
      </AntForm>

      {blockPermissions?.edit && (
      <button
        type={"submit"}
        onClick={handleFinish}
        className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
        disabled={!isFormReady || isChangesDisabled}
      >
        Save
        {finishLoading ? <Spin /> : <SaveIcon />}
      </button>
      )}

      {selectedProductId && blockPermissions?.delete ? (
        <button onClick={() => showModal()} className={"delete"}>
          Delete
          <TrashIcon />
        </button>
      ) : null}
    </>
  )
}

export default Form
