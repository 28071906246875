import { Form as AntForm, Empty, Input, Select, Spin } from "antd"
import { DateTime } from "luxon"
import React, { FC, useEffect, useRef, useState } from "react"
import {
  useCreateNotificationMutation, useGetDistributorEntitiesLazyQuery,
  useGetEventTitlesLazyQuery, useGetRegionsLazyQuery, useGetUserNamesLazyQuery,
  useUpdateNotificationMutation,
} from "../../../../../../../../../graphql"
import { CalendarIcon, ClockIcon, CloseIcon, SaveIcon, SearchIcon, TrashIcon } from "../../../../../../../../../icons"
import { areAllKeysPopulated, areObjectsEqual, truncate } from "../../../../../../../../../utils"
const pagination = { limit: 1000, start: 0 }
import "./index.less"
import { CustomCalendar } from "../../../../../../../components/CustomCalendar"
import { useAuth } from "../../../../../../../../../components/auth"

const Form: FC<NotificationManageBlockLeftSideFormInterface> = ({
  options,
  filters,
  showModal,
  setOptions,
  setFilters,
  eventTypeId,
  customTypeId,
  initialFilters,
  isChangesDisabled,
  setInitialFilters,
  selectedNotificationId,
}) => {
  const { permissions, region } = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Content")?.blocks?.find((block: any) => block.name == "Notifications")

  const [createNotification] = useCreateNotificationMutation()
  const [updateNotification] = useUpdateNotificationMutation()
  const [getDscs] = useGetUserNamesLazyQuery()
  const [getDistributors] = useGetDistributorEntitiesLazyQuery()
  const [getRegions] = useGetRegionsLazyQuery()
  const [getEvents] = useGetEventTitlesLazyQuery()

  const calendarSelectElement = useRef<any>(null)

  const [loading, setLoading] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const generateTimeOptions = (): SelectItems =>
    Array.from({ length: 96 }, (_, index) => {
      const hours = Math.floor((index * 15) / 60)
      const minutes = (index * 15) % 60
      const formattedHours = hours.toString().padStart(2, "0")
      const formattedMinutes = minutes.toString().padStart(2, "0")
      const time = `${formattedHours}:${formattedMinutes}`

      return {
        value: time,
        label: time,
        key: String(index),
      }
    })

  const generateTimeZoneOptions = (): SelectItems => {
    const utcOffsets = Array.from({ length: 27 }, (_, index) => index - 12)
    return utcOffsets.map(offset => {
      const formattedOffset = offset >= 0 ? `+${offset}` : `${offset}`

      return {
        value: `UTC${formattedOffset}`,
        label: `UTC${formattedOffset}`,
        key: `UTC${formattedOffset}`,
      }
    })
  }

  const getItems = async () => {
    setLoading(true)

    const itemCreator = (item: any) => ({
      value: item?.attributes?.name || item?.title,
      label: item?.attributes?.name || item?.title,
      key: item?.id,
    })

    const dscsData = await getDscs({
      variables: {
        pagination,
        sort: "name:asc",
        filter: {
          role: {name: {eq: "DSC"}},
          isAppActivated: {eq: true},
          ...(region?.id ?
            {dsc: {sales_manager: {distributor: {region: {id: {eq: region.id}}}}}} :
            {})
        }
      },
    })
    const dscOptions = dscsData?.data?.usersPermissionsUsers?.data.map(item => itemCreator(item)).filter(item => item.value)

    const distributorsData = await getDistributors({
      variables: {
        pagination,
        sort: "name:asc",
        ...(region?.id ? {filter: {region: {id: {eq: region.id}}}} : {})
      }
    })
    const distributorsOptions = distributorsData?.data?.distributors?.data
      .map(item => itemCreator(item))
      .filter(item => item.value)

    const regionsData = await getRegions()
    let regionsOptions = regionsData?.data?.regions?.data
      .map(item => itemCreator(item))
      .filter(item => item.value)
    regionsOptions?.unshift({key: "0", value: "All", label: "All"})
    if (region?.id) {
      regionsOptions = regionsOptions?.filter(item => item.key == region.id)
    }

    const eventsData = await getEvents({
      variables: {
        input: {
          page: 1,
          pageSize: 1000,
          ...(Number(region.id) ? {region: Number(region.id)} : {})
          }
        }
      })
    const eventOptions = eventsData?.data?.events
      ?.data!.map(item => itemCreator(item))
      .filter(item => item.value)
      .sort((a, b) => a.value!.localeCompare(b.value!))

    setOptions({
      dscs: dscOptions!,
      events: eventOptions!,
      regions: regionsOptions,
      distributors: distributorsOptions,
    })

    setLoading(false)
  }

  const handleValuesChange = (value: string, type: string) => {
    const invitationKeys = ["event", "dsc", "distributor", "region"];

    if (type == "date" || type == "time" || type == "timeZone") {
      setFilters({
        ...filters,
        dateAndTime: {
          ...(typeof filters?.dateAndTime == "object" ? { ...filters.dateAndTime } : {}),
          [type]: value,
        },
      })
      return
    }

    if ((type == "event" || type == "dsc"  || type == "distributor" || type == "region") && value) {
      setFilters({
        ...filters,
        ...invitationKeys
        .filter(key => key !== type)
          .reduce((acc, key) => {
            (acc as any)[key] = "";
            return acc;
          }, {}),
        [type]: value,
      })
      return
    }

    setFilters({
      ...filters,
      [type]: value,
    })
  }

  const handleFinish = async () => {
    const { title, description, event, dsc, distributor, region, dateAndTime } = filters as any

    const convertObjectToDate = (dateAndTime: { date: string; time: string; timeZone: string }): Date => {
      const { date, time, timeZone } = dateAndTime
      const dateTimeString = `${date}T${time}:00.000`
      const dateTime: DateTime = DateTime.fromISO(dateTimeString, { zone: timeZone })
      return dateTime.toJSDate()
    }

    const convertTimeZone = (timeZone: string) =>
      timeZone.replace("+", "plus").replace("-", "minus") as EnumAppnewspostTimezone

    try {
      setFinishLoading(true)

      const selectedEvent = options?.events?.find(option => option.label == event)?.key
      const selectedDSC = options?.dscs?.find(option => option.label == dsc)?.key
      const selectedDistributor = options?.distributors?.find(option => option.label == distributor)?.key
      const selectedRegion = options?.regions?.find(option => option.label == region)?.key
      const regionOptions =
        +selectedRegion! > 0 ?
          [selectedRegion] :
          region == "All" ?
          options?.regions?.filter(option => option.key && option.value !== "All")?.map(option => option.key) :
          null

      const data: AppNotificationInput = {
        title: title,
        isSent: false,
        body: description,
        regions: regionOptions,
        user: selectedDSC || null,
        event: selectedEvent || null,
        pushAt: convertObjectToDate(dateAndTime),
        timeZone: convertTimeZone(dateAndTime.timeZone),
        type: selectedEvent ? eventTypeId : customTypeId,
        distributors: selectedDistributor ? [selectedDistributor] : null,
      }

      let newNotification
      let updatedNotification

      if (!selectedNotificationId) {
        newNotification = await createNotification({
          variables: {
            data,
          },
        })
      } else {
        updatedNotification = await updateNotification({
          variables: {
            id: String(selectedNotificationId),
            data,
          },
        })
      }

      if (
        newNotification?.data?.createAppNotification?.data?.id ||
        updatedNotification?.data?.updateAppNotification?.data?.id
      ) {
        setInitialFilters(filters)
      }

      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (type: string, name: string, loading: boolean, options: SelectItems) => {
    const value = filters?.[type] || filters?.dateAndTime?.[type] || ""

    let SuffixIcon
    switch (type) {
      case "date":
        SuffixIcon = CalendarIcon
        break
      case "time":
        SuffixIcon = ClockIcon
        break
      default:
        SuffixIcon = SearchIcon
        break
    }

    const canBeRemoved = value

    const handleCalendarClick = (type: string) => {
      switch (type) {
        case "ok":
          calendarSelectElement?.current?.focus()
          calendarSelectElement?.current?.blur()
          break
        case "cancel":
          handleValuesChange("", "date")
          calendarSelectElement?.current?.focus()
          calendarSelectElement?.current?.blur()
          break
      }
    }

    return (
      <AntForm.Item name={name} label={name} required={false}>
        <div className={"input-wrapper"}>
          {canBeRemoved && (
            <div className={"remove-icon"} children={<CloseIcon />} onClick={() => handleValuesChange("", type)} />
          )}
          <Select
            showSearch
            options={options}
            optionFilterProp='children'
            defaultActiveFirstOption={true}
            filterOption={filteredOption as any}
            value={value ? truncate(value, 40) : ""}
            onChange={data => handleValuesChange(data, type)}
            {...(type == "date" ? { ref: calendarSelectElement } : {})}
            notFoundContent={
              type == "date" ? (
                <div>
                  <CustomCalendar
                    fullscreen={false}
                    onSelect={(data: any) =>
                      handleValuesChange(
                        `${String(data.$y)}-${String(data.$M + 1).padStart(2, "0")}-${String(data.$D).padStart(2, "0")}`,
                        type
                      )
                    }
                  />
                  <div className={"calendar-buttons"}>
                    <button onClick={() => handleCalendarClick("cancel")} className={"cancel"} children={"Cancel"} />
                    <button onClick={() => handleCalendarClick("ok")} children={"OK"} />
                  </div>
                </div>
              ) : loading || !options ? (
                <div className={"no-data"} children={<Spin />} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
              )
            }
            suffixIcon={<SuffixIcon style={canBeRemoved ? { opacity: 0 } : {}} />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createInputElement = (type: string, label: string) => (
    <AntForm.Item label={label} required={true}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          value={(filters?.[type] as string) || ""}
          onChange={e => handleValuesChange(
          type == "title" ?
          e.target.value.substring(0, 45) :
          type == "description" ?
          e.target.value.substring(0, 240) :
          e.target.value,
          type)}
        />
        {type == "title" && <div className={"limit-identifier"}>{filters?.title?.length || 0} / 45</div>}
        {type == "description" && <div className={"limit-identifier"}>{filters?.description?.length || 0} / 240</div>}
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    if (filters && initialFilters) {
      const notRequiredKeys = ["dsc", "distributor", "region", "event", "dateAndTime"]

      const dateFilter = filters.dateAndTime as any
      const dateInitialFilter = initialFilters.dateAndTime as any
      const isDifferentDate = !areObjectsEqual(dateFilter, dateInitialFilter)
      const isDateValuesValid = areAllKeysPopulated(dateFilter, [])

      const isDifferentRootValues = !areObjectsEqual(filters, initialFilters, "dateAndTime")
      const isRootValuesValid = areAllKeysPopulated(filters, notRequiredKeys) && (filters.dsc || filters.distributor || filters.region || filters.event)

      setIsFormReady((isDifferentRootValues || isDifferentDate) && isDateValuesValid && isRootValuesValid)
      errorMessage && setErrorMessage("")
    }
  }, [filters, initialFilters])

  useEffect(() => {
    getItems()
  }, [])

  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper notification"}>
          <div className={"block"}>
            <div className={"title"}>TITLE</div>
            <div className={"inputs-wrapper wide-input"}>
              {createInputElement("title", "Title")}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>DESCRIPTION</div>
            <div className={"inputs-wrapper wide-input"}>
              {createInputElement("description", "Description")}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>
              <span>* </span>
              PUSH DATE
            </div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("date", "Push date", loading, [])}
              {createSelectElement("time", "Push time", loading, generateTimeOptions())}
              {createSelectElement("timeZone", "Time zone", loading, generateTimeZoneOptions())}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>
                <span>* </span>TIED TO (Must choose one option)
            </div>
            <div className={"title two-items"}>
              <div>SPECIFIC RECEIVERS</div>
              <div>USERS OF THE EVENT</div>
            </div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("region", "Region", loading, options.regions!)}
              {createSelectElement("event", "Event", loading, options.events!)}
              {createSelectElement("distributor", "Distributor", loading, options.distributors!)}
              <div/>
              {createSelectElement("dsc", "DSC", loading, options.dscs!)}
            </div>
          </div>

          {errorMessage && <div className={"error"}>{errorMessage}</div>}
        </div>
      </AntForm>

      {blockPermissions?.edit && (
      <button
        type={"submit"}
        onClick={handleFinish}
        className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
        disabled={!isFormReady || isChangesDisabled}
      >
        Save
        {finishLoading ? <Spin /> : <SaveIcon />}
      </button>
      )}

      {selectedNotificationId && blockPermissions?.delete ? (
        <button onClick={() => showModal()} className={"delete"}>
          Delete
          <TrashIcon />
        </button>
      ) : null}
    </>
  )
}

export default Form
