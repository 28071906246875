import React, { FC } from "react"
import { ReactComponent as TrashIcon } from "../../../../../../../../../icons/trash.svg"
import { formatDate } from "../../../../../../../../../utils"
import "./index.less"

const Comments: FC<PostManageBlockLeftSideCommentsInterface> = ({ comments, showModal }) => {
  const noComments = !comments?.length

  return (
    <div className={"comments-block-wrapper"}>
      <div className={"title"}>COMMENTS{noComments ? " (No comments yet)" : ""}</div>

      {!noComments && (
        <div className={"comments-wrapper"}>
          <div className={"items top"}>
            <div children={"TITLE"} />
            <div children={"AUTHOR"} />
            <div children={"DATE"} />
            <div className={"centered"} children={"DELETE"} />
          </div>

          {comments.map((item: any, i: number) => (
            <div className={"items"} key={`list-item-${i}`}>
              <div children={<p>{item.attributes?.body || "-"}</p>} />
              <div children={<p>{item.attributes.user?.data?.attributes?.name || "Unknown"}</p>} />
              <div children={<p>{formatDate(item.attributes.createdAt)}</p>} />
              <div className={"centered"} children={<TrashIcon />} onClick={() => showModal(item.id)} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Comments
