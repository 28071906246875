import React, { FC, useEffect, useRef, useState } from "react"
import { useGetResourcesLazyQuery } from "../../../../../graphql"
import { formatDate } from "../../../../../utils"
import Filter from "./components/Filter"
import List from "./components/List"
import "./index.less"

const ListBlock: FC = () => {
  const [getLinksList] = useGetResourcesLazyQuery()

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const [list, setList] = useState<any>()
  const [page, setPage] = useState<number>(1)
  const [totalItems, setTotalItems] = useState<number>(0)
  const [listForExport, setListForExport] = useState<any>()
  const [pageSize, setPageSize] = useState<number>(10)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [filters, setFilters] = useState<{ [key: string]: string }>({
    selectedCategory: "All",
  })

  const createQueryFilters = () => ({
    ...(filters.selectedCategory !== "All" ? { category: { eq: filters.selectedCategory } } : {}),
  })

  const createList = async (isForExport: boolean = false) => {
    if (list && !isForExport) {
      setList(undefined)
    }

    let newList
    const pagination = {
      pageSize: isForExport ? 1000 : pageSize,
      page: isForExport ? undefined : page,
    }
    newList = await getLinksList({
      fetchPolicy: "network-only",
      variables: {
        filter: createQueryFilters(),
        sort: "createdAt:desc",
        pagination,
      },
    })

    let totalItems
    let finalData

    totalItems = newList?.data?.appResources?.meta?.pagination?.total || 0
    finalData = newList?.data?.appResources?.data

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      return

    } else {
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any) => [
        item?.id,
        item?.attributes?.category,
        item?.attributes?.description,
        formatDate(item?.attributes?.createdAt),
        (item?.attributes?.file?.data?.attributes?.size / 1000).toFixed(1) + " mb",
      ]) || []

    listForExport = listForExport?.map((item: any) => item.filter((entity: any) => entity !== null))
    listForExport.unshift(["ID", "CATEGORY", "DESCRIPTION", "DATE", "SIZE"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  return (
    <div className={"block-wrapper"}>
      <Filter
        {...{
          filters,
          setFilters,
        }}
      />
      <List
        {...{
          list,
          page,
          setPage,
          filters,
          pageSize,
          totalItems,
          setPageSize,
          listForExport,
          exportLoading,
          downloadBtnRef,
          createExportList,
        }}
      />
    </div>
  )
}

export default ListBlock
