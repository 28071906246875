import ManageBlock from "./blocks/ManageBlock"
import { useLocation } from "react-router"
import ListBlock from "./blocks/ListBlock"
import React, { FC } from "react"
import "./index.less"

const Products: FC = () => {
  const { pathname } = useLocation()
  const pathIdentifier = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createNewMode = pathIdentifier == "new-product"
  const selectedProductId = Number(pathIdentifier)

  return (
    <section className={"products"}>
      {!createNewMode && !selectedProductId && <ListBlock />}
      {(selectedProductId > 0 || createNewMode) && <ManageBlock selectedProductId={selectedProductId} />}
    </section>
  )
}

export default Products
