interface AllItems {
  countries: {
    key: number
    value: string
    label: string
    region: number
  }[]
  state: {
    key: number
    value: string
    label: string
  }[]
}

export const allItems: AllItems = {
  state: [
    {
      key: 1,
      value: "Alabama",
      label: "Alabama",
    },
    {
      key: 2,
      value: "Alaska",
      label: "Alaska",
    },
    {
      key: 3,
      value: "Arizona",
      label: "Arizona",
    },
    {
      key: 4,
      value: "Arkansas",
      label: "Arkansas",
    },
    {
      key: 5,
      value: "California",
      label: "California",
    },
    {
      key: 6,
      value: "Colorado",
      label: "Colorado",
    },
    {
      key: 7,
      value: "Connecticut",
      label: "Connecticut",
    },
    {
      key: 8,
      value: "Delaware",
      label: "Delaware",
    },
    {
      key: 9,
      value: "Florida",
      label: "Florida",
    },
    {
      key: 10,
      value: "Georgia",
      label: "Georgia",
    },
    {
      key: 11,
      value: "Hawaii",
      label: "Hawaii",
    },
    {
      key: 12,
      value: "Idaho",
      label: "Idaho",
    },
    {
      key: 13,
      value: "Illinois",
      label: "Illinois",
    },
    {
      key: 14,
      value: "Indiana",
      label: "Indiana",
    },
    {
      key: 15,
      value: "Iowa",
      label: "Iowa",
    },
    {
      key: 16,
      value: "Kansas",
      label: "Kansas",
    },
    {
      key: 17,
      value: "Kentucky",
      label: "Kentucky",
    },
    {
      key: 18,
      value: "Louisiana",
      label: "Louisiana",
    },
    {
      key: 19,
      value: "Maine",
      label: "Maine",
    },
    {
      key: 20,
      value: "Maryland",
      label: "Maryland",
    },
    {
      key: 21,
      value: "Massachusetts",
      label: "Massachusetts",
    },
    {
      key: 22,
      value: "Michigan",
      label: "Michigan",
    },
    {
      key: 23,
      value: "Minnesota",
      label: "Minnesota",
    },
    {
      key: 24,
      value: "Mississippi",
      label: "Mississippi",
    },
    {
      key: 25,
      value: "Missouri",
      label: "Missouri",
    },
    {
      key: 26,
      value: "Montana",
      label: "Montana",
    },
    {
      key: 27,
      value: "Nebraska",
      label: "Nebraska",
    },
    {
      key: 28,
      value: "Nevada",
      label: "Nevada",
    },
    {
      key: 29,
      value: "New Hampshire",
      label: "New Hampshire",
    },
    {
      key: 30,
      value: "New Jersey",
      label: "New Jersey",
    },
    {
      key: 31,
      value: "New Mexico",
      label: "New Mexico",
    },
    {
      key: 32,
      value: "New York",
      label: "New York",
    },
    {
      key: 33,
      value: "North Carolina",
      label: "North Carolina",
    },
    {
      key: 34,
      value: "North Dakota",
      label: "North Dakota",
    },
    {
      key: 35,
      value: "Ohio",
      label: "Ohio",
    },
    {
      key: 36,
      value: "Oklahoma",
      label: "Oklahoma",
    },
    {
      key: 37,
      value: "Oregon",
      label: "Oregon",
    },
    {
      key: 38,
      value: "Pennsylvania",
      label: "Pennsylvania",
    },
    {
      key: 39,
      value: "Rhode Island",
      label: "Rhode Island",
    },
    {
      key: 40,
      value: "South Carolina",
      label: "South Carolina",
    },
    {
      key: 41,
      value: "South Dakota",
      label: "South Dakota",
    },
    {
      key: 42,
      value: "Tennessee",
      label: "Tennessee",
    },
    {
      key: 43,
      value: "Texas",
      label: "Texas",
    },
    {
      key: 44,
      value: "Utah",
      label: "Utah",
    },
    {
      key: 45,
      value: "Vermont",
      label: "Vermont",
    },
    {
      key: 46,
      value: "Virginia",
      label: "Virginia",
    },
    {
      key: 47,
      value: "Washington",
      label: "Washington",
    },
    {
      key: 48,
      value: "West Virginia",
      label: "West Virginia",
    },
    {
      key: 49,
      value: "Wisconsin",
      label: "Wisconsin",
    },
    {
      key: 50,
      value: "Wyoming",
      label: "Wyoming",
    },
    {
      key: 51,
      value: "Washington, D.C.",
      label: "Washington, D.C.",
    },
  ],

  countries: [
    {
      key: 1,
      value: "australia",
      label: "Australia",
      region: 0,
    },
    {
      key: 2,
      value: "hong kong",
      label: "Hong Kong",
      region: 0,
    },
    {
      key: 3,
      value: "india",
      label: "India",
      region: 0,
    },
    {
      key: 4,
      value: "japan",
      label: "Japan",
      region: 0,
    },
    {
      key: 5,
      value: "new zealand",
      label: "New Zealand",
      region: 0,
    },
    {
      key: 6,
      value: "pakistan",
      label: "Pakistan",
      region: 0,
    },
    {
      key: 7,
      value: "singapore",
      label: "Singapore",
      region: 0,
    },
    {
      key: 8,
      value: "taiwan",
      label: "Taiwan",
      region: 0,
    },
    {
      key: 9,
      value: "austria",
      label: "Austria",
      region: 1,
    },
    {
      key: 10,
      value: "belgium",
      label: "Belgium",
      region: 1,
    },
    {
      key: 11,
      value: "bulgaria",
      label: "Bulgaria",
      region: 1,
    },
    {
      key: 12,
      value: "croatia",
      label: "Croatia",
      region: 1,
    },
    {
      key: 13,
      value: "cyprus",
      label: "Cyprus",
      region: 1,
    },
    {
      key: 14,
      value: "czech republic",
      label: "Czech Republic",
      region: 1,
    },
    {
      key: 15,
      value: "denmark",
      label: "Denmark",
      region: 1,
    },
    {
      key: 16,
      value: "estonia",
      label: "Estonia",
      region: 1,
    },
    {
      key: 17,
      value: "finland",
      label: "Finland",
      region: 1,
    },
    {
      key: 18,
      value: "france",
      label: "France",
      region: 1,
    },
    {
      key: 19,
      value: "germany",
      label: "Germany",
      region: 1,
    },
    {
      key: 20,
      value: "greece",
      label: "Greece",
      region: 1,
    },
    {
      key: 21,
      value: "hungary",
      label: "Hungary",
      region: 1,
    },
    {
      key: 22,
      value: "iceland",
      label: "Iceland",
      region: 1,
    },
    {
      key: 23,
      value: "ireland",
      label: "Ireland",
      region: 1,
    },
    {
      key: 24,
      value: "italy",
      label: "Italy",
      region: 1,
    },
    {
      key: 25,
      value: "kuwait",
      label: "Kuwait",
      region: 1,
    },
    {
      key: 26,
      value: "malta",
      label: "Malta",
      region: 1,
    },
    {
      key: 27,
      value: "netherlands",
      label: "Netherlands",
      region: 1,
    },
    {
      key: 28,
      value: "norway",
      label: "Norway",
      region: 1,
    },
    {
      key: 29,
      value: "poland",
      label: "Poland",
      region: 1,
    },
    {
      key: 30,
      value: "romania",
      label: "Romania",
      region: 1,
    },
    {
      key: 31,
      value: "slovenia",
      label: "Slovenia",
      region: 1,
    },
    {
      key: 32,
      value: "south africa",
      label: "South Africa",
      region: 1,
    },
    {
      key: 33,
      value: "spain",
      label: "Spain",
      region: 1,
    },
    {
      key: 34,
      value: "sweden",
      label: "Sweden",
      region: 1,
    },
    {
      key: 35,
      value: "switzerland",
      label: "Switzerland",
      region: 1,
    },
    {
      key: 36,
      value: "uae",
      label: "UAE",
      region: 1,
    },
    {
      key: 37,
      value: "united kingdom",
      label: "United Kingdom",
      region: 1,
    },
    {
      key: 38,
      value: "canada",
      label: "Canada",
      region: 2,
    },
    {
      key: 39,
      value: "united states",
      label: "United States",
      region: 2,
    },
    {
      key: 40,
      value: "latvia",
      label: "Latvia",
      region: 1,
    },
    {
      key: 41,
      value: "lithuania",
      label: "Lithuania",
      region: 1,
    },
  ],
}
