import { ConfigProvider, Modal, Spin } from "antd"
import React, { FC, useState } from "react"
import { useBlockingUserMutation } from "../../../../../../../graphql"
import { ReactComponent as DangerInfoIcon } from "../../../../../../../icons/danger-info.svg"
import Form from "./components/Form"
import "./index.less"

const LeftSide: FC<RoleManageBlockLeftSideInterface> = ({
  user,
  options,
  filters,
  setOptions,
  setFilters,
  selectedUserId,
  initialFilters,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const [blockingUser] = useBlockingUserMutation()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [blocked, setBlocked] = useState<boolean>(user?.attributes?.blocked)

  const handleModalOk = async () => {
    setModalLoading(true)

    try {
      const response = await blockingUser({
        variables: {
          input: {
            user_id: selectedUserId,
            blocked: !user?.attributes?.blocked,
          },
        },
      })

      if (response?.data?.blockingUser?.success) {
        setModalLoading(false)
        setIsModalOpen(false)
        setBlocked(!!response?.data?.blockingUser?.blocked)
      }
    } catch (error) {
      setModalLoading(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className={"left-side-wrapper"}>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBorder: "#ffffff",
              colorPrimaryHover: "#ffffff",
              controlOutline: "#ffffff",
              colorError: "#ffffff",
              colorErrorBorderHover: "#ffffff",
              colorErrorOutline: "#ffffff",
              colorIcon: "#1C1C23",
              borderRadius: 0,
            },
            Select: {
              colorText: "#000",
              colorPrimaryBorder: "#fff",
              colorPrimaryHover: "#fff",
              controlOutline: "#fff",
              colorBgContainerDisabled: "#fff",
              colorBorder: "#fff",
            },
            Button: {
              borderRadius: 0,
              colorBorder: "#000",
              colorPrimary: "#000",
              colorPrimaryHover: "#707070",
              colorText: "#000",
            },
          },
        }}
      >
        <Form
          {...{
            user,
            blocked,
            options,
            filters,
            showModal,
            setOptions,
            setFilters,
            selectedUserId,
            initialFilters,
            isChangesDisabled,
            setInitialFilters,
          }}
        />

        <Modal open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel} className={"roles"}>
          <div className={"modal-content-wrapper"}>
            <DangerInfoIcon />
            <p>Are you sure you want to block an existing user?</p>
            <div className={"loading"}>{modalLoading && <Spin />}</div>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default LeftSide
