import React, { FC, useEffect, useState } from "react"
import { formatDate, truncate } from "../../../../../../../utils"
import ImageLoader from "../../../../../components/ImageLoader"
import "./index.less"

const RightSide: FC<LinkManageBlockRightSideInterface> = ({ link, types, filters, setFilters, isChangesDisabled }) => {
  const [fetchedImageURL, setFetchedImageURL] = useState<string>()
  const isVideo = fetchedImageURL?.includes(".mov") || fetchedImageURL?.includes(".mp4")

  useEffect(() => {
    if (filters!.currentImage) {
      setFetchedImageURL(filters!.currentImage as string)
    }
  }, [filters])

  return (
    <div className={"general-info-wrapper"}>
      <div className={"title"}>INFORMATION</div>

      <ImageLoader
        type={"image"}
        filters={filters}
        acceptVideo={true}
        setFilters={setFilters}
        isChangesDisabled={isChangesDisabled || !!filters?.youtubeLink}
        {...(isVideo ? {fetchedVideoURL: fetchedImageURL} : {fetchedImageURL: fetchedImageURL})}
      />

      <div className={"data"}>
        <div>
          Title<span>{truncate(filters?.title || "-", 33)}</span>
        </div>

        <div>
          Type
          <span>
          {
            types?.find((type: any) =>
              type.value == filters?.type)?.label ||
              link?.attributes?.more_section_type?.data?.attributes?.label ||
              "-"
          }
          </span>
        </div>

        <div>
          Link<span>{truncate(filters?.urlLink || "-", 33)}</span>
        </div>

        <div>
          Created by<span>{truncate(link?.attributes?.author?.data?.attributes?.name || "-", 33)}</span>
        </div>

        <div>
          Date of creation<span>{formatDate(link?.attributes?.createdAt)}</span>
        </div>

        <div>
          Last update<span>{formatDate(link?.attributes?.updatedAt)}</span>
        </div>
      </div>
    </div>
  )
}

export default RightSide
