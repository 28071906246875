import { Layout as BaseLayout } from "antd"
import React, { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"

const Layout: FC = () => {
  return (
    <BaseLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </BaseLayout>
  )
}

export { Layout }
