import React, { FC, useEffect, useRef, useState } from "react"
import { useGetSecondLeaderboardLazyQuery } from "../../../../graphql"
import List from "./components/List"

const SecondList: FC<LeaderboardInterface> = ({
  page,
  setPage,
  filters,
  pageSize,
  dscOptions,
  setFilters,
  sortOptions,
  monthOptions,
  setTotalItems,
  competitionTypeOptions,
}) => {
  const [getLeaderboard] = useGetSecondLeaderboardLazyQuery()

  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()

  const createList = async (isForExport: boolean = false) => {
    if (list && !isForExport) {
      setList(undefined)
    }

    let newList
    newList = await getLeaderboard({
      variables: {
        input: {
          type: competitionTypeOptions!.find(type => type.label == filters?.selectedCompetition)!.value,
          sortBy: sortOptions?.find(type => type.label == filters?.sortBy)!.value,
          month: +monthOptions.find(option => option.label == filters.selectedMonth)!.key,
          year: +filters.selectedYear,
          page: isForExport ? 1 : page,
          pageSize: isForExport ? 5000 : pageSize,
          ...(filters.selectedDSC !== "All"
            ? { dscEmail: dscOptions!.find(option => option.label == filters.selectedDSC)!.key }
            : {}),
        },
      },
    })

    let totalItems: number
    let finalData: object[]

    totalItems = newList?.data?.appGetCompetitionLeaderboard?.participants || 0
    finalData = newList?.data?.appGetCompetitionLeaderboard?.leaderboard as object[]

    if (filters.selectedRegion !== "North America") {
      totalItems = 0
      finalData = []
    }

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      return
    } else {
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any, i) => [i + 1, item.name, filters.selectedRegion, item.quantity, item.deals]) || []
    listForExport = listForExport?.map(item => item.filter(entity => entity !== null))
    listForExport.unshift(["RANK", "DSC", "REGION", "UNITS", "DEALS / SETS"])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  useEffect(() => {
    if (filters?.selectedCompetition) {
      setListForExport(undefined)
      createList()
    }
  }, [pageSize, page, filters])

  return (
    <List
      {...{
        list,
        page,
        filters,
        pageSize,
        setFilters,
        dscOptions,
        listForExport,
        exportLoading,
        downloadBtnRef,
        createExportList,
      }}
    />
  )
}

export default SecondList
