import React, { FC, useEffect, useState } from "react"
import { formatDate, truncate } from "../../../../../../../utils"
import ImageLoader from "../../../../../components/ImageLoader"
import "./index.less"

const RightSide: FC<PostManageBlockRightSideInterface> = ({ post, filters, setFilters, isChangesDisabled }) => {
  const [fetchedURL, setFetchedURL] = useState<string>()
  const isVideo = fetchedURL?.includes(".mov") || fetchedURL?.includes(".mp4")

  useEffect(() => {
    if (filters!.currentImage && !filters!.youtubeLink) {
      setFetchedURL(filters!.currentImage as string)
    }
    if (filters!.youtubeLink) {
      setFetchedURL(undefined)
    }
  }, [filters])

  return (
    <div className={"general-info-wrapper"}>
      <div className={"title"}>INFORMATION</div>

      <ImageLoader
        type={"image"}
        filters={filters}
        acceptVideo={true}
        setFilters={setFilters}
        isChangesDisabled={isChangesDisabled || !!filters?.youtubeLink}
        {...(isVideo ? {fetchedVideoURL: fetchedURL} : {fetchedImageURL: fetchedURL})}
      />

      <div className={"data"}>
        <div>
          Title<span>{truncate((filters?.title as string) || "-", 33)}</span>
        </div>

        <div>
          Created by<span>{truncate(post?.attributes?.author?.data?.attributes?.name || "-", 33)}</span>
        </div>

        <div>
          Date of creation<span>{formatDate(post?.attributes?.createdAt)}</span>
        </div>

        <div>
          Last update<span>{formatDate(post?.attributes?.updatedAt)}</span>
        </div>

        <div>
          Category<span>{truncate((filters?.category as string) || "-", 33)}</span>
        </div>

        <div>
          Launch date<span>{(filters?.dateAndTime as any)?.date || "-"}</span>
        </div>

        <div>
          Launch time
          <span>
            {(filters?.dateAndTime as any)?.time +
              ((filters?.dateAndTime as any)?.timeZone ? " " + (filters?.dateAndTime as any)?.timeZone : "") || "-"}
          </span>
        </div>
      </div>
    </div>
  )
}

export default RightSide
