import { Spin } from "antd"
import React, { FC } from "react"
import { useMatch } from "react-router"
import { useMeQuery } from "../../../graphql"
import Form from "./components/Form"
import Navigation from "./components/Navigation"
import "./index.less"

const Profile: FC = () => {
  const { data: userData, loading, refetch } = useMeQuery()
  const legalMode = !!useMatch("/account/profile/legal")
  const accountMode = !!useMatch("/account/profile/account")
  const passwordMode = !!useMatch("/account/profile/password")

  return (
    <section className={"profile"}>
      <div className={"main-wrapper"}>
        <Navigation />
        <div className={`content ${loading && "loading"}`}>
          {!loading ? (
            <Form
              {...{
                refetch,
                userData,
                legalMode,
                accountMode,
                passwordMode,
              }}
            />
          ) : (
            <Spin className={"profile-spin"} />
          )}
        </div>
      </div>
    </section>
  )
}

export default Profile
