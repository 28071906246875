import { Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { v4 as uuidv4 } from "uuid"
import { useReorderLinksMutation } from "../../../../../graphql"
import { EditSixDots, SaveIcon } from "../../../../../icons"
import { areObjectsEqual } from "../../../../../utils"
import "./index.less"

const linksManagement: FC<MoreLinksManagementInterface> = ({ data, refetch, handleModalCancel }) => {
  const [changeOrder] = useReorderLinksMutation()

  const [initialFilters, setInitialFilters] = useState<LinkInterface[]>([])
  const [filters, setFilters] = useState<LinkInterface[]>([])
  const [loading, setLoading] = useState<boolean>(!filters)

  const isItemsEqual = areObjectsEqual(initialFilters, filters)

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return

    const newItems = Array.from(filters)
    const [reorderedItem] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, reorderedItem)
    setFilters(newItems)
  }

  const onSave = async () => {
    setLoading(true)
    try {
      const newOrder = filters.map(item => item.itemId)
      const response = await changeOrder({ variables: { input: { newOrder } } })

      if (!response.data?.reorderLinks?.success) {
        throw Error
      } else {
        await refetch()
        setLoading(false)
        setTimeout(() => {
          handleModalCancel()
        }, 250)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (data) {
      const filters =
        data.map(item => ({
          id: uuidv4(),
          itemId: +item.id!,
          label: item!.label,
          moreSectionType: item!.moreSectionType,
        })) || []

      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [data])

  return (
    <div className={"modal-sections-management-wrapper"}>
      <div>
        <div className={"title"}>{data?.[0]?.moreSectionType?.toUpperCase() || ""}</div>
        <div className={"additional-description"}>Here, you have the ability to relocate bynders as needed.</div>
      </div>

      <div className={"list-wrapper"}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='characters'>
            {provided => (
              <ul className='list' {...provided.droppableProps} ref={provided.innerRef}>
                {filters.map(({ id, itemId, label }, index) => {
                  return (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                      {provided => (
                        <li
                          className={"card"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={"name"}>
                            <p>{label}</p>
                          </div>
                          <EditSixDots className={"disabled"} />
                        </li>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className={`save-button ${!isItemsEqual && "active"}`} onClick={() => !isItemsEqual && onSave()}>
        Save
        {loading ? <Spin /> : <SaveIcon />}
      </div>
    </div>
  )
}

export default linksManagement