import { ConfigProvider, Modal, Spin } from "antd"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router"
import { useDeleteLinkMutation } from "../../../../../../../graphql"
import { DangerInfoIcon } from "../../../../../../../icons"
import Form from "./components/Form"
import "./index.less"

const LeftSide: FC<LinkManageBlockLeftSideInterface> = ({
  link,
  types,
  filters,
  setFilters,
  initialFilters,
  selectedLinkId,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)

  const [deleteLink] = useDeleteLinkMutation()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleModalOk = async () => {
    setModalLoading(true)

    try {
      const linkResponse = await deleteLink({
        variables: {
          id: String(selectedLinkId),
        },
      })
      if (linkResponse?.data?.deleteAppNewsPost?.data?.id) {
        setIsModalOpen(false)
        setTimeout(() => {
          navigate("/account/content/assets")
        }, 500)
      }
      setModalLoading(false)
    } catch (error) {
      setModalLoading(false)
    }
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className={"left-side-wrapper"}>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBorder: "#ffffff",
              colorPrimaryHover: "#ffffff",
              controlOutline: "#ffffff",
              colorError: "#ffffff",
              colorErrorBorderHover: "#ffffff",
              colorErrorOutline: "#ffffff",
              colorIcon: "#1C1C23",
              borderRadius: 0,
            },
            Select: {
              colorText: "#000",
              colorPrimaryBorder: "#fff",
              colorPrimaryHover: "#fff",
              controlOutline: "#fff",
              colorBgContainerDisabled: "#fff",
              colorBorder: "#fff",
            },
            Button: {
              borderRadius: 0,
              colorBorder: "#000",
              colorPrimary: "#000",
              colorPrimaryHover: "#707070",
              colorText: "#000",
            },
          },
        }}
      >
        <Form
          {...{
            link,
            types,
            filters,
            showModal,
            setFilters,
            initialFilters,
            selectedLinkId,
            isChangesDisabled,
            setInitialFilters,
          }}
        />

        <Modal open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel} className={"link"}>
          <div className={"modal-content-wrapper"}>
            <DangerInfoIcon />
            <p>Are you sure you want to delete an existing link?</p>
            <div className={"loading"}>{modalLoading && <Spin />}</div>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}

export default LeftSide
