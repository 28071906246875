import { Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useGetUsersQuery } from "../../../../../graphql"
import LeftSide from "./components/LeftSide"
import RightSide from "./components/RightSide"
import "./index.less"

const ManageBlock: FC<ManageRolesBlockInterface> = ({ selectedUserId }) => {
  const { data: userData, loading } = useGetUsersQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        id: {
          eq: String(selectedUserId),
        },
      },
    },
  })

  const user = userData?.usersPermissionsUsers?.data?.[0]
  const [initialFilters, setInitialFilters] = useState<{ [key: string]: string | number | Object }>()
  const [filters, setFilters] = useState<{ [key: string]: string | number | Object }>()
  const [options, setOptions] = useState<{ [key: string]: SelectItems | null }>({
    roles: null,
    regions: null,
  })

  useEffect(() => {
    if (user || !selectedUserId) {
      const filters = {
        name: user?.attributes?.name || "",
        email: user?.attributes?.email || "",
        role: user?.attributes?.role?.data?.attributes?.name?.replace("Adminportal ", "") || "",
        region: user?.attributes?.region?.data?.attributes?.name || (user ? "All" : ""),
      }

      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [user, options])

  const isChangesDisabled = !!(initialFilters?.name && !selectedUserId)
  const isPageReady = !loading && filters && initialFilters

  return (
    <div className={`block-manage-wrapper ${loading && "center"}`}>
      {isPageReady ? (
        <>
          <LeftSide
            {...{
              user,
              options,
              filters,
              setOptions,
              setFilters,
              selectedUserId,
              initialFilters,
              isChangesDisabled,
              setInitialFilters,
            }}
          />
          <RightSide
            {...{
              user,
              filters,
            }}
          />
        </>
      ) : (
        <Spin className={"roles-manage-block-spin"} />
      )}
    </div>
  )
}

export default ManageBlock
