import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { useGetEnumsQuery, useGetRegionsQuery } from "../../../../../../../graphql"
import { capitalizeEveryWord, truncate } from "../../../../../../../utils"
import "./index.less"
import { useAuth } from "../../../../../../../components/auth"

const Filter: FC<RoleFilterInterface> = ({ filters, setFilters, specificFilters }) => {
  const { region } = useAuth()

  const { data: regionsData, loading } = useGetRegionsQuery()
  const { data: typesData } = useGetEnumsQuery({
    variables: {
      input: {
        collection: "api::guest.guest",
        fieldName: "guest_type",
      },
    },
  })

  const getOptions = (type: string) => {
    const regions = regionsData?.regions?.data
    const types = typesData?.getEnum?.enum

    const options = Array.from(
      {
        length: (type == "region" ? regions?.length : types?.length) || 0,
      },
      (_, i) => ({
        label: type == "region" ? regions![i].attributes?.name : capitalizeEveryWord(types![i]!),
        key: i + 1,
      })
    )
    options.unshift({
      label: "All",
      key: 0,
    })
    return options || []
  }

  let regionOptions = getOptions("region")
  const typeOptions = getOptions("type")

  if (region?.name) {
    const adminRegion = region!.name!
    regionOptions = [{ label: adminRegion, key: 0}]
  }

  const resetFilter = (type: string) => {
    switch (type) {
      case "name":
        setFilters({ ...filters, selectedName: "All" })
        break
      case "email":
        setFilters({ ...filters, selectedEmail: "All" })
        break
    }
  }

  const activeSpecificFiltersAmount = specificFilters.length

  return (
    <div className={"filters-wrapper guest-roles-filter"}>
      <div className={"filters"}>
        <Dropdown
          className={`region-filter ${filters.selectedRegion != "All" && "active"}`}
          trigger={["click"]}
          disabled={loading}
          overlay={
            <Menu
              onClick={e =>
                setFilters({ ...filters, selectedRegion: regionOptions.find(item => item.key == +e.key)!.label! })
              }
              selectedKeys={[`${regionOptions.find(item => item.label == filters.selectedRegion)!.key}`]}
            >
              {regionOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(regionOptions.find(item => item.label == filters.selectedRegion)!.label!, 15)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>

        <Dropdown
          className={`type-filter ${filters.selectedType != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e =>
                setFilters({
                  ...filters,
                  selectedType: typeOptions.find(item => item.key == +e.key)!.label! || "All",
                })
              }
              selectedKeys={[`${typeOptions.find(item => item.label == filters.selectedType)!.key}`]}
            >
              {typeOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(typeOptions.find(item => item.label == filters.selectedType)!.label!, 15)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>

      <div className={"specific-filters-wrapper"}>
        Filters ({activeSpecificFiltersAmount})
        <div className={"specific-filters-list"}>
          {specificFilters &&
            specificFilters.map((filter: any, i: number) => (
              <div
                className={"block"}
                key={`filter-${i}`}
                style={{ backgroundColor: filter.backgroundColor, color: filter.color }}
              >
                {truncate(filter.option, 32)}
                <div className={"remove-filter"} onClick={() => resetFilter(filter.type)}>
                  <div className={"l-line"} />
                  <div className={"r-line"} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
