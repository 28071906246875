import React, { FC } from "react"
import { useLocation } from "react-router"
import ListBlock from "./blocks/ListBlock"
import ManageBlock from "./blocks/ManageBlock"
import "./index.less"

const Notifications: FC = () => {
  const { pathname } = useLocation()
  const pathIdentifier = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createNewMode = pathIdentifier == "new-notification"
  const selectedNotificationId = Number(pathIdentifier)

  return (
    <section className={"notifications"}>
      {!createNewMode && !selectedNotificationId && <ListBlock />}
      {(selectedNotificationId > 0 || createNewMode) && <ManageBlock selectedNotificationId={selectedNotificationId} />}
    </section>
  )
}

export default Notifications
