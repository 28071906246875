import { ChangeEvent, FC, useEffect, useState } from "react"
import placeholderEmpty from "../../../../images/default-pdf.png"
import placeholderFilled from "../../../../images/pdf-added.png"
import ToolTip from "../ToolTip"
import "./index.less"

const PdfLoader: FC<PdfLoaderInterface> = ({ type, filters, setFilters, maxFileSize, isChangesDisabled }) => {
  const [preview, setPreview] = useState<string>(placeholderEmpty)

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const input = e.target as HTMLInputElement

    if (input.files && input.files[0]) {
      const file = input.files[0]

      if (file.type !== "application/pdf") {
        console.error("File not found")
        return
      }

      if (file.size > maxFileSize) {
        console.error("File size exceeds the limit")
        return
      }

      const formData = new FormData()
      formData.append("files", file)
      setFilters({
        ...filters,
        size: file.size / 1000,
        [type]: formData,
      })

      setPreview(placeholderFilled)
    }
  }

  useEffect(() => {
    filters?.[type] && setPreview(placeholderFilled)
  }, [filters])

  return (
    <div className={"pdf-upload-container"}>
      <div className={"default-text-placeholder"}>
        {!filters?.[type] ? "Click to this area to upload file" : "The file has been attached"}
      </div>

      <div className={"pdf-upload"}>
        <div className={"pdf-preview"}>
          <img src={preview} alt={"media"} />
          <input
            type={"file"}
            accept={".pdf"}
            id={"pdfUpload"}
            key={"pdfUpload"}
            className={"pdf-upload"}
            onChange={handleFileChange}
          />

          <div className={"options"}>
            <ToolTip
              placement={"top"}
              size={"fit-content-wide"}
              disabled={isChangesDisabled}
              title={
                <div className={"default-extra-text-placeholder"}>
                  <span>Max size: </span>
                  {` ${(maxFileSize / 1000000).toFixed(0)} mb`}
                  <br />
                  <span>Supported formats: </span>
                  {` .pdf`}
                </div>
              }
            >
              <label className={"edit"} htmlFor={"pdfUpload"}>
                {"Add new file"}
              </label>
            </ToolTip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PdfLoader
