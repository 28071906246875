import { Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useCountUsersLazyQuery, useGetDistributorEntitiesQuery, useGetUsersQuery } from "../../../../../graphql"
import LeftSide from "./components/LeftSide"
import RightSide from "./components/RightSide"
import "./index.less"

const ManageBlock: FC<ManageTeamBlockInterface> = ({ userId, role }) => {
  const [countUsers] = useCountUsersLazyQuery()
  const { data: userData, loading } =
    role != "Distributor Entity"
      ? useGetUsersQuery({ fetchPolicy: "network-only", variables: { filter: { id: { eq: userId ? String(userId) : null } } } })
      : useGetDistributorEntitiesQuery({ fetchPolicy: "network-only", variables: { filter: { id: { eq: userId ? String(userId) : null } } } })
  let user: any
  if (userData && "usersPermissionsUsers" in userData) {
    user = userData?.usersPermissionsUsers?.data[0]
  } else if (userData && "distributors" in userData) {
    user = userData?.distributors?.data[0]
  }

  const [blocked, setBlocked] = useState(user?.attributes?.blocked)
  const [usersAssignedToDistributor, setUsersAssignedToDistributor] = useState<number>()
  const [initialFilters, setInitialFilters] = useState<{ [key: string]: string | null }>()
  const [filters, setFilters] = useState<{ [key: string]: string | null }>()

  const getDistributorDeleteStatus = async () => {
    const users = await countUsers({
      variables: {
        filter: {
          or: [
            {
              dsc: {
                sales_manager: {
                  distributor: { id: { eq: String(userId) } }
                }
              }
            },
            {
              distributor: {
                id: { eq: String(userId) }
              }
            }
          ]
        }
      }
    })

    setUsersAssignedToDistributor(users?.data?.usersPermissionsUsers?.meta?.pagination?.total || 0)
  }

  useEffect(() => {
    role == "Distributor Entity" && getDistributorDeleteStatus()
  }, [role])

  useEffect(() => {
    const filters = {
      name: user?.attributes?.name || "",
      email: user?.attributes?.email || "",
      status: user?.attributes?.isAppActivated ? "Active" : "Inactive",
      insiderPermission: user?.attributes?.insiderPermission ? "true" : "",
      role: role,
      region: user?.attributes?.region?.data?.attributes?.name || "",
      distributor:
        user?.attributes?.distributor?.data?.attributes?.name ||
        user?.attributes?.sales_manager?.data?.attributes?.distributor?.data?.attributes?.name ||
        "",
      sales_manager: user?.attributes?.dsc?.data?.attributes?.sales_manager?.data?.attributes?.name || "",
      phone: user?.attributes?.phoneNumber || "",
    }
    setInitialFilters(filters)
    setFilters(filters)
  }, [userData])

  useEffect(() => {
    if (userData) {
      getDistributorDeleteStatus()
      setBlocked(user?.attributes?.blocked || false)
    }
  }, [userData])

  return (
    <div className={`block-manage-wrapper ${loading && "center"}`}>
      {
        !loading &&
          typeof blocked == "boolean" &&
          (
            role != "Distributor Entity" || typeof usersAssignedToDistributor == "number") ?
          (
            <>
              <LeftSide {...{ usersAssignedToDistributor, initialFilters, setInitialFilters, filters, setFilters, blocked, setBlocked, role, userId }} />
              <RightSide {...{ filters, blocked, user, role }} />
            </>
          ) : (
            <Spin className={"team-manage-block-spin"} />
          )}
    </div>
  )
}

export default ManageBlock
