import { lazy } from "react"
import { RouteObject } from "react-router-dom"
import DefaultLayout, { Loader } from "../components/layout"

const NotFound = lazy(() => import("./NotFound"))
const Privacy = lazy(() => import("./Privacy"))
const Terms = lazy(() => import("./Terms"))
const Resources = lazy(() => import("./Resources"))

import Account from "./Account"
import ActivityLog from "./Account/ActivityLog"
import AdminRoles from "./Account/AdminRoles"
import Analytics from "./Account/Analytics"
import Assets from "./Account/Assets"
import Events from "./Account/Events"
import GuestRoles from "./Account/GuestRoles"
import Leaderboard from "./Account/Leaderboard"
import Links from "./Account/Links"
import More from "./Account/More"
import Notifications from "./Account/Notifications"
import Posts from "./Account/Posts"
import Products from "./Account/Products"
import Profile from "./Account/Profile"
import Salons from "./Account/Salons"
import Team from "./Account/Team"
import Login from "./Login"

export const initializationRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        element: <Loader spinning />,
        path: "*",
      },
    ],
  },
]

export const guestRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: NotFound,
        path: "*",
      },
      {
        Component: Login,
        path: "/login",
        index: true,
      },
      {
        Component: Privacy,
        path: "/privacy",
        index: true,
      },
      {
        Component: Terms,
        path: "/terms",
        index: true,
      },
      {
        Component: Resources,
        path: "/resources/*",
        index: true,
      },
    ],
  },
]

export const userRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: NotFound,
        path: "*",
      },
      {
        Component: Privacy,
        path: "/privacy",
        index: true,
      },
      {
        Component: Terms,
        path: "/terms",
        index: true,
      },
      {
        Component: Resources,
        path: "/resources/*",
        index: true,
      },
      {
        path: "/account",
        children: [
          {
            element: <Account children={<Team role={"DSC"} />} pageName={"DSC"} />,
            path: "/account/team/dsc/*",
            index: true,
          },
          {
            element: <Account children={<Team role={"Sales Manager"} />} pageName={"Sales Manager"} />,
            path: "/account/team/sales-manager/*",
            index: true,
          },
          {
            element: <Account children={<Team role={"Distributor"} />} pageName={"Distributor"} />,
            path: "/account/team/distributor/users/*",
            index: true,
          },
          {
            element: <Account children={<Team role={"Distributor Entity"} />} pageName={"Distributor"} />,
            path: "/account/team/distributor/entities/*",
            index: true,
          },
          {
            element: <Account children={<Team role={"Region"} />} pageName={"Region"} />,
            path: "/account/team/region/*",
            index: true,
          },
          {
            element: <Account children={<Salons />} pageName={"Salon"} />,
            path: "/account/team/salon/*",
            index: true,
          },
          {
            element: <Account children={<Analytics />} pageName={"Analytics"} />,
            path: "/account/dashboard/analytics",
            index: true,
          },
          {
            element: <Account children={<Leaderboard leaderboardType={"global"} />} pageName={"Leaderboard"} />,
            path: "/account/dashboard/leaderboard",
            index: true,
          },
          {
            element: (
              <Account
                children={<Leaderboard leaderboardType={"competition"} />}
                pageName={"Kevin Murphy Competition"}
              />
            ),
            path: "/account/dashboard/competition",
            index: true,
          },
          {
            element: <Account children={<Posts />} pageName={"Posts"} />,
            path: "/account/content/posts/*",
            index: true,
          },
          {
            element: <Account children={<Products />} pageName={"Products"} />,
            path: "/account/content/products/*",
            index: true,
          },
          {
            element: <Account children={<Events />} pageName={"Events"} />,
            path: "/account/content/events/*",
            index: true,
          },
          {
            element: <Account children={<Assets />} pageName={"Assets"} />,
            path: "/account/content/assets/*",
            index: true,
          },
          {
            element: <Account children={<Notifications />} pageName={"Notifications"} />,
            path: "/account/content/notifications/*",
            index: true,
          },
          {
            element: <Account children={<More />} pageName={"More"} />,
            path: "/account/content/more",
            index: true,
          },
          {
            element: <Account children={<Links />} pageName={"Links"} />,
            path: "/account/content/links/*",
            index: true,
          },
          {
            element: <Account children={<ActivityLog />} pageName={"Activity Log"} />,
            path: "/account/activity-log",
            index: true,
          },
          {
            element: <Account children={<Profile />} pageName={"Profile"} />,
            path: "/account/profile/account",
            index: true,
          },
          {
            element: <Account children={<Profile />} pageName={"Profile"} />,
            path: "/account/profile/password",
            index: true,
          },
          {
            element: <Account children={<Profile />} pageName={"Profile"} />,
            path: "/account/profile/legal",
            index: true,
          },
          {
            element: <Account children={<AdminRoles />} pageName={"Admin Roles Management"} />,
            path: "/account/roles/admin-roles/*",
            index: true,
          },
          {
            element: <Account children={<GuestRoles />} pageName={"Guests Management"} />,
            path: "/account/roles/guest-roles/*",
            index: true,
          },
        ],
      },
    ],
  },
]
