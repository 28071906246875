import "./index.less"
import { Spin } from "antd"
import { jsPDF } from "jspdf";
import React, { FC, useState } from "react"
import FirstBlock from "./blocks/FirstBlock"
import ThirdBlock from "./blocks/ThirdBlock"
import * as htmlToImage from 'html-to-image'
import { DownloadIcon } from "../../../icons"
import SecondBlock from "./blocks/SecondBlock"
import { useGetRegionsQuery } from "../../../graphql"
import { capitalizeFirstLetter } from "src/utils";

const Analytics: FC = () => {
  const { data: regionsData } = useGetRegionsQuery()
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [blockLoading, setBlockLoading] = useState<{[key: string]: boolean}>({
    firstBlock: false,
    secondBlock: false,
    thirdBlock: false,
  })

  const createPDF = () => {
    setExportLoading(true)
    const pdf = new jsPDF();
    let pdfWidth = pdf.internal.pageSize.getWidth()
    const firstPage = document.getElementById('first-pdf-page');
    const secondPage = document.getElementById('second-pdf-page');

    const addImage = (pageDataUrl: string, imageProps: {width: number, height: number}) =>
      pdf.addImage(
        pageDataUrl,
        'PNG',
        0,
        0,
        pdfWidth,
        pdfWidth! / (imageProps.width / imageProps.height)
      );

    htmlToImage.toPng(firstPage!, { quality: 1 })
      .then(function (firstPageDataUrl) {
        addImage(firstPageDataUrl, pdf.getImageProperties(firstPageDataUrl)); // Assuming the width and height are correct
        pdf.addPage();

        htmlToImage.toPng(secondPage!, { quality: 1 })
          .then(function(secondPageDataUrl) {
            addImage(secondPageDataUrl, pdf.getImageProperties(secondPageDataUrl))

            pdf.save("analytics.pdf");
            setExportLoading(false)
          });
      });
  };

  const getRegionOptions = () => {
    const regionOptions = Array.from({ length: regionsData?.regions?.data.length || 0 }, (_, i) => ({
      label: regionsData?.regions?.data[i].attributes?.name!,
      value: regionsData?.regions?.data[i].attributes?.name!,
      key: String(regionsData?.regions?.data[i].id),
    }))
    regionOptions.unshift({
      label: "All",
      value: "All",
      key: "0",
    })
    return regionOptions || []
  }

  const regionOptions = getRegionOptions()

  const currentYear = new Date().getFullYear()
  const yearOptions = Array.from({ length: currentYear - 2023 + 1 }, (_, i) => ({
    label: String(currentYear - i),
    key: String(i),
  }))
  const currentMonth = new Date().getMonth() + 1
  const monthOptions = Array.from({ length: 12 }, (_, index) => {
    return {
      label: capitalizeFirstLetter(new Date(currentYear, index).toLocaleString("en-US", { month: "long" })),
      key: String(index + 1),
    }
  })
  const currentMonthName = monthOptions?.find(option => +option.key == currentMonth)!.label
  const isExportReady = blockLoading.firstBlock && blockLoading.secondBlock && blockLoading.thirdBlock

  return (
    <section className={"analytics"} id={"analytics"}>
      <button
        type={"submit"}
        onClick={createPDF}
        disabled={!isExportReady}
        className={`export-pdf ${isExportReady && "ready"}`}
      >
        Download PDF
        {exportLoading ? <Spin /> : <DownloadIcon style={{fill: "#fff"}} />}
      </button>
      <div id={"first-pdf-page"}>
      <FirstBlock {...{ currentYear, yearOptions, currentMonth, monthOptions, currentMonthName, regionOptions, blockLoading, setBlockLoading }} />
      </div>
      <div id={"second-pdf-page"}>
      <SecondBlock {...{ currentYear, yearOptions, currentMonth, monthOptions, regionOptions, blockLoading, setBlockLoading}} />
      <ThirdBlock {...{regionOptions, blockLoading, setBlockLoading}}/>
      </div>
    </section>
  )
}

export default Analytics
