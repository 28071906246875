import React, { FC, useEffect, useRef, useState } from "react"
import { useGetLeaderboardLazyQuery } from "../../../../graphql"
import List from "./components/List"

const FirstList: FC<LeaderboardInterface> = ({
  page,
  setPage,
  filters,
  pageSize,
  dscOptions,
  setFilters,
  monthOptions,
  setTotalItems,
}) => {
  const [getLeaderboard] = useGetLeaderboardLazyQuery()
  const downloadBtnRef = useRef<HTMLButtonElement & { link: HTMLAnchorElement | null }>(null)

  const [exportLoading, setExportLoading] = useState<boolean>(false)

  const [list, setList] = useState<any>()
  const [listForExport, setListForExport] = useState<any>()

  const createList = async (isForExport: boolean = false) => {
    if (list && !isForExport) setList(undefined)

    let newList
    newList = await getLeaderboard({
      variables: {
        input: {
          month: +monthOptions.find(option => option.label == filters.selectedMonth)!.key,
          year: +filters.selectedYear,
          page: isForExport ? 1 : page,
          pageSize: isForExport ? 5000 : pageSize,
          ...(filters.selectedDSC !== "All"
            ? { dscEmail: dscOptions!.find(option => option.label == filters.selectedDSC)!.key }
            : {}),
        },
      },
    })

    let totalItems: number
    let finalData: object[]

    totalItems = newList?.data?.appGetLeaderboard?.participants || 0
    finalData = newList?.data?.appGetLeaderboard?.leaderboard as object[]

    if (filters.selectedRegion !== "North America") {
      totalItems = 0
      finalData = []
    }

    if (finalData && !isForExport) {
      setTotalItems(totalItems!)
      setList(finalData)
      return
    } else {
      return finalData
    }
  }

  const createExportList = async () => {
    setExportLoading(true)
    const list = await createList(true)

    let listForExport =
      list?.map((item: any, i) => [
        i + 1,
        item.name,
        filters.selectedRegion,
        item.total_new_door,
        item.haircare_sales,
        item.color_me_sales,
        item.combined_sales,
      ]) || []
    listForExport = listForExport?.map(item => item.filter(entity => entity !== null))
    listForExport.unshift([
      "RANK",
      "DSC",
      "REGION",
      "TOTAL NEW DOOR ($)",
      "HAIRCARE SALES ($)",
      "COLOR.ME SALES ($)",
      "COMBINED SALES ($)",
    ])

    setExportLoading(false)
    setListForExport(listForExport)
  }

  useEffect(() => {
    page !== 1 && setPage(1)
  }, [filters])

  useEffect(() => {
    listForExport && downloadBtnRef.current!.link!.click()
  }, [listForExport])

  useEffect(() => {
    setListForExport(undefined)
    createList()
  }, [pageSize, page, filters])

  return (
    <List
      {...{
        list,
        page,
        filters,
        pageSize,
        setFilters,
        dscOptions,
        listForExport,
        exportLoading,
        downloadBtnRef,
        createExportList,
      }}
    />
  )
}

export default FirstList
