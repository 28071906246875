import { Spin } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useAuth } from "../../../../../components/auth"
import { useGetSalonQuery } from "../../../../../graphql"
import LeftSide from "./components/LeftSide"
import { allItems } from "./components/LeftSide/components/Form/countries"
import RightSide from "./components/RightSide"
import "./index.less"

const ManageBlock: FC<ManageRolesBlockInterface> = ({ selectedUserId }) => {
  const { region } = useAuth()
  const { data: userData, loading } = useGetSalonQuery({
    fetchPolicy: "network-only",
    variables: {
      id: String(selectedUserId),
    },
  })
  const user = userData?.salon?.data

  const [initialFilters, setInitialFilters] = useState<{ [key: string]: string | boolean | null | undefined }>()
  const [filters, setFilters] = useState<{ [key: string]: string | boolean | null | undefined }>()
  const [options, setOptions] = useState<{ [key: string]: SelectItems | null }>({
    dsc: null,
    region: null,
    country: null,
    distributor: null,
    salesManager: null,
    state: allItems.state as any,
    status: [
      { label: "Active", value: "active", key: "1" },
      { label: "Inactive", value: "inactive", key: "2" },
      { label: "Blocked", value: "blocked", key: "3" },
    ],
  })

  const getStatus = () => {
    const owner = user?.attributes?.owner?.data?.attributes
    return owner?.blocked ? "blocked" : owner?.isAppActivated ? "active" : "inactive"
  }

  useEffect(() => {
    if (user || !selectedUserId) {
      const filters = {
        name: user?.attributes?.name || "",
        city: user?.attributes?.city || "",
        email: user?.attributes?.owner?.data?.attributes?.email || "",
        insiderPermission: user?.attributes?.insiderPermission || false,
        region: region?.name ? region.name : user?.attributes?.region?.data?.attributes?.name || "",
        distributor: user?.attributes?.distributor?.data?.attributes?.name || "",
        salesManager: user?.attributes?.sales_manager?.data?.attributes?.name || "",
        dsc: user?.attributes?.dsc?.data?.attributes?.name || "",
        state: user?.attributes?.state || "",
        country: user?.attributes?.country || "",
        status: getStatus(),
      }

      setInitialFilters(filters)
      setFilters(filters)
    }
  }, [user])

  const isChangesDisabled = !!(initialFilters?.name && !selectedUserId)
  const isPageReady = !loading && filters && initialFilters

  return (
    <div className={`block-manage-wrapper ${loading && "center"}`}>
      {isPageReady ? (
        <>
          <LeftSide
            {...{
              user,
              options,
              filters,
              setOptions,
              setFilters,
              selectedUserId,
              initialFilters,
              isChangesDisabled,
              setInitialFilters,
            }}
          />
          <RightSide
            {...{
              user,
              filters,
            }}
          />
        </>
      ) : (
        <Spin className={"guest-roles-manage-block-spin"} />
      )}
    </div>
  )
}

export default ManageBlock
