import { Tooltip } from "antd"
import { TooltipPlacement } from "antd/lib/tooltip"
import React, { FC, ReactNode } from "react"
import "./index.less"

const ToolTip: FC<{
  children: ReactNode
  title: ReactNode
  disabled?: boolean
  size?: string
  placement?: TooltipPlacement
}> = ({ size, title, children, placement, disabled = true }) => {
  return !disabled ? (
    <Tooltip title={title} placement={placement || "top"} id={size}>
      {children}
    </Tooltip>
  ) : (
    <span>{children}</span>
  )
}
export default ToolTip
