import { ReactComponent as AddIcon } from "../../../../../../../../../icons/add-green-circle.svg"
import { ReactComponent as SearchIcon } from "../../../../../../../../../icons/search.svg"
import { ReactComponent as CloseIcon } from "../../../../../../../../../icons/close.svg"
import { ReactComponent as TrashIcon } from "../../../../../../../../../icons/trash.svg"
import { Form as AntForm, Collapse, Empty, Input, Select, Spin } from "antd"
import ImageLoader from "../../../../../../../components/ImageLoader"
import React, { FC, useEffect, useState } from "react"
import type { CollapseProps } from "antd"
import "./index.less"

const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""

const Items: FC<ProductManageBlockLeftSideItemsInterface> = ({
  filters,
  sizeEnums,
  colorEnums,
  setFilters,
  formatSize,
  isChangesDisabled,
}) => {
  const [activeKey, setActiveKey] = useState<string[]>(["0"])
  const itemsAmount = filters?.items?.length

  const generateSelectOptions = (enums: string[], type: string): SelectItems =>
    enums?.map(
      (item, i) =>
        ({
          label: type == "color" ? item?.replace("color-", "") : formatSize(item),
          value: item,
          key: String(i),
        }) || []
    )

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const handleValuesChange = (value: string | Object, type: string, itemIndex: number) => {
    setFilters({
      ...filters,
      items: [
        ...(filters!.items as any).map((item: any, i: number) => ({
          ...item,
          ...(i == itemIndex ? { [type]: value } : {}),
        })),
      ],
    })
  }

  const handleRemoveItem = (index: number) => {
    let newItemsArray = JSON.parse(JSON.stringify(filters.items))
    newItemsArray = newItemsArray.filter((item: any, i: number) => index !== i)

    setFilters({
      ...filters,
      items: newItemsArray,
    })
  }

  const handleAddItem = () => {
    const newItemsArray = JSON.parse(JSON.stringify(filters.items))
    newItemsArray.push({
      id: "",
      name: "",
      color: "",
      size: "",
      currentImage: "",
      SKU: "",
      media: null,
    })

    isItemsValid &&
      setFilters({
        ...filters,
        items: newItemsArray,
      })
    setActiveKey([`${newItemsArray.length - 1}`])
  }

  const uploadImage = async (image: FormData, itemIndex: number) => {
    let imageId: number
    try {
      const uploadResponse = await fetch(url + "/api/upload", {
        method: "POST",
        body: image,
      })
      const uploadData = await uploadResponse.json()
      if (!uploadData?.[0]?.id) {
        throw new Error("failed to upload image")
      }
      imageId = uploadData?.[0]?.id
      setFilters({
        ...filters,
        items: [
          ...(filters!.items as any).map((item: any, i: number) => ({
            ...item,
            ...(i == itemIndex ? { media: imageId } : {}),
          })),
        ],
      })
    } catch (error) {
      setFilters({
        ...filters,
        items: [
          ...(filters!.items as any).map((item: any, i: number) => ({
            ...item,
            ...(i == itemIndex ? { media: null, currentImage: null } : {}),
          })),
        ],
      })
    }
  }

  const createInputElement = (type: string, label: string, itemIndex: number, isRequired = true) => (
    <AntForm.Item label={label} name='name' required={isRequired}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          onChange={e => handleValuesChange(e.target.value, type, itemIndex)}
          value={(filters?.items?.[itemIndex]?.[type] as string) || ""}
        />
      </div>
    </AntForm.Item>
  )

  const createSelectElement = (
    type: string,
    name: string,
    loading: boolean,
    options: SelectItems,
    itemIndex: number,
    isRequired = true
  ) => {
    const value = type == "size" ? (formatSize(filters?.items[itemIndex][type]) || "") : (filters?.items[itemIndex][type] || "")

    const canBeRemoved = !isRequired && value
    return (
      <AntForm.Item name={name} label={name} required={isRequired}>
        <div className={"input-wrapper"}>
          {canBeRemoved && (
            <div
              className={"remove-icon"}
              children={<CloseIcon />}
              onClick={() => handleValuesChange("", type, itemIndex)}
            />
          )}
          <Select
            showSearch
            value={value?.replace("_", " ")}
            optionFilterProp='children'
            onChange={data => handleValuesChange(data, type, itemIndex)}
            filterOption={filteredOption as any}
            options={options}
            defaultActiveFirstOption={true}
            notFoundContent={
              loading || !options ? (
                <div className={"no-data"} children={<Spin />} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
              )
            }
            suffixIcon={<SearchIcon style={canBeRemoved ? { opacity: 0 } : {}} />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createItem = (i: number) => (
    <div className={"item"} key={`product-item-${i}`}>
      <div className={"left-side"}>
        <AntForm layout='vertical'>
          <div className={"inputs-wrapper"}>
            {createInputElement("SKU", "SKU", i, false)}
            {createInputElement("name", "Color Name", i, false)}
            {createSelectElement(
              "color",
              "Color",
              !colorEnums?.getEnum?.enum,
              generateSelectOptions(colorEnums?.getEnum?.enum as string[], "color"),
              i,
              false
            )}
            {createSelectElement(
              "size",
              "Size",
              !sizeEnums?.getEnum?.enum,
              generateSelectOptions(sizeEnums?.getEnum?.enum as string[], "size"),
              i,
              false
            )}
          </div>
        </AntForm>
      </div>
      <div className={"right-side"}>
        <div className={filters.items[i].media && isNaN(filters.items[i].media) ? "under-loading" : ""}>
          <ImageLoader
            type={"media"}
            itemIndex={i}
            filters={filters}
            maxImageSize={120000}
            setFilters={setFilters}
            fetchedImageURL={filters?.items[i].currentImage}
            isChangesDisabled={isChangesDisabled}
          />
        </div>
        <button onClick={() => handleRemoveItem(i)} className={"delete"}>
          Delete
          <TrashIcon />
        </button>
      </div>
    </div>
  )

  const items: CollapseProps["items"] = filters.items.map((_: any, i: number) => ({
    key: i,
    label: filters!.items[i]?.color || formatSize(filters!.items[i]?.size) || `variant`,
    children: createItem(i),
  }))

  const isItemsValid = !filters.items?.find((item: any) => !item.currentImage && !(item.media && !isNaN(item.media)))

  useEffect(() => {
    const formData = filters.items?.find((item: any) => item.media && isNaN(item.media))?.media
    if (formData) {
      const itemIndex = filters.items.findIndex((item: any) => item.media && isNaN(item.media))
      uploadImage(formData, itemIndex)
    }
  }, [filters])

  return (
    <div className={"items-block-wrapper"}>
      <div className={"title"}>
        <span>* </span>
        VARIANTS {itemsAmount < 1 ? " (Must add at least one variant)" : `(${itemsAmount})`}
      </div>
      <div className={"items-wrapper"}>
        <Collapse
          items={items}
          onChange={data => setActiveKey(data as string[])}
          activeKey={activeKey}
          accordion={true}
        />
        <div
          className={`add-item ${!isItemsValid && "disabled"}`}
          aria-disabled={!isItemsValid}
          onClick={handleAddItem}
        >
          <AddIcon />
          Variant
        </div>
      </div>
    </div>
  )
}

export default Items
