import React, { FC } from "react"
import { useLocation } from "react-router"
import ListBlock from "./blocks/ListBlock"
import ManageBlock from "./blocks/ManageBlock"
import "./index.less"

const Events: FC = () => {
  const { pathname } = useLocation()
  const pathIdentifier = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createNewMode = pathIdentifier == "new-event"
  const selectedEventId = Number(pathIdentifier)

  return (
    <section className={"events"}>
      {!createNewMode && !selectedEventId && <ListBlock />}
      {(selectedEventId > 0 || createNewMode) && <ManageBlock selectedEventId={selectedEventId} />}
    </section>
  )
}

export default Events
