import React, { FC } from "react"
import { useLocation } from "react-router"
import ListBlock from "./blocks/ListBlock"
import ManageBlock from "./blocks/ManageBlock"
import "./index.less"

const GuestRoles: FC = () => {
  const { pathname } = useLocation()
  const pathIdentifier = pathname?.split("/")[pathname?.split("/")?.length - 1]
  const createNewMode = pathIdentifier == "new-role"
  const selectedUserId = Number(pathIdentifier)

  return (
    <section className={"roles"}>
      {!createNewMode && !selectedUserId && <ListBlock />}
      {(selectedUserId > 0 || createNewMode) && <ManageBlock selectedUserId={selectedUserId} />}
    </section>
  )
}

export default GuestRoles
