import React, { FC, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useAuth } from "../../../../components/auth"
import {
  ActivityIcon,
  ContentIcon,
  DashboardIcon,
  LogoIcon,
  LogoutIcon,
  ProfileIcon,
  RolesIcon,
  TeamIcon,
} from "../../../../icons"
import NavItem from "./components/NavItem"
import "./index.less"

const Navigation: FC = () => {
  let { avatar, permissions } = useAuth()

  const navigate = useNavigate()
  const [openedElement, setOpenedElement] = useState<string>()
  const { pathname } = useLocation()

  const icons: any = {
    ["Team"]: <TeamIcon />,
    ["Roles"]: <RolesIcon />,
    ["Log Out"]: <LogoutIcon />,
    ["Content"]: <ContentIcon />,
    ["Dashboard"]: <DashboardIcon />,
    ["Activity Log"]: <ActivityIcon />,
    ["Profile"]: avatar ? <img src={avatar} alt={"avatar"} /> : <ProfileIcon />,
  }

  const links: any = {
    ["DSC"]: "dsc",
    ["More"]: "more",
    ["Links"]: "links",
    ["Posts"]: "posts",
    ["Salon"]: "salon",
    ["Region"]: "region",
    ["Events"]: "events",
    ["Assets"]: "assets",
    ["Products"]: "products",
    ["Analytics"]: "analytics",
    ["Guest Roles"]: "guest-roles",
    ["Profile"]: "profile/account",
    ["Leaderboard"]: "leaderboard",
    ["Competition"]: "competition",
    ["Admin Roles"]: "admin-roles",
    ["Activity Log"]: "activity-log",
    ["Notifications"]: "notifications",
    ["Sales Manager"]: "sales-manager",
    ["Distributor"]: "distributor/users",
  }

  const handleElementClick = (parent: string, child: string) => {
    navigate(`/account/${parent}/${child}`)
  }

  const handleStyle = (parent: string, child: string) => {
    if (pathname?.includes(`/account/${parent}/${child.split('/').slice(0, 1)[0]}`)) {
      return { backgroundColor: "#677685", color: "#fff" }
    }
    return {}
  }

  const generateBlocks = () => (
    <>
      <nav className={"first-menu"}>
        {permissions!.firstMenu?.map(item => (
          <NavItem
            key={item.name}
            name={item.name}
            link={links[item.name]}
            label={icons[item.name]}
            openedElement={openedElement}
            setOpenedElement={setOpenedElement}
            children={
              item?.blocks
                ? item.blocks.map((block: PermissionBlockInterface) => (
                    <div
                      key={links[block!.name]}
                      className={"nav-subitem"}
                      onClick={() => handleElementClick(item.name.toLowerCase(), links[block!.name])}
                      style={handleStyle(item.name.toLowerCase(), links[block.name])}
                    >
                      {block.name}
                    </div>
                  ))
                : null
            }
          />
        ))}
      </nav>

      <nav className={"second-menu"}>
        {permissions!.secondMenu?.map(item => (
          <NavItem
            key={item.name}
            name={item.name}
            link={links[item.name]}
            label={icons[item.name]}
            openedElement={openedElement}
            setOpenedElement={setOpenedElement}
          />
        ))}
      </nav>
    </>
  )

  return (
    <section className={"navigation"}>
      <LogoIcon className={"logo"} />
      {generateBlocks()}
    </section>
  )
}
export default Navigation
