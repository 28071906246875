import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, Typography } from "antd"
import React, { FC } from "react"
import { useGetRegionsQuery } from "../../../../../../../graphql"
import { truncate } from "../../../../../../../utils"
import "./index.less"
import { useAuth } from "../../../../../../../components/auth"

const Filter: FC<PostFilterInterface> = ({ filters, setFilters, specificFilters }) => {
  const {region} = useAuth()
  const { data: regionsData } = useGetRegionsQuery()

  const getRegionOptions = () => {
    const regionOptions = Array.from({ length: regionsData?.regions?.data.length || 0 }, (_, i) => ({
      label: regionsData?.regions?.data[i].attributes?.name,
      key: i + 1,
    }))
    regionOptions.unshift({
      label: "All",
      key: 0,
    })
    return regionOptions || []
  }

  const regionOptions = getRegionOptions()

  const statusOptions = [
    {
      label: "All",
      key: 1,
    },
    {
      label: "Published",
      key: 2,
    },
    {
      label: "Scheduled",
      key: 3,
    },
  ]

  const resetFilter = (type: string) => {
    switch (type) {
      case "title":
        setFilters({ ...filters, selectedTitle: "All" })
        break
      case "author":
        setFilters({ ...filters, selectedAuthor: "All" })
        break
      case "category":
        setFilters({ ...filters, selectedCategory: "All" })
        break
    }
  }

  const activeSpecificFiltersAmount = specificFilters.length

  return (
    <div className={`filters-wrapper posts-filter`}>
      <div className={"filters"}>

        {!region?.id && (
        <Dropdown
          className={`region-filter ${filters.selectedRegion != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e =>
                setFilters({ ...filters, selectedRegion: regionOptions.find(item => item.key == +e.key)!.label! })
              }
              selectedKeys={[`${regionOptions.find(item => item.label == filters.selectedRegion)!.key}`]}
            >
              {regionOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {truncate(regionOptions.find(item => item.label == filters.selectedRegion)!.label!, 18)}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
        )}

        <Dropdown
          className={`status-filter ${filters.selectedStatus != "All" && "active"}`}
          trigger={["click"]}
          overlay={
            <Menu
              onClick={e =>
                setFilters({ ...filters, selectedStatus: statusOptions.find(item => item.key == +e.key)!.label! })
              }
              selectedKeys={[`${statusOptions.find(item => item.label == filters.selectedStatus)!.key}`]}
            >
              {statusOptions.map(item => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Typography.Link>
            <Space className={"dropdown"}>
              {statusOptions.find(item => item.label == filters.selectedStatus)!.label}
              <DownOutlined style={{ color: "black" }} />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>

      <div className={"specific-filters-wrapper"}>
        Filters ({activeSpecificFiltersAmount})
        <div className={"specific-filters-list"}>
          {specificFilters &&
            specificFilters.map((filter: any, i: number) => (
              <div className={"block"} key={`filter-${i}`} style={{backgroundColor: filter.backgroundColor, color: filter.color}}>
                {truncate(filter.option, 20)}
                <div className={"remove-filter"} onClick={() => resetFilter(filter.type)}>
                  <div className={"l-line"} />
                  <div className={"r-line"} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
